
<div class="row">
    <div class="col-6">
        <h1 class="mbs">Projects</h1>
    </div>
    <div class="col-6 txt-r sort-dropdown d-flex align-center">
        <div class="search d-flex align-center mrs pos-relative">
            <input type="text" class="prs" [(ngModel)]="searchValue" (keyup)=searchProjects()>
            <styles-icon name="search" color="neutral" class="mrxxxs pos-absolute pos-right pos-v-center"></styles-icon>
        </div>
        <styles-dropdown class="right-align">
            <ng-container class="dropdown-label">Sort By: </ng-container>
            <ng-container class="dropdown-link">{{ currentSort }}</ng-container>
            <li *ngFor="let o of sortOptions" (click)="selectSortOption(o)" [ngClass]="{ 'active' : o === currentSort }">{{o}}</li>
        </styles-dropdown>
    </div>
</div>

<div class="projects-wrap row">
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mbm" *ngFor="let project of filteredProjects">
        <div class="project full-height">
            <!-- <div class="project-buttons">
                <button class="bg-neutral-brighter pts pos-relative pls border-n mrxxs">
                    <styles-icon class="pos-absolute pos-center" style="height: 18px; width: 18px" name="edit" color="neutralDarker"></styles-icon>
                </button>
            </div> -->
            <a [routerLink]="['/p/' + project.id + '/' + currentUser?.selectedLinks[currentUser?.selectedView] ]" class="ntd d-flex flex-col full-height">
                <div *ngIf="!project.settings.icon" class="project-image colors" [ngStyle]="{'background': gradientBackgrounds[project.id]}"></div>
                <div *ngIf="project.settings.icon && project.settings.iconSvg && project.sanitizedSvg" class="project-image svg" [innerHTML]="project.sanitizedSvg" [ngStyle]="{'background': project.settings.iconBg.hex}"></div>
                <div class="project-details d-flex justify-between pvxxxs phxxs flex-grow">
                    <div class="info flex-grow d-flex align-center">
                        <!-- <small *ngIf="project.name && project.clientName">{{project.clientName}}</small>
                        <h5>{{project.name}}</h5> -->
                        <div class="line-clamp line-xs pvxxxs prxxxs">
                            <b *ngIf="project.name && project.clientName">{{project.clientName}} </b>
                            <span>{{project.name}}</span>
                        </div>
                    </div>
                    <div class="date d-flex align-center" >
                        <small *ngIf="project.updated && project.created" class="project-date whitespace-nowrap" title="Updated: {{ formatDateString(project.updated) }}&#013;Created:  {{ formatDateString(project.created) }}">{{formatProjectModifiedDate(project.updated)}}</small>
                    </div>
                </div>
            </a>
        </div>
    </div>
</div>
