<div class="col-12" *ngIf="currentProject && currentProject.clientPortal">
  <div class="page-heading d-flex align-center justify-between mbxs">
    <h2 class="mrm">Client Portal Settings</h2>
    <a class="btn" (click)="updateProject()">Save changes</a>
  </div>

  <div class="form-control mtxl">
    <label>Client Site URL</label>
    <input type="url" [(ngModel)]="currentProject.settings.url" (blur)="updateProject()">
  </div>
  <div class="form-control mtxs mbxl" *ngIf="currentProject.settings.url">
    <a [href]="'/guide/' + currentProject.id" class="txt-underline font-bold" target="_blank">View Client Portal</a>
  </div>

  <div class="form-control mbl">
    <label>Dashboard Heading</label>
    <input type="url" [(ngModel)]="currentProject.clientPortal.dashboard.heading" (blur)="updateProject()">
  </div>

  <div class="pages">
    <h5 class="mbxxxs">Pages</h5>
    <div class="checkbox" *ngFor="let page of pageLabels; let i = index">
      <label for="" (click)="toggleModule(page.slug)" class="d-flex align-center cursor-pointer">
        <input type="checkbox" class="mrxs" [(ngModel)]="currentProject.clientPortal.dashboard.pages[page.slug]"
          [ngModelOptions]="{standalone: true}" />
        {{page.label}}
      </label>
    </div>
  </div>
  
</div>