export class LinkModel {
    name = 'New';
    text = 'Link Text';
    transition = '0.3';
    hovered = false;
    clicked = false;
    toggled = true;
    styles = {
        color: {base: '',hover: '',},
        fontWeight: {base: '',hover: ''},
        background: {base: '',hover: ''},
        textDecorationLine: {base: 'underline',hover: 'underline'},
        textDecorationColor: {base: '',hover: ''},
        textDecorationStyle: {base: 'solid',hover: 'solid'},
    };
}
