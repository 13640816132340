import { Component, HostBinding, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { UserSubscriptionMixin } from 'src/app/mixins/subscriptions.mixin';
import { ClientService } from 'src/app/services/client.service';
import { LoadingService } from 'src/app/services/loading.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { IconsService } from 'src/app/services/icons.service';

@Component({
  selector: 'app-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss']
})
export class IconsComponent extends UserSubscriptionMixin implements OnInit {
  ttf = '';
  svg = '';
  eot = '';
  woff = '';
  iconCss = '';
  sanitizedStyleTag: SafeHtml;
  icons = []

  constructor(
    userService: UserService,
    navService: NavigationService,
    projectService: ProjectService,
    clientService: ClientService,
    private loadingService: LoadingService,
    private storage: AngularFireStorage,
    private sanitizer: DomSanitizer,
    private iconsService: IconsService
  ) {
    super(userService, navService, projectService,clientService);
    
  }

  ngOnInit(): void {
    this.currentProjectLoaded.then(() => {
      
      if (!('icons' in this.currentProject.components)) {
        this.currentProject.components.icons = {
          files: {},
          css: ''
        }
      } else {
        this.eot = this.currentProject.components.icons.files.eot
        this.ttf = this.currentProject.components.icons.files.ttf
        this.woff = this.currentProject.components.icons.files.woff
        this.svg = this.currentProject.components.icons.files.svg
        this.iconCss = this.currentProject.components.icons.css
        
        if (this.iconCss && this.iconsService.sanitizedStyleTag.getValue()) {
          this.icons = this.iconsService.convertCssToIcons(this.iconCss);
        }
      }

    });
  }

  updateProject() {
    this.loadingService.startLoading();
    this.projectService.updateProject(this.currentProject.id, this.currentProject)
    .then(() => {
      this.loadingService.stopLoading()
    });
  }

  uploadFile(event, type) {
    const file = event.target.files[0];
    const filePath = `${this.toKebabCase(this.currentProject.name)}-icon-font-${type}.${type}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    task.snapshotChanges().pipe(
      finalize(() => {
        this[type] = fileRef.getDownloadURL().toPromise().then(res => res)
        fileRef.getDownloadURL().toPromise().then(res => {
          if (res) {
            // const url = new URL(res)
            // this[type] = url.origin + url.pathname;
            this[type] = res
            console.log(this[type])
            this.currentProject.components.icons.files[type] = this[type];
            this.updateProject();
          }
        })
      })
    ).subscribe()
  }

  saveCss() {
    const processed = this.iconCss.substring(this.iconCss.indexOf('.icon-'))
    this.currentProject.components.icons.css = processed;
    this.updateProject();
    this.icons = this.iconsService.convertCssToIcons(this.iconCss);
  }
  resetFile(type) {
    this[type] = ''
    this.currentProject.components.icons.files[type] = this[type];
    this.updateProject();
  }

  toKebabCase(str) {
    return str && str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.toLowerCase())
      .join('-');
  }

}
