<div class="spacing" *ngIf="currentProject && currentProject.variables">
    <h1 class="mbs">Spacing</h1>

    <section class="weights mbxl row">
        <div class="col-12">
            <table>
                <tbody>
                    <tr>
                        <td class="pan"></td>
                        <td class="pan txt-c"><small>Desktop</small></td>
                        <td class="pan txt-c"><small>Mobile</small></td>
                        
                    </tr>
                    <tr *ngFor="let size of sizes; let index = index">
                        <td class="prl">{{size}}</td>
                        <td>
                            <div class="sizeholder">
                                <input type="text"
                                        [(ngModel)]="currentProject.variables.spacing[size.toLowerCase()].desktop"
                                        (blur)="updateProject()">
                                px
                            </div>
                        </td>
                        <td>
                            <div class="sizeholder">
                                <input type="text"
                                    [(ngModel)]="currentProject.variables.spacing[size.toLowerCase()].mobile"
                                    (blur)="updateProject()">
                                px
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</div>
