import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GuideSubscriptionMixin } from 'src/app/mixins/guide.mixin';
import { GuideService } from 'src/app/services/guide.service';
import { IconsService } from 'src/app/services/icons.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.scss']
})
export class GuideComponent extends GuideSubscriptionMixin implements OnInit {
  currentProject;
  siteUrl: string;
  navBase: string;
  sanitizedLogo: SafeHtml;
  currentRoute: string;
  siteInput: string = '';
  showErrorMessage = false;
  showLoadingOverlay = false;
  iconsStyleTag = null;
  subnavStyleTag

  constructor(
    guideService: GuideService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    public loadingService: LoadingService,
    private iconsService: IconsService,
    private element: ElementRef
  ) {
    super(guideService); 
  }

  ngOnInit(): void {
    this.showLoadingOverlay = true;
    this.loadingService.startLoading();

    this.subnavStyleTag = this.sanitizer.bypassSecurityTrustHtml(`
    <style>
      .sub-nav-item {
        position: relative;
        font-size: 20px;
        line-height: 32px;
      }
      .sub-nav-item.active::before {
        content: '';
        position: absolute;
        right: calc(100% + 8px);
        top: calc(50% - 4px);
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background-color: #f98b64;
      }
    </style>
    `);


    this.subscriptions.add(

      // if route includes projectId, this gets priority over site search param
      this.route.paramMap.subscribe( paramMap => {
        console.log(paramMap)
        if (paramMap.get('projectId') && !paramMap.get('projectId').includes('http') && !this.guideService.currentProject.getValue()) {
          console.log("paramMap.get('projectId')")
          this.guideService.setCurrentProjectById(paramMap.get('projectId'))

          // remove site search param if it exists
          this.router.navigate([], {
            queryParams: { 'site': null },
            queryParamsHandling: 'merge'
          })
          
        } else {
          // if route doesn't include projectId, check for site search param
          this.route.queryParams.subscribe(params => {
            if (params.site) {
              console.log("params.site")
              this.guideService.setCurrentProjectByUrl(params.site).then((res: any) => {
                console.log(res)
              })
    
              // replace route with guide/[id]
              this.currentProjectLoaded.then(() => {
                this.showLoadingOverlay = false;
                this.loadingService.stopLoading()
                this.router.navigate(['guide/' + this.currentProject.id ],{replaceUrl:true});
              })
            } else {
              console.log("!params.site")
              this.showLoadingOverlay = false;
              this.loadingService.stopLoading()
            }
          })
          
        }
      }),

      this.subscriptions.add(
        this.iconsService.sanitizedStyleTag.subscribe(tag => this.iconsStyleTag = tag ? tag : null)
      ),

      this.subscriptions.add(
        this.guideService.currentSubmenuLinks.subscribe(links => {
          if (links && links.length) {
            this.getSubNavItems(links)
          }
        })
      )
    )

    this.currentProjectLoaded.then(() => {
      console.log("currentProjectLoaded")
      const urlParts = this.router.url.split('/');
      this.currentRoute = urlParts.length > 3 ? urlParts[urlParts.length - 1].split('#')[0] : '';
      this.navBase = `/guide/${this.currentProject.id}/`;

      // sanitize project logo
      if (this.currentProject?.components?.logos?.length && this.currentProject.components.logos[0].types.lightBG.svg) {
        this.sanitizedLogo = this.sanitizer.bypassSecurityTrustHtml(this.currentProject.components.logos[0].types.lightBG.svg);
      } else if (this.currentProject.settings.icon && this.currentProject.settings.iconSvg) {
        this.sanitizedLogo = this.sanitizer.bypassSecurityTrustHtml(this.currentProject.settings.iconSvg);
      }
      this.showLoadingOverlay = false;
      this.loadingService.stopLoading()
    })

    // watch route changes
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const urlParts = val.url.split('/');
        this.currentRoute = urlParts.length > 3 ? urlParts[urlParts.length - 1].split('#')[0] : '';
        console.log('currentRoute', this.currentRoute);
      }
    });
    
  }

  searchForProject() {
    this.loadingService.startLoading()
    this.guideService.setCurrentProjectByUrl(this.siteInput).then((res: any) => {
      this.loadingService.stopLoading()
      if (res === 'no project found') {
        this.showErrorMessage = true;
      } else {
        this.router.navigate(['guide/' + res ]);
      }
    })
  }


  getSubNavItems(links) {
    const currentNav = this.element.nativeElement.querySelector(`#${this.currentRoute}-nav`);
    const submenu = currentNav?.querySelector('.submenu');
    if (submenu) {
      submenu.innerHTML = '';
      links.forEach((li) => {
        const a = li.querySelector('a')
        a.addEventListener('click', (e) => {
          e.preventDefault();
          const section = this.element.nativeElement.querySelector(`#${a.dataset.fragment}`);
          section.scrollIntoView({behavior: 'smooth', block: 'start'});
        })
        submenu.appendChild(li);
      })
    }
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    // check if .guide-section is visible and add active class to nav item

    const navItems = this.element.nativeElement.querySelectorAll('.sub-nav-item');
    const sections = this.element.nativeElement.querySelectorAll('.guide-section');
    sections.forEach(section => {
      const rect = section.getBoundingClientRect();
      if (rect.top >= 0 && rect.top <= 100) {
        const navItem = this.element.nativeElement.querySelector(`[data-fragment="${section.id}"`);
        navItems.forEach(item => item.classList.remove('active'))
        navItem.classList.add('active')
      }
    });
    
  }


}

