import { Component, OnInit, NgZone } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  inputs = {
    displayName: '',
    email: '',
    password: '',
    position: 'designer',
  };
  signupError = '';

  constructor(
    private userService: UserService,
    private router: Router,
    private zone: NgZone,
  ) { }

  ngOnInit(): void {
  }

  signup(): void {
    let {displayName, email, password, position} = this.inputs;
    if (!email || email.split('@')[1] !== 'niftic.agency') {
      this.signupError = 'Please enter a valid niftic email address';
      return;
    } if (displayName && email && password) {
      this.signupError = '';
      this.userService
        .signup(displayName, email, password, position)
        .then((tm: any) => {
          this.zone.run(async () => {
            await this.router.navigateByUrl('/');
          });
        })
        .catch((err) => {
          console.error(err);
          this.signupError = err;
        });
    } else {
      if (!displayName) this.signupError = 'Please enter your name';
      if (!email) this.signupError = 'Please enter your email';
      if (!password) this.signupError = 'Please enter a password';
    }
  }

}
