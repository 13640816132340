<div class="colors" *ngIf="currentProject && currentProject.variables">
    <h1 class="mbs">Colors</h1>
    
    <div class="colors-list row mbl">
        <div class="col-12 col-sm-6 col-lg-4 mbs" *ngFor="let color of currentProject?.variables.colors; let i = index">
            <div class="color d-flex">
                <div class="color-swatch ptl pbm prxl plxl mrxxs pos-relative" [ngStyle]="{'background-color': color.hex}">
                    <div class="swatch-actions pos-absolute pos-top pos-left maxxs">
                        <button class="duplicate bg-neutral-brighter pts pos-relative pls border-n mrxxs" (click)="editColor(color, i)">
                            <styles-icon class="pos-absolute pos-center" style="height: 18px; width: 18px" name="edit" color="neutralDarker"></styles-icon>
                        </button>
                        <button class="delete bg-neutral-brighter pts pls pos-relative border-n" (click)="deleteColor(i)">
                            <styles-icon class="pos-absolute pos-center" style="height: 18px; width: 18px" name="close" color="neutralDarker"></styles-icon>
                        </button>
                    </div>
                </div>
                <div class="color-meta d-flex flex-col justify-center">
                    <h5 class="font-heavy">{{color.label}}</h5>
                    <h5 class="font-normal">{{color.hex}}</h5>
                </div>
            </div>
        </div>
    </div>
    
    <div class="add-color-wrap mbxl">
        <styles-modal #addColorModal (onHide)="changeHide($event)">
            <ng-container class="open-inner">Add Color</ng-container>
            <h2 class="mbs">{{editingColor !== null ? 'Edit' : 'Add'}} Color</h2>
            <div class="color-display full-width ptxxl border-a mbs pos-relative">
                <div class="color-display-bg full-width full-height pos-absolute pos-center" *ngIf="newColor.hex" [ngStyle]="{'background-color': (newColor.hex.startsWith('#') ? '' : '#') + newColor.hex}"></div>
            </div>
            <div class="form-control">
                <label>Hex</label>
                <input class="form-control" type="text" name="hex" [(ngModel)]="newColor.hex" required>
            </div>
            <div class="form-control">
                <label>Label</label>
                <input class="form-control" type="text" name="label" [(ngModel)]="newColor.label">
            </div>
            <div class="form-control mbxs">
                <label>Slug</label>
                <input class="form-control" type="text" name="label" [(ngModel)]="newColor.slug">
            </div>
            <!-- <div class="form-control mbxs">
                <label>Group</label>
                <input class="form-control" type="text" name="group" [(ngModel)]="newColor.group">
            </div> -->
            <div class="txt-r">
                <button class="btn" (click)="saveColor()" [disabled]="!newColor.hex || !newColor.label">Save Color</button>
                <div *ngIf="newColorError" style="color: red">{{newColorError}}</div>
            </div>
        </styles-modal>
    </div>

    <div class="colors-list row mbl">
        <div class="col-12">
            <h2>Bulk Add</h2>
            <div class="instructions mbxs">
                <div><i>Color Label : #ffffff : color-slug-override</i></div>
            </div>
            <textarea name="bulk-color-add" id="bulk-color-add" class="mbxs" [(ngModel)]="bulkColorsInput"></textarea>
            <button class="btn" (click)="bulkAdd()">Add Colors</button>
            <div *ngIf="newColorError" style="color: red">{{newColorError}}</div>
        </div>
    </div>
</div>
