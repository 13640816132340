import { Component, ElementRef, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GuideSubscriptionMixin } from 'src/app/mixins/guide.mixin';
import { GuideService } from 'src/app/services/guide.service';
import { IconsService } from 'src/app/services/icons.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-guide-links',
  templateUrl: './guide-links.component.html',
  styleUrls: ['./guide-links.component.scss']
})
export class GuideLinksComponent extends GuideSubscriptionMixin implements OnInit {

  constructor(
    guideService: GuideService,
    private loadingService: LoadingService,
    private element: ElementRef
  ) {
    super(guideService); 
  }

  ngOnInit(): void {
    this.currentProjectLoaded.then(() => {
      console.log(this.currentProject);

      this.guideService.setCurrentSubmenu(this.element.nativeElement.querySelectorAll('.guide-section'))
    })
  }

  toKebabCase(str) {
    return str && str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.toLowerCase())
      .join('-');
  }
}
