<div class="col-12" *ngIf="currentProject && currentProject.settings">
  <h2 class="mbs">Harmonica Settings</h2>
  <div class="form-control">
    <label>Project Name</label>
    <input class="form-control" type="text" name="project-name" [(ngModel)]="currentProject.name" required>
  </div>
  <div class="form-control mbxs">
    <label class="d-flex align-center hover-parent justify-between">
      <span>Client</span>
    </label>
    <div class="client-autocomplete pos-relative">
      <input type="text" [(ngModel)]="clientSearch.currentProject" (keyup)="onClientSearch('currentProject')"
        class="full-width">
      <styles-icon name="close" color="neutralDarker" class="cursor-pointer pos-absolute pos-v-center pos-right mrxxs"
        (click)="removeClient('currentProject')" *ngIf="clientSearch.currentProject.length"></styles-icon>
      <styles-dropdown class="pos-absolute pos-left pos-top mtl" *ngIf="clientSearch.currentProject.length">
        <ng-container #currentProjectClientDropdownBtn class="dropdown-label">
          <div class="hide-fully"></div>
        </ng-container>
        <li class="whitespace-nowrap cursor-pointer" *ngFor="let c of filteredClients"
          (click)="selectClient('currentProject',c)">{{c.name}}</li>
        <li class="whitespace-nowrap cursor-pointer"
          [hidden]="filteredClients.length === 1 && clientSearch.currentProject.toLowerCase() === filteredClients[0].name.toLowerCase()"
          (click)="createClient('currentProject')">Add '{{clientSearch.currentProject}}' to the database?</li>
      </styles-dropdown>
    </div>
  </div>
  
  <div class="project-thumbnail mtxl" *ngIf="currentProject?.settings">
    <h4>Thumbnail</h4>

    <div class="form-control">
      <label>Thumbnail Type</label>
      <div class="d-flex align-center">
        <div class="radio-wrap d-flex align-center mrs cursor-pointer">
          <input id="thumbnail-type-css" type="radio" class="mrxxxs" [value]="false" name="thumbnailType" [(ngModel)]="currentProject.settings.icon" required>
          <label for="thumbnail-type-css">Colors</label>
        </div>
        <div class="radio-wrap d-flex align-center mrs cursor-pointer">
          <input id="thumbnail-type-logo" type="radio" class="mrxxxs" [value]="true" name="thumbnailType"
            [(ngModel)]="currentProject.settings.icon" required>
          <label for="thumbnail-type-logo">Logo</label>
        </div>
      </div>
    </div>

    <div class="thumbnail-colors-wrap" *ngIf="!currentProject.settings.icon">
      <h5 class="mbxs">Colors</h5>
      <div *ngIf="!currentProject.settings.icon" class="project-image colors"
        [ngStyle]="{'background': gradientBackgrounds[currentProject.id]}"></div>
      <div class="form-control" *ngFor="let color of currentProject?.settings?.thumbnail; let i = index">
        <select (change)="setThumbnailColor($event.target.value, i)"
          [ngStyle]="{'border-right' :'10px solid '+ color.hex}">
          <option value="" disabled="true" [selected]="!color.slug"></option>
          <option *ngFor="let c of currentProject.variables.colors" [value]="c.slug" [selected]="color.slug === c.slug">{{c.label}}</option>
        </select>
      </div>
    </div>
    <div class="thumbnail-colors-wrap" *ngIf="currentProject.settings.icon">
      <h5 class="mbxs">SVG</h5>
      <div *ngIf="currentProject.settings.icon && currentProject.settings.iconSvg && sanitizedSvg" class="project-image svg"
        [innerHTML]="sanitizedSvg" [ngStyle]="{'background': currentProject.settings.iconBg.hex}"></div>
      <div class="form-control">
        <textarea name="" id="" style="width: 100%" rows="7" [(ngModel)]="currentProject.settings.iconSvg" (change)="renderThumbnailSVG()"></textarea>
      </div>
      <div class="form-control">
        <label>Logo Background</label>
        <select (change)="setIconColor($event.target.value)"
          [ngStyle]="{'border-right' :'10px solid '+ currentProject.settings.iconBg.hex}">
          <option value="" disabled="true" [selected]="!currentProject.settings.iconBg.slug"></option>
          <option *ngFor="let c of currentProject.variables.colors" [value]="c.slug"
            [selected]="currentProject.settings.iconBg.slug === c.slug">{{c.label}}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="modules mtxl row" *ngIf="currentProject?.settings?.modules">
    <h4 class="mbxs col-12">Modules</h4>
    <div class="design-modules mbs col-12 col-md-6">
      <h5 class="mbxxxs">Design</h5>
      <div class="checkbox" *ngFor="let m of designModules">
        <label for="" (click)="toggleModule(m, 'design')" class="d-flex align-center cursor-pointer">
          <input type="checkbox" class="mrxs" [(ngModel)]="currentProject.settings.modules.design[m]"
            [ngModelOptions]="{standalone: true}" />
          {{convertToNormalCase(m)}}
        </label>
      </div>
    </div>
    <div class="dev-modules col-12 col-md-6">
      <h5 class="mbxxxs">Dev</h5>
      <div class="checkbox" *ngFor="let m of devModules">
        <label for="" (click)="toggleModule(m, 'dev')" class="d-flex align-center cursor-pointer">
          <input type="checkbox" class="mrxs" [(ngModel)]="currentProject.settings.modules.dev[m]"
            [ngModelOptions]="{standalone: true}" />
          {{m === 'stylesheet' ? 'Exports' : convertToNormalCase(m)}}
        </label>
      </div>
    </div>
  </div>

  <div class="txt-r">
    <button class="btn" (click)="updateProject()">Save Project</button>
  </div>
</div>