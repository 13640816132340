<div id="icon-css" *ngIf="iconsStyleTag" [innerHTML]="iconsStyleTag"></div>
<div class="header pos-fixed pos-top pos-left ptxl full-width">
    <div class="pos-absolute full-width full-height pos-top pos-left">
        <div class="container-fluid full-height d-flex justify-between align-center">
            <div class="left d-flex align-center">
                <a routerLink="/" class="d-flex align-center justify-center">
                    <styles-icon name="nifticLogo" color="neutralBrighter" width="30" height="30"></styles-icon>
                </a>
                <h3 *ngIf="currentProject && currentProject.name" class="mlm">{{currentProject.clientName ? currentProject.clientName + ' - ' : ''}}{{currentProject.name}}</h3>
                <!-- <styles-dropdown *ngIf="currentProject && currentProject.name">
                    <ng-container class="dropdown-link">
                        <styles-icon name="caretDown" color="neutralBrighter" class="mtxxs mlxxxs"></styles-icon>
                    </ng-container>
                    <li class="d-flex align-center phxxs mbxxs cursor-pointer color-neutral-darker whitespace-nowrap"
                        (click)="toggleModal('editModal')">Edit Project</li>
                    <li class="d-flex align-center phxxs cursor-pointer color-system-error font-bold whitespace-nowrap"
                        (click)="toggleModal('deleteModal')">Delete Project</li>
                </styles-dropdown> -->
            </div>
            <div class="right d-flex align-center full-height" *ngIf="currentUser">
                <ng-container *ngIf="currentView !== 'dashboard' && currentView !== 'settings'">
                    <div class="view-tab" (click)="changeView('design')" [ngClass]="{'active': currentView == 'design'}">
                        <b class="text-uppercase">Design</b>
                    </div>
                    <div class="view-tab" (click)="changeView('develop')" [ngClass]="{'active': currentView == 'develop'}">
                        <b class="text-uppercase">Dev</b>
                    </div>
                    <div class="view-tab" (click)="changeView('projectSettings')" [ngClass]="{'active': currentView == 'projectSettings'}">
                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24"
                            width="24px" fill="#ffffff">
                            <g>
                                <path d="M0,0h24v24H0V0z" fill="none" />
                                <path
                                    d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z" />
                            </g>
                        </svg>
                    </div>
                </ng-container>
                <a routerLink="/settings" class="settings" [ngStyle]="currentUser.photoURL && {'background-image': 'url(' + currentUser.photoURL + ')'}">{{ !currentUser.photoURL ? currentUser.displayName[0] : ''}}</a>
            </div>
        </div>
    </div>
</div>
<div class="sidebar" [ngClass]="{'toggled': menuToggled}">
    <div class="sidebar-inner d-flex flex-col justify-between bg-neutral-bright pam full-height">
        <button class="d-none xs-d-block pos-absolute pos-top pos-right mtxxxs cursor-pointer paxxs"
            (click)="toggleMenu()">
            <styles-icon name="menu" color="neutralDarker" [height]="24" [width]="24"
                [ngClass]="{'hide-fully': menuToggled}"></styles-icon>
            <styles-icon name="close" color="neutralDarker" [ngClass]="{'hide-fully': !menuToggled}"></styles-icon>
        </button>
        <ul>
            <ng-container *ngIf="currentView === 'design'">
                <li class="mbs" *ngIf="
                    currentProject?.settings?.modules.design.colors ||
                    currentProject?.settings?.modules.design.typography ||
                    currentProject?.settings?.modules.design.spacing 
                ">
                    <h4 class="mbs">Base Styles</h4>
                </li>
                <li *ngIf="currentProject?.settings?.modules.design.colors" class="mbs">
                    <a (click)="navigate('colors')" class="cursor-pointer"
                        [ngClass]="{'txt-underline': 'colors' === currentSlug}">Colors</a>
                </li>
                <li *ngIf="currentProject?.settings?.modules.design.typography" class="mbs">
                    <a (click)="navigate('typography')" class="cursor-pointer"
                        [ngClass]="{'txt-underline': 'typography' === currentSlug}">Typography</a>
                </li>
                <li *ngIf="currentProject?.settings?.modules.design.spacing" class="mbs">
                    <a (click)="navigate('spacing')" class="cursor-pointer"
                        [ngClass]="{'txt-underline': 'spacing' === currentSlug}">Spacing</a>
                </li>
                <li class="mbs" *ngIf="
                    currentProject?.settings?.modules.design.buttons ||
                    currentProject?.settings?.modules.design.links 
                ">
                    <h4 class="mbs mtm">Components</h4>
                </li>
                <li *ngIf="currentProject?.settings?.modules.design.buttons" class="mbs">
                    <a (click)="navigate('buttons')" class="cursor-pointer"
                        [ngClass]="{'txt-underline': 'buttons' === currentSlug}">Buttons</a>
                    <ul *ngIf="'buttons' === currentSlug" class="submenu">
                        <li *ngFor="let button of currentProject.components.buttons">
                            <a (click)="navigateToHash('#' + toKebabCase(button.name))" >{{button.name}}</a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="currentProject?.settings?.modules.design.links" class="mbs">
                    <a (click)="navigate('links')" class="cursor-pointer"
                    [ngClass]="{'txt-underline': 'links' === currentSlug}">Links</a>
                    <ul *ngIf="'links' === currentSlug" class="submenu">
                        <li *ngFor="let link of currentProject.components.links">
                            <a (click)="navigateToHash('#' + toKebabCase(link.name))" >{{link.name}}</a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="currentProject?.settings?.modules.design.icons" class="mbs">
                    <a (click)="navigate('icons')" class="cursor-pointer"
                    [ngClass]="{'txt-underline': 'icons' === currentSlug}">Icons</a>
                </li>
                <li *ngIf="currentProject?.settings?.modules.design.logos" class="mbs">
                    <a (click)="navigate('logos')" class="cursor-pointer"
                    [ngClass]="{'txt-underline': 'logos' === currentSlug}">Logos</a>
                </li>
            </ng-container>

            <ng-container *ngIf="currentView === 'develop'">
                <li class="mbs" *ngIf="currentProject?.settings?.modules.dev.overview">
                    <a (click)="navigate('overview')" class="cursor-pointer"
                        [ngClass]="{'txt-underline': 'overview' === currentSlug}">Overview</a>
                </li>
                <li class="mbs" *ngIf="
                    (currentProject?.settings?.modules.design.buttons ||
                    currentProject?.settings?.modules.design.links) && 
                    currentProject?.settings?.modules.dev.components
                ">
                    <a (click)="navigate('components')" class="cursor-pointer"
                        [ngClass]="{'txt-underline': 'components' === currentSlug}">Components</a>
                </li>
                <li class="mbs" *ngIf="currentProject?.settings?.modules.dev.codeBlocks">
                    <a (click)="navigate('code-blocks')" class="cursor-pointer"
                        [ngClass]="{'txt-underline': 'code-blocks' === currentSlug}">Code blocks</a>
                    <ul *ngIf="'code-blocks' === currentSlug" class="submenu">
                        <li *ngFor="let section of currentProject.codeBlocks">
                            <a (click)="navigateToHash('#' + toKebabCase(section.name))" >{{section.name}}</a>
                        </li>
                    </ul>
                </li>
                <li class="mbs" *ngIf="currentProject?.settings?.modules.dev.stylesheet">
                    <a (click)="navigate('stylesheet')" class="cursor-pointer"
                        [ngClass]="{'txt-underline': 'stylesheet' === currentSlug}">Exports</a>
                </li>
            </ng-container>
            <ng-container *ngIf="currentView === 'projectSettings'">
                <li class="mbs">
                    <a (click)="navigate('harmonica')" class="cursor-pointer"
                        [ngClass]="{'txt-underline': 'harmonica' === currentSlug}">Harmonica Settings</a>
                </li>
                <li class="mbs" >
                    <h4 class="mbs mtm">Client Portal</h4>
                </li>
                <li class="mbs">
                    <a (click)="navigate('client-portal')" class="cursor-pointer"
                        [ngClass]="{'txt-underline': 'client-portal' === currentSlug}">Settings</a>
                </li>
                <li class="mbs">
                    <a (click)="navigate('client-guidelines')" class="cursor-pointer"
                        [ngClass]="{'txt-underline': 'client-guidelines' === currentSlug}">Guidelines</a>
                </li>
                <li class="mbs">
                    <a (click)="navigate('client-logos')" class="cursor-pointer"
                        [ngClass]="{'txt-underline': 'client-logos' === currentSlug}">Logos</a>
                </li>
                <li class="mbs">
                    <a (click)="navigate('client-colors')" class="cursor-pointer"
                        [ngClass]="{'txt-underline': 'client-colors' === currentSlug}">Colors</a>
                </li>
                <li class="mbs">
                    <a (click)="navigate('client-fonts')" class="cursor-pointer"
                        [ngClass]="{'txt-underline': 'client-fonts' === currentSlug}">Fonts</a>
                </li>
                <li class="mbs">
                    <a (click)="navigate('client-icons-graphics')" class="cursor-pointer"
                        [ngClass]="{'txt-underline': 'client-icons-graphics' === currentSlug}">Icons &amp; Graphics</a>
                </li>
                <li class="mbs">
                    <a (click)="navigate('client-links')" class="cursor-pointer"
                        [ngClass]="{'txt-underline': 'client-links' === currentSlug}">Links</a>
                </li>
            </ng-container>

            <ng-container *ngIf="currentView == 'dashboard' || currentView == 'codeBlocks'">
                <li class="mbs">
                    <a (click)="setFilter(null)" [ngClass]="{'active': currentFilter === null}">All Projects</a>
                </li>
                <li>
                <li class="mbs">
                    <a (click)="setFilter('codeBlocks')" [ngClass]="{'active': currentFilter === 'codeBlocks'}">Code blocks</a>
                </li>
                <li>
                    <h4 class="mbs d-flex align-center justify-between hover-parent">
                        <span>Clients</span>
                        <styles-icon name="edit" class="d-block hover-child cursor-pointer"
                            (click)="toggleModal('editClients')"></styles-icon>
                    </h4>
                </li>
                <li [style.display]="currentView == 'dashboard' ? 'block' : 'none'" *ngFor="let client of clients"
                    class="mbs">
                    <a (click)="setFilter(client.id)" [ngClass]="{'active': client.id === currentFilter}">{{ client.name
                        }}</a>
                </li>
            </ng-container>
        </ul>

        <ng-container *ngIf="currentView == 'dashboard'">
            <div>
                <styles-modal #createProjectModal>
                    <ng-container class="open-inner">{{navLinks[currentView].cta.openLabel}}</ng-container>
                    <h2 class="mbs">New Project</h2>
                    <div class="form-control">
                        <label>Project Name</label>
                        <input class="form-control" type="text" name="project-name" [(ngModel)]="newProject.name"
                            required>
                    </div>
                    <div class="form-control mbxs">
                        <label>Client</label>
                        <div class="client-autocomplete pos-relative">
                            <input type="text" [(ngModel)]="clientSearch.newProject"
                                (keyup)="onClientSearch('newProject')" class="full-width">
                            <styles-icon name="close" color="neutralDarker"
                                class="cursor-pointer pos-absolute pos-v-center pos-right mrxxs"
                                (click)="removeClient('newProject')" *ngIf="clientSearch.newProject.length">
                            </styles-icon>
                            <styles-dropdown class="pos-absolute pos-left pos-top mtl"
                                *ngIf="clientSearch.newProject.length">
                                <ng-container #newProjectClientDropdownBtn class="dropdown-label">
                                    <div class="hide-fully"></div>
                                </ng-container>
                                <li class="whitespace-nowrap cursor-pointer" *ngFor="let c of filteredClients"
                                    (click)="selectClient('newProject', c)">{{c.name}}</li>
                                <li class="whitespace-nowrap cursor-pointer"
                                    [hidden]="filteredClients.length === 1 && clientSearch.newProject.toLowerCase() === filteredClients[0].name.toLowerCase()"
                                    (click)="createClient('newProject')">Add '{{clientSearch.newProject}}' to the
                                    database?</li>
                            </styles-dropdown>
                        </div>
                    </div>
                    <div class="modules">
                        <h4 class="mbxs mtl">Modules</h4>
                        <div class="row">
                            <div class="design-modules mbs col-12 col-md-6">
                                <h5 class="mbxxxs">Design</h5>
                                <div class="checkbox" *ngFor="let m of designModules">
                                    <label for=""
                                        (click)="newProject.settings.modules.design[m] = !newProject.settings.modules.design[m]"
                                        class="d-flex align-center cursor-pointer">
                                        <input type="checkbox" class="mrxs"
                                            [(ngModel)]="newProject.settings.modules.design[m]"
                                            [ngModelOptions]="{standalone: true}" />
                                        {{convertToNormalCase(m)}}
                                    </label>
                                </div>
                            </div>
                            <div class="dev-modules col-12 col-md-6">
                                <h5 class="mbxxxs">Dev</h5>
                                <div class="checkbox" *ngFor="let m of devModules">
                                    <label for=""
                                        (click)="newProject.settings.modules.dev[m] = !newProject.settings.modules.dev[m]"
                                        class="d-flex align-center cursor-pointer">
                                        <input type="checkbox" class="mrxs"
                                            [(ngModel)]="newProject.settings.modules.dev[m]"
                                            [ngModelOptions]="{standalone: true}" />
                                        {{convertToNormalCase(m)}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="txt-r">
                        <button class="btn" (click)="createProject()">Create Project</button>
                    </div>
                </styles-modal>
            </div>
        </ng-container>
    </div>
</div>
<styles-modal #editProjectModal [showButton]="false">
    
</styles-modal>
<styles-modal #editClientsModal [showButton]="false">
    <h2 class="mbs">Edit Clients</h2>
    <div class="d-flex hover-parent align-center pos-relative mbxs"
        *ngFor="let client of filteredClients; let i = index">
        <input class="full-width mrm" type="text" name="client-name" [(ngModel)]="client.name" required
            (blur)="updateClient(i)">
        <styles-icon name="close" (click)="deleteClient(i)" color="systemError"
            class="hover-child cursor-pointer pos-absolute pos-v-center pos-right"></styles-icon>
    </div>
    <div class="d-flex align-center pos-relative mbs">
        <input class="full-width mrm" type="text" name="client-name" [(ngModel)]="clientSearch.editClients" required>
        <styles-icon name="add" (click)="createClient('editClients')" color="neutralDarker"
            class="cursor-pointer pos-absolute pos-v-center pos-right"></styles-icon>
    </div>
    <div class="txt-r">
        <button class="btn" (click)="toggleModal('editClients')">Done</button>
    </div>
</styles-modal>
<styles-modal #deleteProjectModal [showButton]="false">
    <h2 class="mbs">Are you sure you want to delete this project?</h2>
    <div class="d-flex align-center">
        <button class="btn mrs bg-system-error" (click)="deleteProject()">Delete</button>
        <button class="btn mrs" (click)="toggleModal('deleteModal')">Take me back</button>
    </div>
</styles-modal>