import { Component, ElementRef, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GuideSubscriptionMixin } from 'src/app/mixins/guide.mixin';
import { GuideService } from 'src/app/services/guide.service';
import { IconsService } from 'src/app/services/icons.service';
import * as JSZip from 'jszip';
import * as FileSaver from 'file-saver';
import { LoadingService } from 'src/app/services/loading.service';
import * as bops from 'bops';

@Component({
  selector: 'app-brand-resources',
  templateUrl: './brand-resources.component.html',
  styleUrls: ['./brand-resources.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BrandResourcesComponent extends GuideSubscriptionMixin implements OnInit {
  assets = [
    {
      label: 'Download Individual Icons',
      toggled: false,
      contentHeight: 0,
      content: [],
      safeContent: null
    },
    {
      label: 'Download Individual Graphics',
      toggled: false,
      contentHeight: 0,
      content: [],
      safeContent: null
    },
  ]

  constructor(
    private sanitizer: DomSanitizer,
    guideService: GuideService,
    private iconsService: IconsService,
    private loadingService: LoadingService,
    private element: ElementRef
  ) {
    super(guideService); 
  }

  ngOnInit(): void {
    this.assets.forEach(asset => {
      if (typeof asset.content == 'string') asset.safeContent = this.sanitizer.bypassSecurityTrustHtml(asset.content);
    })
    this.currentProjectLoaded.then(() => {
      console.log(this.currentProject);

      if ('logos' in this.currentProject.components && this.currentProject.components.logos.length) {
        this.currentProject.components.logos.forEach(logo => {
          if ('types' in logo) {
            if ('lightBG' in logo.types && 'svg' in logo.types.lightBG && logo.types.lightBG.svg) logo.types.lightBG.sanitized = this.sanitizer.bypassSecurityTrustHtml(logo.types.lightBG.svg);
            if ('darkBG' in logo.types && 'svg' in logo.types.darkBG && logo.types.darkBG.svg) logo.types.darkBG.sanitized = this.sanitizer.bypassSecurityTrustHtml(logo.types.darkBG.svg);
          }
        })
      }

      if (
        'clientPortal' in this.currentProject && 
        'iconsGraphics' in this.currentProject.clientPortal &&
        'icons' in this.currentProject.clientPortal.iconsGraphics && 
        this.currentProject.clientPortal.iconsGraphics.icons.length
      ) {
        this.assets[0].content = this.currentProject.clientPortal.iconsGraphics.icons
      }
      if (
        'clientPortal' in this.currentProject && 
        'iconsGraphics' in this.currentProject.clientPortal &&
        'graphics' in this.currentProject.clientPortal.iconsGraphics && 
        this.currentProject.clientPortal.iconsGraphics.graphics.length
      ) {
        this.assets[1].content = this.currentProject.clientPortal.iconsGraphics.graphics
      }

      this.guideService.setCurrentSubmenu(this.element.nativeElement.querySelectorAll('.guide-section'))
    })
  }

  toggleAccordion(asset, accordion) {
    asset.toggled = !asset.toggled
    const content = accordion.querySelector('.accordion-content')
    const contentInner = content.firstElementChild;
    if (asset.toggled) {
      const contentHeight = contentInner.clientHeight;
      content.style.height = contentHeight + 'px';
    } else {
      content.style.height = 0;
    }
  }

  async downloadImage(img, convertSvg = false) {
    console.log(img)
    let image, imageBlob;
    if (convertSvg) {
      imageBlob = new Blob([img.svg], { type: 'image/svg+xml' });
    } else {
      image = await fetch(img.downloadURL)
      imageBlob = await image.blob()
    } 
    const imageURL = URL.createObjectURL(imageBlob)
  
    this.downloadFile(imageURL, img.name)
  }

  async downloadFont(font) {

  }

  downloadFile(url, name) {
    const link = document.createElement('a')
    link.href = url
    link.download = name
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  async downloadZip(type) {
    this.loadingService.startLoading();

    let filesArr;
    const zip = new JSZip();

    if (type === 'icons' || type === 'graphics') {
      filesArr = this.currentProject.clientPortal.iconsGraphics[type]
      for (let i = 0; i < filesArr.length; i++) {
        const file = await fetch(filesArr[i].downloadURL)
        const fileBlob = await file.blob()
        zip.file(filesArr[i].name, fileBlob, { base64: true });
      }
    }

    if (type === 'fonts') {
      filesArr = this.currentProject.clientPortal.fonts.typefaces
      for await (const file of filesArr) {
        await this.processFont(zip, file)
      }
    }

    zip.generateAsync({ type: "blob" })
      .then( (content) => {
        console.log('generate async')
        FileSaver.saveAs(content, `${this.currentProject.name} ${type.substring(0,1).toUpperCase() + type.substring(1)}.zip`);
        this.loadingService.stopLoading();
      });
  }

  async processFont(zip, file) {
    console.log('processFont')
    let unzip = new JSZip();
    let zipFile = await fetch(file.file)
    let zipFileBlob = await zipFile.blob()
    let zipFolder = zip.folder(file.label)

    const unzip$ = await unzip.loadAsync(zipFileBlob)

    for await (const filename of Object.keys(unzip$.files)) {
      const fileData = await unzip$.files[filename].async('string')
      await zipFolder.file(filename, fileData);
      console.log(file.name)
    }

    console.log(zipFolder)
  }
}
