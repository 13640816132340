import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserSubscriptionMixin } from 'src/app/mixins/subscriptions.mixin';
import { ClientService } from 'src/app/services/client.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-project-single',
  templateUrl: './project-single.component.html',
  styleUrls: ['./project-single.component.scss']
})
export class ProjectSingleComponent extends UserSubscriptionMixin implements OnInit {

  constructor(
    userService: UserService,
    navService: NavigationService,
    projectService: ProjectService,
    clientService: ClientService,
  ) {
    super(userService, navService, projectService, clientService);
  }

  ngOnInit(): void {
    this.currentProjectLoaded.then(() => {

      // init url and isLive
      if (!('clientPortal' in this.currentProject)) {
        this.currentProject.clientPortal = {}
      }
    })

  }

}
