<div class="spacing" *ngIf="currentProject && currentProject.variables">
    <h1 class="mbs">Borders</h1>

    <section class="widths mbxl row">
        <div class="col-12">
            <h3 class="mbs">Width</h3>
            <table>
                <tbody>
                    <ng-container *ngFor="let width of currentProject.variables.borders.widths; let index = index">
                        <tr *ngIf="!width.hidden">
                            <td class="prl">{{width.label}}</td>
                            <td><input type="text" [(ngModel)]="currentProject.variables.borders.widths[index].value" (blur)="updateProject()"></td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
            <styles-dropdown>
                <ng-container class="dropdown-link">+ Add Width</ng-container>
                <li *ngFor="let width of currentProject.variables.borders.widths;let index = index"
                    class="d-flex align-center mbxxs cursor-pointer" (click)="toggleWidth(index)">
                    <div class="checkbox border-a ptxs prxs mrxxs" [ngClass]="{'bg-neutral': !width.hidden}"></div>
                    <div>{{width.label}}</div>
                </li>
            </styles-dropdown>
        </div>
    </section>
    
    <section class="widths mbxl row">
        <div class="col-12">
            <h3 class="mbs">Radius</h3>
            <table>
                <tbody>
                    <ng-container *ngFor="let radius of currentProject.variables.borders.radius; let index = index">
                        <tr *ngIf="!radius.hidden">
                            <td class="prl">{{radius.label}}</td>
                            <td><input type="text" [(ngModel)]="currentProject.variables.borders.radius[index].value" (blur)="updateProject()"></td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
            <styles-dropdown>
                <ng-container class="dropdown-link">+ Add radius</ng-container>
                <li *ngFor="let radius of currentProject.variables.borders.radius;let index = index"
                    class="d-flex align-center mbxxs cursor-pointer" (click)="toggleRadius(index)">
                    <div class="checkbox border-a ptxs prxs mrxxs" [ngClass]="{'bg-neutral': !radius.hidden}"></div>
                    <div>{{radius.label}}</div>
                </li>
            </styles-dropdown>
        </div>
    </section>
</div>