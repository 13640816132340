import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private _loading: boolean = false;
  loadingStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);

  get loading(): boolean {
    return this._loading;
  }

  set loading(v: boolean) {
    this._loading = v;
    this.loadingStatus.next(v);
  }

  startLoading(): void {
    this.loading = true;
  }

  stopLoading(): void {
    this.loading = false;
  }
}
