export class ButtonModel {
    name = 'New Button';
    text = 'Button Text';
    transition = '0.3';
    toggled = true;
    hovered = false;
    clicked = false;
    styles = {
        background: {base: '',hover: '', disabled: ''},
        color: {base: '',hover: '', disabled: '',},
        fontWeight: {base: '',hover: '', disabled: ''},
        height: {base: '',hover: '', disabled: ''},
        paddingRight: {base: '',hover: '', disabled: '',},
        paddingLeft: {base: '',hover: '', disabled: '',},
        borderRadius: {base: '',hover: '', disabled: '', metric: 'px'},
        borderWidth: {base: '',hover: '', disabled: ''},
        borderColor: {base: '',hover: '', disabled: ''},
    };
    icon = {
        active: false,
        position: 'right',
        svg: '',
        styles: {
            padding: {base: null, hover: null, disabled: null},
            visibility: {base: null, hover: null, disabled: null}
        }
    }
}
