import { Component, ElementRef, OnInit } from '@angular/core';
import { GuideSubscriptionMixin } from 'src/app/mixins/guide.mixin';
import { GuideService } from 'src/app/services/guide.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-guide-dashboard',
  templateUrl: './guide-dashboard.component.html',
  styleUrls: ['./guide-dashboard.component.scss']
})
export class GuideDashboardComponent extends GuideSubscriptionMixin implements OnInit {
  siteUrl: string;
  navBase: string;
  dashboardCards = [
    {
      title: 'Wordpress Tips',
      desc: 'Pointers with accompanying images to help you use the page builder.',
      path: 'wordpress-tips'
    },
    {
      title: 'Brand Resources',
      desc: 'Brand guidelines, logo files, colors, icons and graphics.',
      path: 'brand-resources'
    },
    {
      title: 'Links',
      desc: 'Direct links to shared google drive folders with brand assets, collateral, and more.',
      path: 'guide-links'
    },
  ]
  constructor(
    guideService: GuideService,
    private loadingService: LoadingService,
    private element: ElementRef
  ) {
    super(guideService); 
  }

  ngOnInit(): void {
    const pathArr = window.location.pathname.split('/');
    this.siteUrl = pathArr[pathArr.indexOf('guide') + 1];
    this.navBase = `/guide/${this.siteUrl}/`;

    this.currentProjectLoaded.then(() => {
      console.log(this.currentProject);
    })
  }

}
