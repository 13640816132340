import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './authentication/login/login.component';
import { SignupComponent } from './authentication/signup/signup.component';
import { AllCodeBlocksComponent } from './pages/all-code-blocks/all-code-blocks.component';
import { ProjectSettingsComponent } from './pages/project-single/project-settings/project-settings.component';
import { BordersComponent } from './pages/project-single/design/borders/borders.component';
import { ButtonsComponent } from './pages/project-single/design/buttons/buttons.component';
import { CardComponent } from './pages/project-single/design/card/card.component';
import { ColorComponent } from './pages/project-single/design/color/color.component';
import { FormFieldsComponent } from './pages/project-single/design/form-fields/form-fields.component';
import { LinkComponent } from './pages/project-single/design/link/link.component';
import { ModalComponent } from './pages/project-single/design/modal/modal.component';
import { SliderComponent } from './pages/project-single/design/slider/slider.component';
import { SpacingComponent } from './pages/project-single/design/spacing/spacing.component';
import { TypographyComponent } from './pages/project-single/design/typography/typography.component';
import { CodeBlocksComponent } from './pages/project-single/dev/code-blocks/code-blocks.component';
import { ComponentsComponent } from './pages/project-single/dev/components/components.component';
import { OverviewComponent } from './pages/project-single/dev/overview/overview.component';
import { StylesheetComponent } from './pages/project-single/dev/stylesheet/stylesheet.component';
import { ProjectSingleComponent } from './pages/project-single/project-single.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { SearchComponent } from './pages/search/search.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { AuthGuard } from './services/auth-guard.service';
import { GuideComponent } from './pages/guide/guide.component';
import { MainComponent } from './layout/main/main.component';
import { GuideDashboardComponent } from './pages/guide/guide-dashboard/guide-dashboard.component';
import { WordpressTipsComponent } from './pages/guide/wordpress-tips/wordpress-tips.component';
import { BrandResourcesComponent } from './pages/guide/brand-resources/brand-resources.component';
import { GuideLinksComponent } from './pages/guide/guide-links/guide-links.component';
import { HarmonicaComponent } from './pages/project-single/project-settings/harmonica/harmonica.component';
import { ClientGuidelinesComponent } from './pages/project-single/project-settings/client-guidelines/client-guidelines.component';
import { ClientLogosComponent } from './pages/project-single/project-settings/client-logos/client-logos.component';
import { ClientColorsComponent } from './pages/project-single/project-settings/client-colors/client-colors.component';
import { ClientFontsComponent } from './pages/project-single/project-settings/client-fonts/client-fonts.component';
import { ClientIconsGraphicsComponent } from './pages/project-single/project-settings/client-icons-graphics/client-icons-graphics.component';
import { ClientLinksComponent } from './pages/project-single/project-settings/client-links/client-links.component';
import { IconsComponent } from './pages/project-single/design/icons/icons.component';
import { LogosComponent } from './pages/project-single/design/logos/logos.component';
import { ClientDashboardComponent } from './pages/project-single/project-settings/client-dashboard/client-dashboard.component';


const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: MainComponent,
    children: [
      {
        path: '',
        component: ProjectsComponent
      },
      {
        path: 'dashboard',
        redirectTo: '',
      },
      {
        path: 'search',
        component: SearchComponent
      },
      {
        path: 'code-blocks',
        component: AllCodeBlocksComponent
      },
      {
        path: 'settings',
        component: SettingsComponent
      },
      {
        path: 'p/:id',
        canActivate: [AuthGuard],
        component: ProjectSingleComponent,
        children: [
          {
            path: 'colors',
            component: ColorComponent
          },
          {
            path: 'typography',
            component: TypographyComponent
          },
          {
            path: 'spacing',
            component: SpacingComponent
          },
          {
            path: 'borders',
            component: BordersComponent
          },
          {
            path: 'buttons',
            component: ButtonsComponent
          },
          {
            path: 'links',
            component: LinkComponent
          },
          {
            path: 'icons',
            component: IconsComponent
          },
          {
            path: 'logos',
            component: LogosComponent
          },
          {
            path: 'form-fields',
            component: FormFieldsComponent
          },
          {
            path: 'card',
            component: CardComponent
          },
          {
            path: 'slider',
            component: SliderComponent
          },
          {
            path: 'modal',
            component: ModalComponent
          },
          {
            path: 'overview',
            component: OverviewComponent
          },
          {
            path: 'stylesheet',
            component: StylesheetComponent
          },
          {
            path: 'code-blocks',
            component: CodeBlocksComponent
          },
          {
            path: 'components',
            component: ComponentsComponent
          },
          {
            path: 'settings',
            component: ProjectSettingsComponent
          },
          {
            path: 'harmonica',
            component: HarmonicaComponent
          },
          {
            path: 'client-guidelines',
            component: ClientGuidelinesComponent
          },
          {
            path: 'client-logos',
            component: ClientLogosComponent
          },
          {
            path: 'client-colors',
            component: ClientColorsComponent
          },
          {
            path: 'client-fonts',
            component: ClientFontsComponent
          },
          {
            path: 'client-icons-graphics',
            component: ClientIconsGraphicsComponent
          },
          {
            path: 'client-links',
            component: ClientLinksComponent
          },
          {
            path: 'client-portal',
            component: ClientDashboardComponent
          },
        ]
      },
    ]
  },

  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'guide',
    component: GuideComponent,
  },
  {
    path: 'guide/:projectId',
    component: GuideComponent,
    children: [
      {
        path: '',
        component: GuideDashboardComponent
      },
      {
        path: 'wordpress-tips',
        component: WordpressTipsComponent
      },
      {
        path: 'brand-resources',
        component: BrandResourcesComponent
      },
      {
        path: 'guide-links',
        component: GuideLinksComponent
      },
    ]
  },
  /*{
    path: 'signup',
    component: SignupComponent
  },*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
