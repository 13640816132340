import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import User from '../interfaces/User';
import { ClientService } from '../services/client.service';
import { NavigationService } from '../services/navigation.service';
import { ProjectService } from '../services/project.service';
import { UserService } from '../services/user.service';
import { observable, computed } from 'mobx-angular';
import { makeObservable } from 'mobx';


export type Constructor<T> = new (...args: any[]) => T;

export interface ISubscriptionsMixin {
    subscriptions: any
    userLoaded: Promise<any>
    currentUser: User,
    currentView: any,
    currentSlug: any,
    currentProjectId: any,
    projectsLoaded: any
    projects: any,
    filteredProjects: any,
    currentProjectLoaded: any,
    currentProject: any,
    clientsLoaded: any,
    clients: any,
}

export type SubscriptionsMixinCtor = Constructor<ISubscriptionsMixin>;

export interface HasMixinServices {
    userService: any;
    navService: any;
    projectService: any;
    clientService: any;
}

export function mixinUser<T extends Constructor<HasMixinServices>>(base: T): SubscriptionsMixinCtor & T {
    class Temp extends base implements OnDestroy {
        subscriptions = new Subscription();

        // User
        userLoaded;
        currentUser;

        // Nav
        currentView;
        currentSlug;
        
        // Projects
        projectsLoaded;
        projects;
        filteredProjects;
        currentProjectLoaded;
        @observable currentProject: {};
        currentProjectId;

        // Clients
        clientsLoaded;
        clients;

        constructor(...args: any[]) {
            super(...args);

            this.userLoaded = new Promise<void>((resolve, reject) => {
                this.subscriptions.add(
                    this.userService.user.subscribe((u) => {
                        if (u && u.id) {
                            this.currentUser = u;
                            resolve();
                        }
                    })
                );
            })   
            this.subscriptions.add(
                this.navService.currentView.subscribe((v) => {
                    if (v) {
                        this.currentView = v;
                    }
                })
            )
            this.subscriptions.add(
                this.navService.currentProjectId.subscribe((p) => {
                    if (p) this.currentProjectId = p;
                })
            )
            this.subscriptions.add(
                this.navService.currentSlug.subscribe((s) => {
                    if (s) this.currentSlug = s;
                })
            )
            this.projectsLoaded = new Promise<void>((resolve) => {
                this.subscriptions.add(
                    this.projectService.projects.subscribe((p) => {
                        if (p && p.length) {
                            this.projects = p
                            resolve();
                        };
                    })).add(
                    this.projectService.filteredProjects.subscribe((p) => {
                        if (p && p.length) {
                            this.filteredProjects = p
                            resolve();
                        };
                    })
                )
            })
            this.currentProjectLoaded = new Promise<void>((resolve) => {
                this.subscriptions.add(
                    this.projectService.currentProject.subscribe((p) => {
                        if (p && p.id) {
                            this.currentProject = p;
                            resolve();
                        } else {
                            this.currentProject = {};
                        }
                    })
                )
            })
            this.clientsLoaded = new Promise<void>((resolve) => {
                this.subscriptions.add(
                    this.clientService.clients.subscribe((c) => {
                        if (c && c.length) {
                            this.clients = c
                            resolve();
                        };
                    })
                )
            })

        }

        ngOnDestroy() {
            this.subscriptions.unsubscribe();
        }
    };

    return Temp;
}

class UserSubscriptionBase {
constructor(
    public userService: UserService,
    public navService: NavigationService,
    public projectService: ProjectService,
    public clientService: ClientService,
) {}
}
  
export const UserSubscriptionMixin: SubscriptionsMixinCtor & typeof UserSubscriptionBase = mixinUser(UserSubscriptionBase);