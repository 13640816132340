<!-- <div>
    <ngx-monaco-editor [options]="editorOptions" [(ngModel)]="code"></ngx-monaco-editor>
</div> -->

<div class="code-blocks" *ngIf="currentProject">

    <h1 class="mbs">Components</h1>

    <div class="sections">
        <div class="col-12" *ngIf="components">
            <div class="accordion-control" 
                *ngFor="let section of components; let sectionInd = index"
                [ngClass]="{'toggled': section.toggled}">
                
                <div class="accordion-heading cursor-pointer d-flex align-center justify-between"
                    [ngClass]="{'mbs': section.toggled}" 
                    (click)="toggleSection(sectionInd)">

                    <div class="left d-flex align-center justify-start">
                        <h3>{{section.name}}</h3>
                    </div>
                    <div class="right d-flex align-center justify-end">
                        <styles-icon name="caretDown" color="neutral-darker" [ngStyle]="{'transform': !section.toggled ? 'none' : 'rotate(180deg)'}"></styles-icon>
                    </div>
                </div>

                <div class="accordion-body" *ngIf="section.toggled">

                    <div class="block" *ngFor="let block of section.blocks; let blockInd = index">
                        <h5 class="block-name mbxxs d-flex justify-between">
                            <span>{{block.name}}</span>
                            <div class="options">
                                <span [ngClass]="{'hide-fully': !block.copied}" class="color-system-info d-inline-flex align-center mrxxs">
                                    <styles-icon name="check" color="systemInfo" class="mrxxxs"></styles-icon>
                                    <span class="type-xxs">copied!</span>
                                </span>
                                <styles-icon class="cursor-pointer" name="copy" color="neutralDarker" (click)="copyBlock(block)" [ngClass]="{'hide-fully': block.copied}"></styles-icon>
                            </div>
                        </h5>
                        <div class="block-content mbxxs">
                            <pre class="code">{{block.content}}</pre>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</div>