import { Component, Input, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { LoadingService } from 'src/app/services/loading.service';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'styles-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @Input() url = '';
  @Input() fileName = '';
  @Input() fileType = '';
  @Input() currentProjectId = '';
  @Output() updateUrl = new EventEmitter<string>();
  @Output() removeUrl = new EventEmitter<string>();
  file = null;

  constructor(
    private loadingService: LoadingService,
    private storage: AngularFireStorage,
  ) { }

  ngOnInit(): void {
  }

  uploadFile(event) {
    this.file = event.target.files[0];
    this.loadingService.startLoading();
  }
  handleCompletedFile(e) {
    this.url = e.downloadURL
    this.updateUrl.emit(this.url);
    this.file = null;
    this.loadingService.stopLoading()
  }
  resetFile() {
    this.removeUrl.emit();
  }


}
