<div class="guide-links container pbxxl" *ngIf="currentProject?.clientPortal?.links">
  <div class="row mbm">
    <div class="col-12">
      <h1 class="mbxl">Links</h1>
    </div>
  </div>

  <div [id]="toKebabCase(group.label)" class="row guide-section" *ngFor="let group of currentProject.clientPortal.links; let i = index">
    <div class="col-12">
      <h2>{{group.label}}</h2>
      <div class="group-description mbs" *ngIf="group.description">
        <styles-builder [modules]="group.description" [admin]="false"></styles-builder>
      </div>
      <div class="links" *ngIf="group.links">
        <a 
          class="link d-flex align-center" 
          [ngClass]="{'external': link.type === 'link', 'download': link.type === 'file'}" 
          [href]="link.type === 'link' ? 'http://' + link.url : link.value" 
          target="_blank" 
          *ngFor="let link of group.links"
        >
          {{link.label}}
          <styles-icon [name]="link.type === 'link' ? 'arrowRight' : 'download'" color="#F98B64" class="mlxxs"></styles-icon>
        </a>
      </div>
    </div>
  </div>

</div>