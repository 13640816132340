import { Component, OnInit } from '@angular/core';
import { UserSubscriptionMixin } from 'src/app/mixins/subscriptions.mixin';
import { ClientService } from 'src/app/services/client.service';
import { LoadingService } from 'src/app/services/loading.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-client-fonts',
  templateUrl: './client-fonts.component.html',
  styleUrls: ['./client-fonts.component.scss']
})
export class ClientFontsComponent extends UserSubscriptionMixin implements OnInit {
  fontsData = {
    description: [],
    typefaces: []
  }
  emptyTypeface = {
    label: '',
    description: '',
    file: null
  }

  constructor(
    userService: UserService,
    navService: NavigationService,
    projectService: ProjectService,
    clientService: ClientService,
    private loadingService: LoadingService,
  ) {
    super(userService, navService, projectService, clientService);
  }

  ngOnInit(): void {
    this.currentProjectLoaded.then(() => {
      this.fontsData.description = this.currentProject.clientPortal?.fonts?.description || [];
      this.fontsData.typefaces = this.currentProject.clientPortal?.fonts?.typefaces || [];
      console.log(this.currentProject)
    })
  }
  addTypeface() {
    this.fontsData.typefaces.push({ ...this.emptyTypeface });
  }
  updateTypefaceFile(event, i) {
   this.fontsData.typefaces[i].file = event
    this.updateProject();
  }
  removeTypefaceFile(event, i) {
   this.fontsData.typefaces[i].file = ''
    this.updateProject();
  }

  updateModules($event) {
    this.fontsData.description = [...$event]
  }

  updateProject() {
    this.currentProject.clientPortal.fonts = this.fontsData

    this.loadingService.startLoading();
    this.projectService.updateProject(this.currentProject.id, this.currentProject)
    .then(() => {
      this.loadingService.stopLoading()
    });
  }
  toKebabCase(str) {
    return str && str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.toLowerCase())
      .join('-');
  }
}
