<div class="col-12" *ngIf="currentProject && currentProject.clientPortal">
  <div class="page-heading d-flex align-center justify-between mbxs">
    <h2 class="mrm">Colors</h2>
    <a class="btn" (click)="updateProject()">Save changes</a>
  </div>

  <div class="section-description mbxl">
    <h5 class="mbxs">Section description</h5>
    <styles-builder [modules]="colorsData.description" (updateModules)="updateDescriptionModules($event)" [currentProjectId]="currentProject.id"></styles-builder>
  </div>
  <div class="section-usage mbxl">
    <h5 class="mbxs">Color usage</h5>
    <styles-builder [modules]="colorsData.usage" (updateModules)="updateUsageModules($event)" [currentProjectId]="currentProject.id"></styles-builder>
  </div>
</div>