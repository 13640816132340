import { Component, OnInit, ViewChild } from '@angular/core';
import { UserSubscriptionMixin } from 'src/app/mixins/subscriptions.mixin';
import { ClientService } from 'src/app/services/client.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';
import { observable, computed } from 'mobx-angular';
import { makeObservable } from 'mobx';
import { LoadingService } from 'src/app/services/loading.service';
import { LinkModel } from 'src/app/models/link.model'
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent extends UserSubscriptionMixin implements OnInit {
  @ViewChild('deleteLinkModal') deleteModal;
  linkStateForm: FormGroup;
  decoration = {
    line: ['none', 'underline', 'line-through', 'overline', 'underline overline'],
    style: ['solid','double','dotted','dashed','wavy']
  }
  colorSlugs = {};
  filteredWeights = [];
  // link = {clicked: false};
  get linkStyles() {
    if (this.currentProject && this.currentProject.components && this.currentProject.components.links) {
      let links = this.currentProject.components.links.map(link => {
        let b = {base: {}, hover: {}}
        for (const key of Object.keys(b)) {
          b[key]['transition'] = `all ${link.transition}s ease-in-out`;
          b[key]['color'] = this.colorSlugs[link.styles.color[key]];
          b[key]['background-color'] = this.colorSlugs[link.styles.background[key]];
          b[key]['font-weight'] = link.styles.fontWeight[key];
          b[key]['text-decoration-line'] = link.styles.textDecorationLine[key];
          b[key]['text-decoration-color'] = this.colorSlugs[link.styles.textDecorationColor[key]];
          b[key]['text-decoration-style'] = link.styles.textDecorationStyle[key];
        }
        return b
      })
      return links;
    } 
    return ''
  }

  constructor(
    userService: UserService,
    navService: NavigationService,
    projectService: ProjectService,
    clientService: ClientService,
    private loadingService: LoadingService,
    private fb: FormBuilder,
  ) {
    super(userService, navService, projectService,clientService);
  
    this.linkStateForm = fb.group({
      linkState: ['base']
    });
  }

  ngOnInit(): void {
    this.currentProjectLoaded.then(() => {      
      this.setColorSlugs();
      this.filteredWeights = this.currentProject.variables.typography.weights.filter((w) => !w.hidden);
      if(!this.currentProject.components) {
        this.currentProject.components = { links: [(new LinkModel)] };
        this.currentProject.components.links[0].name = "Default";
        this.updateProject();
      }
    });
  }

  updateStyle() {
    console.log(this);
  }
  updateProject() {
    this.loadingService.startLoading();
    this.projectService.updateProject(this.currentProject.id, this.currentProject)
    .then(() => this.loadingService.stopLoading());
  }
  setColorSlugs() {
    for (let color of this.currentProject.variables.colors) {
      this.colorSlugs[color.slug] = color.hex;
    }
  }
  addLink() {
    if(!this.currentProject.components.links) this.currentProject.components.links = [];
    this.currentProject.components.links.push(new LinkModel);
  }
  duplicateLink(e,link,i) {
    e.stopPropagation();
    let newLink = {...link}
    newLink.name = newLink.name + ' (copy)';
    newLink.toggled = true;
    link.toggled = false;

    // deep copy nested objects
    let styles = {};
    Object.keys(link.styles).forEach(k => {
      styles[k] = {...link.styles[k]};
    })
    newLink.styles = {...styles};

    this.currentProject.components.links.splice(i + 1, 0, newLink);
  }
  toggleLink(index) {
    this.currentProject.components.links.forEach((l,i) => {
      if (i === index) l.toggled = !l.toggled
    });
    this.projectService.updateProject(this.currentProject.id, this.currentProject)
  }
  deleteLink(i) {
    this.currentProject.components.links.splice(i,1);
  }

  // get styles() {
  //   let styles = [...this.currentProject.components.links]
  //   return styles
  // }
  toKebabCase(str) {
    return str && str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.toLowerCase())
      .join('-');
  }
}
