import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { UserSubscriptionMixin } from 'src/app/mixins/subscriptions.mixin';
import { NavigationService } from 'src/app/services/navigation.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoadingService } from 'src/app/services/loading.service';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent extends UserSubscriptionMixin  implements OnInit {
  subscriptions: Subscription = new Subscription();
  loading = false;
  loadingSubscription: Subscription;

  constructor(
    projectService: ProjectService,
    userService: UserService,
    navService: NavigationService,
    clientService: ClientService,
    private router: Router,
    private loadingService: LoadingService
  ) {
    super(userService, navService, projectService,clientService); 
  }

  ngOnInit(): void {
    
    this.userLoaded.then(() => {
      this.navService.updateNavData(window.location.pathname, this.currentUser).then(id => {
        this.setCurrentProject(id)
      });
      
      this.router.events.subscribe((event: any) => {
        if ( event instanceof NavigationStart ) {
          this.navService.updateNavData(event.url, this.currentUser).then(id => {
            this.setCurrentProject(id);
          });
        }
      });
    })

    this.subscriptions.add(
      this.loadingService.loadingStatus.subscribe((l) => {
        this.loading = l;
      })
    );
  }

  setCurrentProject(id) {
    if (id) {
      if (this.projects && this.projects.length) {
        this.projectService.setCurrentProject(id);
      } else {
        this.projectsLoaded.then(() => {
          this.projectService.setCurrentProject(id);
        })
      }
    } else {
      this.projectService.setCurrentProject(null);
    }
  }

}
