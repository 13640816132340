import { Component, OnInit } from '@angular/core';
import { UserSubscriptionMixin } from 'src/app/mixins/subscriptions.mixin';
import { ClientService } from 'src/app/services/client.service';
import { LoadingService } from 'src/app/services/loading.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-client-icons-graphics',
  templateUrl: './client-icons-graphics.component.html',
  styleUrls: ['./client-icons-graphics.component.scss']
})
export class ClientIconsGraphicsComponent extends UserSubscriptionMixin implements OnInit {
  iconsGraphicsData = {
    description: [],
    icons: [],
    graphics: []
  }

  constructor(
    userService: UserService,
    navService: NavigationService,
    projectService: ProjectService,
    clientService: ClientService,
    private loadingService: LoadingService,
  ) {
    super(userService, navService, projectService, clientService);
  }

  ngOnInit(): void {
    this.currentProjectLoaded.then(() => {
      console.log(this.currentProject)
      this.iconsGraphicsData.description = this.currentProject.clientPortal?.iconsGraphics?.description || [];
      this.iconsGraphicsData.icons = this.currentProject.clientPortal?.iconsGraphics?.icons || [];
      this.iconsGraphicsData.graphics = this.currentProject.clientPortal?.iconsGraphics?.graphics || [];
    })
  }

  updateModules($event) {
    this.iconsGraphicsData.description = [...$event]
  }

  updateProject() {
    this.currentProject.clientPortal.iconsGraphics = this.iconsGraphicsData

    this.loadingService.startLoading();
    this.projectService.updateProject(this.currentProject.id, this.currentProject)
    .then(() => {
      this.loadingService.stopLoading()
    });
  }
  handleFiles(e,type) {
    this.iconsGraphicsData[type] = [
      ...this.iconsGraphicsData[type], 
      ...e.filter(f1 => !this.iconsGraphicsData[type].some(f2 => f1.name == f2.name))
    ];
    console.log(this.iconsGraphicsData[type])
    this.updateProject();
  }
  deleteFile(i, type) {
    this.iconsGraphicsData[type].splice(i,1)
    this.updateProject();
  }
  toKebabCase(str) {
    return str && str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.toLowerCase())
      .join('-');
  }
}
