import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'styles-builder',
  templateUrl: './builder.component.html',
  styleUrls: ['./builder.component.scss']
})
export class BuilderComponent implements OnInit {
  @Input() admin = true;
  @Input() modules: any = []
  @Input() currentProjectId: string = ''
  @Output() updateModules = new EventEmitter<string>();
  moduleTypes = [
    {type: 'text', label: 'Text', value: ''},
    {type: 'heading', label: 'Heading', value: ''},
    {type: 'image', label: 'Image', value: ''}
  ]
  
  constructor() { }

  ngOnInit(): void {
  }
  onUpdateModules() {
    this.updateModules.emit(this.modules)
  }
  addModule(index) {
    this.modules.push({...this.moduleTypes[index]})
  }
  removeModule(i) {
    this.modules.splice(i,1);
  }
  updateFile(event, i) {
    this.modules[i].value = event
    this.onUpdateModules();
  }
  removeFile(event, i) {
    this.modules[i].value = ''
    this.onUpdateModules();
  }
}
