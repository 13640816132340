import { Component, Inject, OnInit } from '@angular/core';
import { UserSubscriptionMixin } from 'src/app/mixins/subscriptions.mixin';
import { ClientService } from 'src/app/services/client.service';
import { LoadingService } from 'src/app/services/loading.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-client-dashboard',
  templateUrl: './client-dashboard.component.html',
  styleUrls: ['./client-dashboard.component.scss']
})
export class ClientDashboardComponent extends UserSubscriptionMixin implements OnInit {
  pageLabels = [
    {slug: 'wordpressTips', label: 'WordPress Tips'},
    {slug: 'brandResources', label: 'Brand Resources'},
    {slug: 'links', label: 'Links'}
  ]

  constructor(
    userService: UserService,
    navService: NavigationService,
    projectService: ProjectService,
    clientService: ClientService,
    private loadingService: LoadingService
  ) {
    super(userService, navService, projectService, clientService);
  }

  ngOnInit(): void {
    this.currentProjectLoaded.then(() => {
      console.log(this.currentProject);
      if (!('dashboard' in this.currentProject.clientPortal)) {
        this.currentProject.clientPortal.dashboard = {
          heading: 'Pellentesque risus interdum maecenas vivamus molestie viverra et cursus. ',
          pages: {
            wordpressTips: true,
            brandResources: true,
            links: true
          }
        };
      }
    })
  }

  toggleModule(slug) {
    this.currentProject.clientPortal.dashboard.pages[slug] = !this.currentProject.clientPortal.dashboard.pages[slug];
    this.updateProject();
  }
  updateProject() {
    this.loadingService.startLoading();
    this.projectService.updateProject(this.currentProject.id, this.currentProject).then(res => {
      this.loadingService.stopLoading();
      console.log(res);
    });
  }

}
