<div class="container signup">
    <div class="row">
        <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
            <h3 class="mtl">Sign Up</h3>
            <div class="form-control">
                <label>Name</label>
                <input type="text" name="name" [(ngModel)]="inputs.displayName">
            </div>
            <div class="form-control">
                <label>Position</label>
                <select [(ngModel)]="inputs.position">
                    <option value="designer" selected>Designer</option>
                    <option value="developer">Developer</option>
                </select>
            </div>
            <div class="form-control">
                <label>Email</label>
                <input type="email" name="email" [(ngModel)]="inputs.email">
            </div>
            <div class="form-control">
                <label>Password</label>
                <input type="password" name="password" [(ngModel)]="inputs.password" (keydown.enter)="signup()">
            </div>
            <div class=" txt-r type-xxs mrm">Already a member? <a routerLink="/login">Sign in now.</a></div>
            <div class="mtm">
                <button class="btn" (click)="signup()">Signup</button>
                <div class="mtxs color-system-error">{{signupError}}</div>
            </div>
        </div>
    </div>
</div>