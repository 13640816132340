import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { observable } from 'mobx-angular';
import { GuideService } from '../services/guide.service';


export type Constructor<T> = new (...args: any[]) => T;

export interface IGuideMixin {
    subscriptions: any
    currentProjectId: any,
    projectsLoaded: any
    projects: any,
    currentProjectLoaded: any,
    currentProject: any,
}

export type GuideMixinCtor = Constructor<IGuideMixin>;

export interface HasGuideMixinServices {
    guideService: any;
}

export function mixinGuide<T extends Constructor<HasGuideMixinServices>>(base: T): GuideMixinCtor & T {
    class GuideClass extends base implements OnDestroy {
        subscriptions = new Subscription();
        
        // Projects
        projectsLoaded;
        projects;
        currentProjectLoaded;
        @observable currentProject: null;
        currentProjectId;

        constructor(...args: any[]) {
            super(...args);

            this.currentProjectLoaded = new Promise<void>((resolve) => {
                this.subscriptions.add(
                    this.guideService.currentProject.subscribe((p) => {
                        if (p && p.id) {
                            this.currentProject = p;
                            resolve();
                        } else {
                            this.currentProject = null;
                        }
                    })
                )
            })
        }

        ngOnDestroy() {
            this.subscriptions.unsubscribe();
        }
    };

    return GuideClass;
}

class GuideSubscriptionBase {
constructor(
    public guideService: GuideService,
) {}
}
  
export const GuideSubscriptionMixin: GuideMixinCtor & typeof GuideSubscriptionBase = mixinGuide(GuideSubscriptionBase);