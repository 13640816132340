import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { GuideService } from './guide.service';
import { ProjectService } from './project.service';

@Injectable({
  providedIn: 'root'
})
export class IconsService {
  public sanitizedStyleTag = new BehaviorSubject(null);

  constructor(
    private projectService: ProjectService,
    private sanitizer: DomSanitizer,
    private guideService: GuideService,
  ) { 
    this.projectService.currentProject.subscribe(proj => {
      if (proj) {
        this.setStyleTag(proj)
      }
    })
    this.guideService.currentProject.subscribe(proj => {
      if (proj) {
        this.setStyleTag(proj)
      }
    })
  }

  convertCssToIcons(css) {
    return [...css.match(/\.icon-(.)+:/g)].map(icon => icon.substring(1,icon.length - 1))
  }
   setStyleTag(project) {
    if ("components" in project && "icons" in project.components) {
      const {eot, ttf, woff, svg} = project.components.icons.files
      const css = project.components.icons.css 
      if (eot && ttf && woff && svg && css) {
        const s = `
          <style>
            @font-face {
              font-family: 'icomoon';
              ${eot ? `src:  url('${eot}?lvibx')` : ''};
              src:  
                ${eot ? `url('${eot}?lvibx#iefix') format('embedded-opentype'),` : ''} 
                ${ttf ? `url('${ttf}?lvibx') format('truetype'),` : ''} 
                ${woff ? `url('${woff}?lvibx') format('woff'),` : ''} 
                ${svg ? `url('${svg}?lvibx#icomoon') format('svg');` : ''} 
              font-weight: normal;
              font-style: normal;
              font-display: block;
            }
            
            [class^="icon-"], [class*=" icon-"] {
              /* use !important to prevent issues with browser extensions that change fonts */
              font-family: 'icomoon' !important;
              speak: never;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              line-height: 1;
            
              /* Better Font Rendering =========== */
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }
            ${css}
          </style>
        `
        this.sanitizedStyleTag.next(this.sanitizer.bypassSecurityTrustHtml(s))
      }
    }
  }

}
