import { Component, OnInit } from '@angular/core';
import { UserSubscriptionMixin } from 'src/app/mixins/subscriptions.mixin';
import { CodeBlockSectionModel } from 'src/app/models/code-block-section.model';
import { CodeBlockModel } from 'src/app/models/code-block.model';
import { ClientService } from 'src/app/services/client.service';
import { LoadingService } from 'src/app/services/loading.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-components',
  templateUrl: './components.component.html',
  styleUrls: ['./components.component.scss']
})
export class ComponentsComponent extends UserSubscriptionMixin implements OnInit {
  components = [];

  constructor(
    userService: UserService,
    navService: NavigationService,
    projectService: ProjectService,
    clientService: ClientService,
    private loadingService: LoadingService,
  ) {
    super(userService, navService, projectService, clientService);
  }

  ngOnInit(): void {
    this.currentProjectLoaded.then(() => {

      let { components: projectComponents } = this.currentProject;
      for (const label in projectComponents) {
        if (Object.prototype.hasOwnProperty.call(projectComponents, label)) {
          const name = label[0].toUpperCase() + label.substring(1);
          let newSection = Object.assign({}, (new CodeBlockSectionModel));
          newSection.name = name;
          newSection.toggled = false;
          newSection.blocks = projectComponents[label].map(component => {
            let newBlock = Object.assign({}, (new CodeBlockModel));
            newBlock.name = component.name;
            newBlock.language = 'html'
            if (label === 'buttons') {
              newBlock.content = `<a class="btn${component.name.toLowerCase() !== 'default' ? ' btn-' + this.toKebabCase(component.name) : ''}">${component.text}</a>`;
            } else if (label === 'links') {
              newBlock.content = `<a class="link${component.name.toLowerCase() !== 'default' ? ' link-' + this.toKebabCase(component.name) : ''}">${component.text}</a>`;
            }
            return newBlock;
          })
          this.components.push(newSection);
        }
      }
      console.log(this.components)
    })
  }

  toggleSection(index) {
    this.components[index].toggled = !this.components[index].toggled;
  }

  copyBlock(block) {
    const selBox = document.createElement('textarea');
    selBox.className = 'pos-fixed pos-top pos-left opacity-0'
    selBox.value = block.content;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    block.copied = true;
    setTimeout(() => block.copied = false, 2000)
  }

  toKebabCase(str) {
    return str && str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.toLowerCase())
      .join('-');
  }

}
