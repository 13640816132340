<div class="col-12" *ngIf="currentProject && currentProject.clientPortal">
  <div class="page-heading d-flex align-center justify-between mbxs">
    <h2 class="mrm">Icons and Graphics</h2>
    <a class="btn" (click)="updateProject()">Save changes</a>
  </div>

  <div class="section-description mbxl">
    <h5 class="mbxs">Section description</h5>
    <styles-builder [modules]="iconsGraphicsData.description" (updateModules)="updateModules($event)" [currentProjectId]="currentProject.id"></styles-builder>
  </div>

  <div class="icons-upload mbxl">
    <h5 class="mbxs">Icons</h5>
    <uploader (filesData)="handleFiles($event, 'icons')" [projectId]="currentProject.id"></uploader>
    <div class="project-icons mvxs" *ngIf="currentProject?.clientPortal?.iconsGraphics?.icons">
      <div class="project-icon d-flex align-center justify-between prxs" *ngFor="let icon of currentProject?.clientPortal?.iconsGraphics?.icons; let i = index;">
        <div class="d-flex align-center">
          <img [src]="icon.downloadURL" class="mrxxs">
          <a [href]="icon.downloadURL" target="_blank" rel="noopener">{{icon.name}}</a>
        </div>
        <a (click)="deleteFile(i, 'icons')"><styles-icon name="close"></styles-icon></a>
      </div>
    </div>
  </div>

  <div class="graphics-upload mbxl">
    <h5 class="mbxs">Graphics</h5>
    <uploader (filesData)="handleFiles($event, 'graphics')" [projectId]="currentProject.id"></uploader>
    <table class="project-graphics mvxs" *ngIf="currentProject?.clientPortal?.iconsGraphics?.graphics">
      <tr class="project-graphic" *ngFor="let graphic of currentProject?.clientPortal?.iconsGraphics?.graphics; let i = index;">
        <td>
          <img [src]="graphic.downloadURL">
        </td>
        <td>
          <a [href]="graphic.downloadURL" target="_blank" rel="noopener" class="mrl">{{graphic.name}}</a>
        </td>
        <td>
          <a (click)="deleteFile(i, 'graphics')" class="d-flex align-center"><styles-icon name="close"></styles-icon></a>
        </td>
      </tr>
    </table>
  </div>
</div>