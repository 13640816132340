<div class="container" *ngIf="currentUser">
    <div class="row">
        <div class="col-12">
            <code>
                {{currentUser | json}}
            </code>
            <h3 class="mtl">Settings</h3>
            <div class="form-control">
                <label>Position</label>
                <select [(ngModel)]="currentUser.position">
                    <option value="designer">Designer</option>
                    <option value="developer">Developer</option>
                </select>
            </div>
            <div class="mtm">
                <button class="btn" (click)="saveUser()">Save</button>
            </div>
            <div class="mtm">
                <a (click)="logout()">Logout</a>
            </div>
        </div>
    </div>
</div>