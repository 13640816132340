import { Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import { GuideService } from 'src/app/services/guide.service';

@Component({
  selector: 'app-wordpress-tips',
  templateUrl: './wordpress-tips.component.html',
  styleUrls: ['./wordpress-tips.component.scss']
})
export class WordpressTipsComponent implements OnInit {

  constructor(
    private element: ElementRef,
    private guideService: GuideService,
  ) { }

  ngOnInit(): void {
    this.guideService.setCurrentSubmenu(this.element.nativeElement.querySelectorAll('.guide-section'))
  }


}
