export const SidebarModel = {
    dashboard: {
        links: [
          // {type: 'search'},
          // {type: 'link', label: 'All Projects', slug: ''},
          // {type: 'sectionHeading', label: 'Clients'},
          // {type: 'clients'},
        ],
        cta: {
          openLabel: 'New Project',
          confirmLabel: 'Create Project' 
        }
      },
      design: {
        links: [
          {type: 'sectionHeading', label: 'Base Styles'},
          {type: 'link', label: 'Colors', slug: 'colors'},
          {type: 'link', label: 'Typography', slug: 'typography'},
          {type: 'link', label: 'Spacing', slug: 'spacing'},
          {type: 'sectionHeading', label: 'Components'},
          {type: 'link', label: 'Buttons', slug: 'buttons'},
          {type: 'link', label: 'Links', slug: 'link'},
          // {type: 'link', label: 'Borders', slug: 'borders'},
          // {type: 'sectionHeading', label: 'Components'},
          // {type: 'link', label: 'Button', slug: 'button'},
          // {type: 'link', label: 'Form Fields', slug: 'form-fields'},
          // {type: 'link', label: 'Card', slug: 'card'},
          // {type: 'link', label: 'Slider', slug: 'slider'},
          // {type: 'link', label: 'Modal', slug: 'modal'},
        ]
      },
      develop: {
        links: [
          {type: 'link', label: 'Overview', slug: 'overview'},
          {type: 'link', label: 'Components', slug: 'components'},
          {type: 'link', label: 'Code Blocks', slug: 'code-blocks'},
          {type: 'link', label: 'Stylesheet', slug: 'stylesheet'},
        ]
      },
}