<!-- <div>
    <ngx-monaco-editor [options]="editorOptions" [(ngModel)]="code"></ngx-monaco-editor>
</div> -->

<div class="code-blocks" *ngIf="projects && projects.length">

    <div class="page-heading d-flex align-center justify-between mbs col-12">
        <h1>Code Blocks</h1>
    </div>

    <section class="code-blocks" *ngIf="codeBlockProjects && codeBlockProjects.length">
        <div class="col-12" dragula="projects" [(dragulaModel)]="codeBlockProjects">
            <div class="accordion-control" [ngClass]="{'toggled': project.toggled}"
                *ngFor="let project of codeBlockProjects; let i = index" [attr.id]="toKebabCase(project.name)">
                <div class="accordion-heading cursor-pointer d-flex align-center justify-between"
                    [ngClass]="{'mbs': project.toggled}" (click)="toggleProject(i)" (mouseover)="heading.hovered = true"
                    (mouseleave)="heading.hovered = false" #heading>
                    <div class="left d-flex align-center justify-start">
                        <h3>{{project.clientName ? project.clientName + ': ' : ''}}{{project.name}}</h3>
                    </div>
                    <div class="right d-flex align-center justify-end">
                        <!-- <b class="color-system-error mrxs" *ngIf="heading.hovered" (click)="deleteButton(i)">Delete</b> -->
                        <!-- <styles-icon name="copy" color="neutral-darker" *ngIf="heading.hovered" (click)="duplicateButton($event,project,i)"></styles-icon> -->
                        <styles-icon name="caretDown" color="neutral-darker"
                            [ngStyle]="{'transform': !project.toggled ? 'none' : 'rotate(180deg)'}"></styles-icon>
                    </div>
                </div>
                <div class="accordion-body" *ngIf="project.toggled">
                    <div class="code-block" *ngFor="let section of project.codeBlocks; let sectionInd = i">
                        <h4 class="mbxs">{{section.name}}</h4>
                        <div class="section-description mbm" [innerHTML]="section.description"></div>
                        <div class="block mbl mls" *ngFor="let block of section.blocks; let blockInd = index">
                            <h5 class="block-name mbxxs d-flex justify-between">
                                <span>{{block.name}}</span>
                                <div class="options">
                                    <span [ngClass]="{'hide-fully': !block.copied}" class="color-system-info d-inline-flex align-center mrxxs">
                                        <styles-icon name="check" color="systemInfo" class="mrxxxs"></styles-icon>
                                        <span class="type-xxs">copied!</span>
                                    </span>
                                    <styles-icon class="cursor-pointer" name="copy" color="neutralDarker" (click)="copyBlock(block)" [ngClass]="{'hide-fully': block.copied}"></styles-icon>
                                    <!-- <styles-icon class="cursor-pointer" name="edit" color="neutralDarker" (click)="toggleBlockModal('edit', sectionInd, blockInd)"></styles-icon>
                                    <styles-icon class="cursor-pointer" name="close" color="systemError" (click)="deleteBlock(sectionInd, blockInd)"></styles-icon> -->
                                </div>
                            </h5>
                            <div class="block-content mbxxs">
                                <ngx-monaco-editor
                                    [options]="{theme: 'vs-dark', language: block.language, readOnly: true}"
                                    [(ngModel)]="block.content"
                                ></ngx-monaco-editor>
                        
                            </div>
                            <div  class="block-description" [innerHTML]="block.description"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>