import { ButtonModel } from 'src/app/models/button.model';
import { LinkModel } from 'src/app/models/link.model';

export class ProjectModel {
    name = '';
    owner = '';
    id = '';
    client = '';
    css = '';
    variables = {
        borders: {
            boxShadows: [],
            radius: [],
            widths: [],
        },
        colors: [
            { group: "", hex: "#110c0c", label: "Neutral Darker", slug: "neutral-darker"},
            { group: "", hex: "#eaeaea", label: "Neutral", slug: "neutral"},
            { group: "", hex: "#ffffff", label: "Neutral Brighter", slug: "neutral-brighter"}
        ],
        spacing: {
            base: { mobile: 16, hidden: false, desktop: 16 },
            xxxl: { hidden: false, desktop: 128, mobile: 128 },
            xxl: { desktop: 96, hidden: false, mobile: 96 },
            xl: { mobile: 64, hidden: false, desktop: 64 },
            l: { hidden: false, desktop: 40, mobile: 40 },
            m: { desktop: 32, mobile: 32, hidden: false },
            s: { hidden: false, desktop: 24, mobile: 24 },
            xs: { desktop: 16, mobile: 16, hidden: false },
            xxs: { desktop: 8, hidden: false, mobile: 8 },
            xxxs: { mobile: 4, hidden: false, desktop: 4 },
        },
        typography: {
            fonts: [],
            heights: {
                base: { hidden: false, mobile: 24, desktop: 24 },
                xxxl: { hidden: false, mobile: 64, desktop: 64 },
                xxl: { hidden: false, mobile: 40, desktop: 40 },
                xl: { hidden: false, mobile: 32, desktop: 32 },
                l: { hidden: false, mobile: 30, desktop: 30 },
                m: { hidden: false, mobile: 27, desktop: 27 },
                s: { hidden: false, mobile: 24, desktop: 24 },
                xs: { hidden: false, mobile: 21, desktop: 21 },
                xxs: { hidden: false, mobile: 18, desktop: 18 },
                xxxs: { hidden: false, mobile: 15, desktop: 15 },
            },
            sizes: {
                base: { hidden: false, mobile: 16, desktop: 16 },
                xxxl: { hidden: false, mobile: 256, desktop: 64 },
                xxl: { hidden: false, mobile: 128, desktop: 48 },
                xl: { hidden: false, mobile: 64, desktop: 36 },
                l: { hidden: false, mobile: 40, desktop: 24 },
                m: { hidden: false, mobile: 32, desktop: 18 },
                s: { hidden: false, mobile: 24, desktop: 16 },
                xs: { hidden: false, mobile: 16, desktop: 14 },
                xxs: { hidden: false, mobile: 8, desktop: 12 },
                xxxs: { hidden: false, mobile: 4, desktop: 10 },
            },
            tags: {
                body: { color: "", font: "", hidden: false, line: "Base", size: "Base", weight: "normal" },
                h1: { color: "", font: "", hidden: false, line: "XXXL", size: "XXXL", weight: "normal" },
                h2: { color: "", font: "", hidden: false, line: "XXL", size: "XXL", weight: "normal" },
                h3: { color: "", font: "", hidden: false, line: "XL", size: "XL", weight: "normal" },
                h4: { color: "", font: "", hidden: false, line: "L", size: "L", weight: "normal" },
                h5: { color: "", font: "", hidden: false, line: "M", size: "M", weight: "normal" },
                h6: { color: "", font: "", hidden: false, line: "S", size: "S", weight: "normal" },
                small: { color: "", font: "", hidden: false, line: "XS", size: "XS", weight: "normal" }
            },
            weights: [
                { hidden: true, value: 100, label: "lighter" },
                { hidden: true, value: 300, label: "light" },
                { hidden: false, value: 500, label: "normal" },
                { hidden: false, value: 600, label: "bold" },
                { hidden: true, value: 700, label: "bolder" },
                { hidden: true, value: 900, label: "black" }
            ],
        },
    };
    components = {
        buttons: [],
        links: [],
    };
    codeBlocks = [];
    members = [];
    updated = {};
    created = {};
    settings = {
        modules: {
            design: {
                colors: true,
                typography: true,
                spacing: true,
                buttons: true,
                links: true,
            },
            dev: {
                overview: true,
                codeBlocks: true,
                components: true,
                stylesheet: true,
            }
        },
        icon: false,
        colors: {
            headerBG: 'neutral-darker',
            headerText: 'neutral-brighter',
            buttons: 'neutral-darker',
            links: 'neutral-darker'
        },
        iconSvg: '',
        iconBg: { group: "", hex: "#eaeaea", label: "Neutral", slug: "neutral"},
    }

    constructor() {
        this.components.buttons.push(Object.assign({}, new ButtonModel));
        this.components.links.push(Object.assign({}, new LinkModel));

        this.components.buttons[0].name = 'Default';
        this.components.links[0].name = 'Default';
    }
}
