import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { from, BehaviorSubject, Observable, Subscription } from 'rxjs';
import Client from '../interfaces/Client';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  subscriptions: Subscription = new Subscription();
  clients = new BehaviorSubject([])
  currentClient = new BehaviorSubject({})
  
  constructor(
    private afs: AngularFirestore, 
    private userService: UserService, 
  ) {

    // set clients once user is loaded
    this.userService.user.subscribe((u) => {
      if (u && u.id) {
        this.getClients().subscribe((clients) => {
          this.clients.next([...clients]);
        })
      }
    })
        
  }
  setClients(t) {
    return new Promise<void>((res) => {
      t.subscriptions.add(
        t.clientService.clients.subscribe((clients: any) => {
          if (clients && clients.length) {
            t.clients = clients;
            res()
          }
        })
      )
    })
  }
  getClients() {
    const clientsRef: AngularFirestoreCollection<any> = this.afs.collection('clients', ref => 
      ref.orderBy('updated','desc')
    );
    return from(clientsRef.valueChanges());
  }
  getCurrentClient(id) {
    const clientRef: AngularFirestoreCollection<any> = this.afs.collection('clients', ref => ref.where('id', '==', id))
    return from(clientRef.valueChanges());
  }

  getCurrentUserOwnedClients(uid) {
    const userClientsRef: AngularFirestoreCollection<any> = this.afs.collection('clients', ref => ref.where('owner', '==', uid))
    return from(userClientsRef.valueChanges());
  }

  createClient(clientData) {
    return new Promise((resolve, reject) => {
      clientData.created = firebase.firestore.FieldValue.serverTimestamp();
      clientData.updated = firebase.firestore.FieldValue.serverTimestamp();
      this.afs.collection('clients').add(clientData).then((dRef) => {
        dRef.update({ id: dRef.id }).then(() => {
          resolve(dRef.id);
        });
      });
    })
  }

  updateClient(clientId, d) {
    const clientRef: AngularFirestoreDocument<any> = this.afs.doc(`clients/${clientId}`);
    d.updated = firebase.firestore.FieldValue.serverTimestamp();
    return clientRef.update(d);
  }

  deleteClient(clientId) {
    const clientRef: AngularFirestoreDocument<any> = this.afs.doc(`clients/${clientId}`);
    return clientRef.delete();
  }
}
