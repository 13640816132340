import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { from, BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import Project from '../interfaces/Project';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  subscriptions: Subscription = new Subscription();
  projects = new BehaviorSubject([])
  currentProject = new BehaviorSubject({})
  filteredProjects = new BehaviorSubject({})

  constructor(
    private afs: AngularFirestore, 
    private userService: UserService, 
  ) {

    // set projects once user is loaded
    this.userService.user.subscribe((u) => {
      if (u && u.id) {
        this.getProjects().subscribe((projects) => {
          this.projects.next([...projects]);
          this.filteredProjects.next([...projects]);
        })
      }
    })
        
  }
  setProjects(t) {
    return new Promise<void>((res) => {
      t.subscriptions.add(
        t.projectService.projects.subscribe((projects: any) => {
          if (projects && projects.length) {
            t.projects = projects;
            res()
          }
        })
      )
    })
  }
  setCurrentProject(id) {
    if (id) {
      const cp = this.projects.getValue().filter((p) => p.id === id)[0]
      this.currentProject.next(cp)
    } else {
      this.currentProject.next({})
    }
  }
  getProjects() {
    const projectsRef: AngularFirestoreCollection<any> = this.afs.collection('projects', ref => 
      ref.orderBy('updated','desc')
    );
    return from(projectsRef.valueChanges());
  }
  getCurrentProject(id) {
    const projectRef: AngularFirestoreCollection<any> = this.afs.collection('projects', ref => ref.where('id', '==', id))
    return from(projectRef.valueChanges());
  }
  getProjectByUrl(url) {
    console.log(url);
    const projectRef: AngularFirestoreCollection<any> = this.afs.collection('projects', ref => ref.where('settings.url', '==', url))
    return from(projectRef.valueChanges())
  }
  getCurrentUserOwnedProjects(uid) {
    const userProjectsRef: AngularFirestoreCollection<any> = this.afs.collection('projects', ref => ref.where('owner', '==', uid))
    return from(userProjectsRef.valueChanges());
  }

  // getCurrentUserProjects(userProjects) {
  //   let projects = []
  //   if (userProjects && userProjects.length) {
  //     userProjects.forEach(up => {
  //       const userProjectsRef: AngularFirestoreCollection<any> = this.afs.collection('projects', ref => ref.where('id', '==', up))
  //       projects.push(from(userProjectsRef.valueChanges()))
  //     })
  //   }
  //   this.projects$.next(projects);
  //   return this.projects$;
  // }

  // getProjectMembers(id) {
  //   const membersRef: AngularFirestoreCollection<any> = this.afs.collection('users', ref => ref.where('projects', 'array-contains', id))
  //   return from(membersRef.valueChanges());
  // }

  createProject(projectData) {
    return new Promise((resolve, reject) => {
      projectData.created = firebase.firestore.FieldValue.serverTimestamp();
      projectData.updated = firebase.firestore.FieldValue.serverTimestamp();
      this.afs.collection('projects').add(projectData).then((dRef) => {
        dRef.update({ id: dRef.id }).then(() => {
          resolve(dRef.id);
        });
      });
    })
  }

  updateProject(projectId, d) {
    const data = JSON.parse(JSON.stringify(d))
    const projectRef: AngularFirestoreDocument<any> = this.afs.doc(`projects/${projectId}`);
    data.updated = firebase.firestore.FieldValue.serverTimestamp();
    return projectRef.update(data);
  }

  deleteProject(projectId) {
    const projectRef: AngularFirestoreDocument<any> = this.afs.doc(`projects/${projectId}`);
    return projectRef.delete();
  }

  filterByClient(clientID) {
    let cp;
    if(clientID !== null)
      cp = this.projects.getValue().filter((p) => p.client === clientID);
    else 
      cp = this.projects.getValue();
    this.filteredProjects.next(cp);
  }

  searchProjects(searchQuery) {
    this.filteredProjects.next(this.projects.getValue().filter(p => {
      return p.name.toLowerCase().includes(searchQuery) || 
             p.client.toLowerCase().includes(searchQuery);
    }));
  }
  sortProjects(sortOption) {
    let sorted;
    switch(sortOption) {
      case 'Last Modified':
        sorted = this.projects.getValue().sort(((a, b) => {
            if (!!a.updated && !!b.updated) {
              return b.updated.seconds - a.updated.seconds;
            }
          }));
      break;
      
      case 'Date Created':
        sorted = this.projects.getValue().sort(((a, b) => {
            if (!!a.created && !!b.created) {
              return b.created.seconds - a.created.seconds;
            }
          }));
      break;
      
      case 'Alphabetical': 
        sorted = this.projects.getValue().sort((a, b) => {
          if (a.name && b.name) {
            var nameA=a.name.toLowerCase(), nameB=b.name.toLowerCase();
            if (nameA < nameB)
             return -1;
            if (nameA > nameB)
             return 1;
            return 0;
          }
        });
      break;
      }
      this.filteredProjects.next(sorted);
  }


  convertTagsToElements(proj) {
    if (
      (!proj.variables.typography.elements ||
      !proj.variables.typography.elements.length) &&
       proj.variables.typography.tags && 
       Object.keys(proj.variables.typography.tags).length 
    ) {
      proj.variables.typography.elements = [];
      const tags = proj.variables.typography.tags;
      const sizes = proj.variables.typography.sizes;
      const heights = proj.variables.typography.heights;
      const spacing = proj.variables.spacing;

      proj.variables.typography.elements = Object.keys(tags).map(function(tag) {
        let element = {
          tagOrClass: 'htmlTag',
          tag: null,
          classLabel: null,
          classSlug: null,
          fontSize: {desktop: null,mobile:null},
          lineHeight: {desktop: null,mobile:null},
          fontFamily: {desktop: null,mobile:null},
          color: {desktop: null,mobile:null}, 
          fontWeight: {desktop: null,mobile:null},
          letterSpacing: {desktop: null,mobile:null},
          marginTop: {desktop: null,mobile:null},
          marginBottom: {desktop: null,mobile:null},
          textDecoration: {desktop: null,mobile:null},
          textTransform: {desktop: null,mobile:null},
          testText: 'Lorem ipsum, dolor sit amet.',
          entryType: 'css',
          css: {desktop: null,mobile: null}
        }
        element.tag = tag;
        const tg = tags[tag];
        if ('font' in tg && tg.font) element.fontFamily.desktop = tg.font;
        if ('size' in tg && tg.size) {
          element.fontSize.desktop = parseInt(sizes[tg.size.toLowerCase()].desktop);
          element.fontSize.mobile = parseInt(sizes[tg.size.toLowerCase()].mobile);
        }
        if ('line' in tg && tg.line) {
          element.lineHeight.desktop = parseInt(heights[tg.line.toLowerCase()].desktop);
          element.lineHeight.mobile = parseInt(heights[tg.line.toLowerCase()].mobile);
        }
        if ('color' in tg && tg.color) element.color.desktop = tg.color
        if ('weight' in tg && tg.weight) element.fontWeight.desktop = tg.weight
        if ('letterSpacing' in tg && tg.letterSpacing) element.letterSpacing.desktop = tg.letterSpacing
        if ('marginTop' in tg && tg.marginTop != 0) {
          if (parseInt(tg.marginTop)) {
            Object.keys(spacing).forEach(size => {
              if (spacing[size] == tg.marginTop) element.marginTop.desktop = size;
            })
          } else {
            element.marginTop.desktop = tg.marginTop
          }
        }
        if ('marginBottom' in tg && tg.marginBottom != 0) {
          if (parseInt(tg.marginBottom)) {
            Object.keys(spacing).forEach(size => {
              if (spacing[size] == tg.marginBottom) element.marginBottom.desktop = size;
            })
          } else {
            element.marginBottom.desktop = tg.marginBottom
          }
        }
        return element;
      }).sort((a,b) => {
        if(a.tag < b.tag) return -1; 
        if(a.tag > b.tag) return 1; 
        return 0;
      })
      this.updateProject(proj.id, proj).then(res => {
        console.log(res);
      });
    }
  }

}
