<div class="stylesheet" *ngIf="currentProject && currentProject.variables">

    <h1 class="mbs">Exports</h1>

    <section class="stylesheet mbxl d-flex flex-col">
        <h3 class="mbxxs">Stylesheet</h3>
        <textarea name="css-textarea" id="css-textarea" [(ngModel)]="css" #cssTextarea class="full-width mbxs"></textarea>
        <div class="d-flex">
            <button (click)="copyCss()" class="btn" [disabled]="loading">{{copying ? 'Copied!' : 'Copy Stylesheet'}}</button>
        </div>
    </section>
    <section class="colors-json mbxl d-flex flex-col">
        <h3 class="mbxxs">Colors JSON</h3>
        <textarea name="css-textarea" id="css-textarea" [(ngModel)]="colorsJSON" #colorsJsonEl class="full-width mbxs"></textarea>
        <div class="d-flex">
            <button (click)="copyColorsJSON()" class="btn">{{copyingJSON ? 'Copied!' : 'Copy JSON'}}</button>
        </div>
    </section>
    <section class="colors-json mbxl d-flex flex-col">
        <h3 class="mbxxs">Sassy Variables</h3>
        <textarea name="css-textarea" id="css-textarea" [(ngModel)]="variables" #variablesEl class="full-width mbxs"></textarea>
        <div class="d-flex">
            <button (click)="copyVariables()" class="btn">{{copyingVariables ? 'Copied!' : 'Copy Variables'}}</button>
        </div>
    </section>
    <!-- <pre>
        {{currentProject.variables | json}}
    </pre> -->

</div>