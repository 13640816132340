<div class="input-wrap" *ngIf="!url">
  <input
    *ngIf="!url"
    [attr.accept]="fileType"
    type="file"
    [attr.name]="fileName"
    (change)="uploadFile($event)"
    autocomplete="off"
    class="full-width"
  >
  <div *ngIf="file" style="height: 0; visibility: hidden;">
    <upload-task [file]="file" (completed)="handleCompletedFile($event)" [projectId]="currentProjectId"></upload-task>
  </div>
</div>

<div *ngIf="url" class="d-flex justify-between align-center">
  <a 
    [href]="url" 
    class="link txt-underline d-inline-flex align-center" 
    style="height: 24px; overflow:hidden"
  >
    <styles-icon name="file" class="mrxxxs"></styles-icon> File link
  </a>
  <styles-icon name="close" (click)="resetFile()" class="mlxxs"></styles-icon>
</div>