<div class="col-12 " *ngIf="currentProject && currentProject.clientPortal">
  <div class="page-heading d-flex align-center justify-between mbxs">
    <h2 class="mrm">Links</h2>
    <a class="btn" (click)="updateProject()">Save changes</a>
  </div>

  <div class="groups mbxl mtxl">
    <div class="group mbl" *ngFor="let group of linkGroups; let gi = index">
      <h3 class="mbxxs d-flex justify-between align-center">
        <span>{{group.label ? group.label : 'Group Name'}}</span>
        <a class="cursor-pointer color-system-error font-normal type-xs d-flex align-center" (click)="removeGroup(gi)"><styles-icon name="close" class="mrxxs"></styles-icon> <span class="txt-underline">Remove Group</span></a>
      </h3>
      <div class="form-control mbl">
        <label [for]="'group-label-' + gi">Label</label>
        <input type="text" class="full-width" [name]="'group-label-' + gi" [(ngModel)]="group.label">
      </div>
      <div class="group-description mbl">
        <h5 class="mbxs">Group description</h5>
        <styles-builder [modules]="group.description" (updateModules)="updateModules($event, gi)" [currentProjectId]="currentProject.id"></styles-builder>
      </div>
      <div class="group-links">
        <h5 class="mbxs">Guideline Links</h5>
        <table class="links full-width" style="text-align: left;">
          <thead>
            <th>Label</th>
            <th>Type</th>
            <th>Input</th>
          </thead>
          <tr *ngFor="let link of group.links; let li = index">
            <td class="label">
              <input type="text" [(ngModel)]="link.label" name="label-{{li}}" id="label-{{li}}" class="full-width">
            </td>
            <td class="type">
              <select [(ngModel)]="link.type" class="full-width">
                {{ link.type }}
                <option value="link" selected>Link</option>
                <option value="file">File</option>
              </select>
            </td>
            <td class="input flex-grow">
              <div class="link" *ngIf="link.type == 'link'">
                <input type="url" name="url-{{li}}" id="url-{{li}}" [(ngModel)]="link.url" class="full-width">
              </div>
              <div class="file" *ngIf="link.type == 'file'">
                <!-- <input type="file" name="file-{{li}}" id="file-{{li}}"> -->
                <styles-file-upload 
                  [url]="link.value" 
                  fileName="toKebabCase(link.label) + li"
                  (updateUrl)="updateLinkFile($event, gi, li)"
                  (removeUrl)="removeLinkFile($event, gi, li)"
                  class="full-width d-flex align-center mtxxxs"
                ></styles-file-upload>
              </div>
            </td>
          </tr>
        </table>
        <div class="add-link-wrap txt-r mtxs">
          <a class="txt-underline cursor-pointer d-inline-flex align-center" (click)="addLink(gi)">
            <styles-icon name="add" class="mrxxxs"></styles-icon>
            Add Link
          </a>
        </div>
      </div>
    </div>
    <div class="add-link-wrap mtxs">
      <a class="txt-underline cursor-pointer d-inline-flex align-center" (click)="addGroup()">
        <styles-icon name="add" class="mrxxxs"></styles-icon>
        Add Group
      </a>
    </div>
  </div>

</div>