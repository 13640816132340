<div class="icons" *ngIf="currentProject">
  <div class="page-heading d-flex align-center justify-between mbxs">
    <h1>Icons</h1>
  </div>
  <section class="description mbs">
    <div class="col-12">
      <p>Icon system is based on <a href="https://icomoon.io/app/" class="link txt-underline">IcoMoon</a>.</p>
    </div>
  </section>
  <section class="font-files">
    <div class="col-12 mbs">
      <h3>Font files</h3>
      <p>Upload the .ttf, .svg, .eot, and .woff files found in the IcoMoon export:</p>
      <div class="form-control">
        <label>EOT</label>
        <input *ngIf="!eot" accept=".eot" type="file" id="fonteot" (change)="uploadFile($event, 'eot')" name="fonteot" autocomplete="off">
        <div *ngIf="eot" class="d-flex justify-between align-center">
          <a [href]="eot" class="link txt-underline d-inline-flex align-center"><styles-icon name="file" class="mrxxxs"></styles-icon> EOT file</a>
          <styles-icon name="close" (click)="resetFile('eot')"></styles-icon>
        </div>
      </div>
      <div class="form-control">
        <label>TTF</label>
        <input *ngIf="!ttf" accept=".ttf" type="file" id="fontttf" #fontttf (change)="uploadFile($event, 'ttf')" name="fontttf" autocomplete="off">
        <div *ngIf="ttf" class="d-flex justify-between align-center">
          <a [href]="ttf" class="link txt-underline d-inline-flex align-center"><styles-icon name="file" class="mrxxxs"></styles-icon> TTF file</a>
          <styles-icon name="close" (click)="resetFile('ttf')"></styles-icon>
        </div>
      </div>
      <div class="form-control">
        <label>WOFF</label>
        <input *ngIf="!woff" accept=".woff" type="file" id="fontwoff" #fontwoff (change)="uploadFile($event, 'woff')" name="fontwoff" autocomplete="off">
        <div *ngIf="woff" class="d-flex justify-between align-center">
          <a [href]="woff" class="link txt-underline d-inline-flex align-center"><styles-icon name="file" class="mrxxxs"></styles-icon> WOFF file</a>
          <styles-icon name="close" (click)="resetFile('woff')"></styles-icon>
        </div>
      </div>
      <div class="form-control">
        <label>SVG</label>
        <input *ngIf="!svg" accept=".svg" type="file" id="fontsvg" #fontsvg (change)="uploadFile($event, 'svg')" name="fontsvg" autocomplete="off">
        <div *ngIf="svg" class="d-flex justify-between align-center">
          <a [href]="svg" class="link txt-underline d-inline-flex align-center"><styles-icon name="file" class="mrxxxs"></styles-icon> SVG file</a>
          <styles-icon name="close" (click)="resetFile('svg')"></styles-icon>
        </div>
      </div>
    </div>
  </section>
  <section class="font-css">
    <div class="col-12 mbs">
      <h3>Font CSS</h3>
      <p class="mbxs">Copy the contents of the style.css file found in the IcoMoon export and paste here:</p>
      <textarea name="bulk-color-add" id="bulk-color-add" class="mbxs full-width" rows="8" [(ngModel)]="iconCss"></textarea>
      <a class="btn" (click)="saveCss()">Save CSS</a>
    </div>
  </section>
  <div class="rendered-icons" *ngIf="icons?.length">
    <div class="col-12">
      <h3>Rendered Icons</h3>
      <div class="d-flex flex-wrap">
        <span *ngFor="let icon of icons" class="mhxxs mbxs" [class]="icon"></span>
      </div>
    </div>
  </div>

</div>
