import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { environment } from '../environments/environment';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './authentication/login/login.component';
import { SignupComponent } from './authentication/signup/signup.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { ProjectSingleComponent } from './pages/project-single/project-single.component';
import { SearchComponent } from './pages/search/search.component';
import { ModalComponent } from './components/modal/modal.component';
import { IconComponent } from './components/icon/icon.component';
import { MainComponent } from './layout/main/main.component';
import { ColorComponent } from './pages/project-single/design/color/color.component';
import { TypographyComponent } from './pages/project-single/design/typography/typography.component';
import { SpacingComponent } from './pages/project-single/design/spacing/spacing.component';
import { BordersComponent } from './pages/project-single/design/borders/borders.component';
import { ButtonsComponent } from './pages/project-single/design/buttons/buttons.component';
import { LinkComponent } from './pages/project-single/design/link/link.component';
import { FormFieldsComponent } from './pages/project-single/design/form-fields/form-fields.component';
import { CardComponent } from './pages/project-single/design/card/card.component';
import { SliderComponent } from './pages/project-single/design/slider/slider.component';
import { NavigationComponent } from './layout/navigation/navigation.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { OverviewComponent } from './pages/project-single/dev/overview/overview.component';
import { StylesheetComponent } from './pages/project-single/dev/stylesheet/stylesheet.component';
import { CodeBlocksComponent } from './pages/project-single/dev/code-blocks/code-blocks.component';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { QuillModule } from 'ngx-quill';
import { DragulaModule } from 'ng2-dragula';
import { ComponentsComponent } from './pages/project-single/dev/components/components.component';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { AllCodeBlocksComponent } from './pages/all-code-blocks/all-code-blocks.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { ProjectSettingsComponent } from './pages/project-single/project-settings/project-settings.component';
import { GuideComponent } from './pages/guide/guide.component';
import { GuideDashboardComponent } from './pages/guide/guide-dashboard/guide-dashboard.component';
import { WordpressTipsComponent } from './pages/guide/wordpress-tips/wordpress-tips.component';
import { BrandResourcesComponent } from './pages/guide/brand-resources/brand-resources.component';
import { GuideLinksComponent } from './pages/guide/guide-links/guide-links.component';
import { HarmonicaComponent } from './pages/project-single/project-settings/harmonica/harmonica.component';
import { ClientGuidelinesComponent } from './pages/project-single/project-settings/client-guidelines/client-guidelines.component';
import { ClientLogosComponent } from './pages/project-single/project-settings/client-logos/client-logos.component';
import { ClientColorsComponent } from './pages/project-single/project-settings/client-colors/client-colors.component';
import { ClientFontsComponent } from './pages/project-single/project-settings/client-fonts/client-fonts.component';
import { ClientIconsGraphicsComponent } from './pages/project-single/project-settings/client-icons-graphics/client-icons-graphics.component';
import { ClientLinksComponent } from './pages/project-single/project-settings/client-links/client-links.component';
import { IconsComponent } from './pages/project-single/design/icons/icons.component';
import { LogosComponent } from './pages/project-single/design/logos/logos.component';
import { BuilderComponent } from './components/builder/builder.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { DropzoneDirective } from './directives/dropzone.directive';
import { UploaderComponent } from './components/uploader/uploader.component';
import { UploadTaskComponent } from './components/upload-task/upload-task.component';
import { ClientDashboardComponent } from './pages/project-single/project-settings/client-dashboard/client-dashboard.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    ProjectsComponent,
    ProjectSingleComponent,
    SearchComponent,
    ModalComponent,
    IconComponent,
    MainComponent,
    ColorComponent,
    TypographyComponent,
    SpacingComponent,
    BordersComponent,
    ButtonsComponent,
    LinkComponent,
    FormFieldsComponent,
    CardComponent,
    SliderComponent,
    NavigationComponent,
    DropdownComponent,
    OverviewComponent,
    StylesheetComponent,
    CodeBlocksComponent,
    ComponentsComponent,
    AllCodeBlocksComponent,
    SettingsComponent,
    ProjectSettingsComponent,
    GuideComponent,
    GuideDashboardComponent,
    WordpressTipsComponent,
    BrandResourcesComponent,
    GuideLinksComponent,
    HarmonicaComponent,
    ClientGuidelinesComponent,
    ClientLogosComponent,
    ClientColorsComponent,
    ClientFontsComponent,
    ClientIconsGraphicsComponent,
    ClientLinksComponent,
    IconsComponent,
    LogosComponent,
    BuilderComponent,
    FileUploadComponent,
    DropzoneDirective,
    UploaderComponent,
    UploadTaskComponent,
    ClientDashboardComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    FormsModule,
    AngularFireFunctionsModule,
    MonacoEditorModule.forRoot(),
    QuillModule.forRoot(),
    DragulaModule.forRoot(),
    NgxPageScrollCoreModule,
    NgxPageScrollModule,
    ReactiveFormsModule,
    AngularFireStorageModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
