import { Injectable } from '@angular/core';
import { CanActivate,CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import User from '../interfaces/User';
import { UserService } from './user.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate,CanActivateChild{
  
  constructor(private userService: UserService, private router: Router, private afAuth: AngularFireAuth) {}

  isLoggedIn() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = await this.isLoggedIn();
    console.log(user);
    if (user) return true;
    this.router.navigateByUrl('/login');
    return false;
  }

  async canActivateChild(next: ActivatedRouteSnapshot,state: RouterStateSnapshot) {
    const user = await this.isLoggedIn();
    if (user) return true;
    this.router.navigateByUrl('/login');
    return false;
  }
}
