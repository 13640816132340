import User from "../interfaces/User";

export const UserModel: User = {
    id: '',
    email: '',
    displayName: '',
    teams: [],
    photoURL: '',
    projects: [],
    position: '',
    selectedView: 'dashboard',
    selectedLinks: {
        design: 'color',
        develop: 'overview',
        projectSettings: 'harmonica',
    }
};