import { Component, OnInit, ViewChild } from '@angular/core';
import { arrayExtensions } from 'mobx/dist/internal';
import { UserSubscriptionMixin } from 'src/app/mixins/subscriptions.mixin';
import { CodeBlockSectionModel } from 'src/app/models/code-block-section.model';
import { CodeBlockModel } from 'src/app/models/code-block.model';
import { ClientService } from 'src/app/services/client.service';
import { LoadingService } from 'src/app/services/loading.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-code-blocks',
  templateUrl: './code-blocks.component.html',
  styleUrls: ['./code-blocks.component.scss']
})
export class CodeBlocksComponent extends UserSubscriptionMixin implements OnInit {
  @ViewChild('editSectionModal') editSectionModal;
  @ViewChild('editBlockModal') editBlockModal;
  @ViewChild('editBulkCopyModal') editBulkCopyModal;
  quillModules = {
    toolbar: [['link', 'bold', 'italic', 'underline', 'strike', 'blockquote', { list: 'ordered' }, { list: 'bullet' }]],
  };
  editorOptions = {theme: 'vs-dark', language: 'javascript'};
  editSection = null;
  editBlock = null;
  editBulkCopy = null;

  constructor(
    userService: UserService,
    navService: NavigationService,
    projectService: ProjectService,
    clientService: ClientService,
    private loadingService: LoadingService,
  ) {
    super(userService, navService, projectService, clientService);
  }

  ngOnInit(): void {
    this.currentProjectLoaded.then(() => {
      if (!this.currentProject.codeBlocks) {
        this.currentProject.codeBlocks = []
      }
    })
  }

  updateProject(modal = null) {
    this.loadingService.startLoading();
    this.projectService.updateProject(this.currentProject.id, this.currentProject).then(() => {
      this.loadingService.stopLoading()
      if (modal) {
        this.editSection = null;
        this.editBlock = null;
        this[`edit${modal}Modal`].toggleModal();
      }
    });
  }

  // MODAL TOGGLES
  toggleSectionModal(type, sectionIndex = null) {
    if (type === 'new') {
      this.editSection = Object.assign({}, (new CodeBlockSectionModel))
    } else {
      let section = Object.assign({}, this.currentProject.codeBlocks[sectionIndex])
      section.blocks = this.currentProject.codeBlocks[sectionIndex].blocks.map((b) => Object.assign({}, b))
      this.editSection = Object.assign({}, section);
    }
    this.editSection.newSection = type === 'new' ? true : false;
    this.editSection.sectionIndex = sectionIndex;
    this.editSectionModal.toggleModal();
  }
  toggleBlockModal(type, sectionIndex, blockIndex = null) {
    if (type === 'new') {
      this.editBlock = Object.assign({}, (new CodeBlockModel))
    } else {
      this.editBlock = Object.assign({}, this.currentProject.codeBlocks[sectionIndex].blocks[blockIndex]);
    }
    this.editBlock.newBlock = type === 'new' ? true : false;
    this.editBlock.sectionIndex = sectionIndex;
    this.editBlock.blockIndex = blockIndex;
    this.editBlockModal.toggleModal();
  }

  // SECTIONS
  saveSection() {
    if(!this.currentProject.codeBlocks) this.currentProject.codeBlocks = [];
    
    let section = Object.assign({}, this.editSection)
    section.blocks = this.editSection.blocks.map((b) => Object.assign({}, b))

    if (this.editSection.newSection) {
      this.currentProject.codeBlocks.push(section);
    } else {
      this.currentProject.codeBlocks[this.editSection.index] = Object.assign({}, section);
    }

    this.updateProject('Section')
  }
  toggleSection(index) {
    this.currentProject.codeBlocks[index].toggled = !this.currentProject.codeBlocks[index].toggled;
    this.projectService.updateProject(this.currentProject.id, this.currentProject)
  }
  deleteSection(index) {
    this.currentProject.codeBlocks.splice(index,1);
    this.updateProject();
  }


  // BLOCKS
  saveBlock() {
    const {newBlock, sectionIndex, blockIndex} = this.editBlock;

    if(!this.currentProject.codeBlocks[sectionIndex].blocks) this.currentProject.codeBlocks[sectionIndex].blocks = [];
    
    console.log(this.editBlock);
    let block = Object.assign({}, this.editBlock)
    console.log(block);
    
    if (newBlock) {
      console.log(this.currentProject.codeBlocks[sectionIndex]);
      this.currentProject.codeBlocks[sectionIndex].blocks.push(block);
      console.log(this.currentProject.codeBlocks[sectionIndex]);
    } else {
      this.currentProject.codeBlocks[sectionIndex].blocks[blockIndex] = block;
    }
    this.updateProject('Block')
  }
  deleteBlock(sectionIndex, blockIndex) {
    this.currentProject.codeBlocks[sectionIndex].blocks.splice(blockIndex,1);
    this.updateProject();
  }
  copyBlock(block) {
    this.copyStr(block.content);
    block.copied  = true;
    setTimeout(() => block.copied = false, 2000)
  }
  copyStr(str) {
    const selBox = document.createElement('textarea');
    selBox.className = 'pos-fixed pos-top pos-left opacity-0'
    selBox.value = str;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }


  // minify
  toggleMinifyModal(section = null) {
    if (section) {
      this.editBulkCopy = {...section, copied: false};
      this.editBulkCopy.blocks = section.blocks.map(b => { 
        return {...b, selected: false} 
      });
      this.editBulkCopyModal.toggleModal();
    } else {
      this.editBulkCopy = null;
      this.editBulkCopyModal.toggleModal();
    }
  }
  copyBlocks(minify) {
    console.log(minify)
    if (!this.editBulkCopy.copied) {
      let minStr = this.editBulkCopy.blocks.reduce((str, b) => {
        if (b.selected) {
          return minify ? str + b.content
            // remove comments
            .replaceAll(/(?:\r\n|\n|^)(?:[^'"])*?(?:'(?:[^\r\n\\']|\\'|[\\]{2})*'|"(?:[^\r\n\\"]|\\"|[\\]{2})*")*?(?:[^'"])*?(\/\*(?:[\s\S]*?)\*\/|\/\/.*)/g, '')
            // remove new lines
            .replaceAll(/\n|\r/g, '')
            // replace multiple spaces with one space
            .replaceAll(/\s+/g, ' ') : str + b.content + '\n\n';
        }
        return str
      }, '');
      this.copyStr(minStr);
      this.editBulkCopy.copied = true;
      setTimeout(() => this.editBulkCopy.copied = false, 2000)
    }
  }

  toKebabCase(str) {
    return str && str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.toLowerCase())
      .join('-');
  }

}
