import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'styles-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})

export class ModalComponent implements OnInit {
  @Input() showButton = true;
  @Output() onHide = new EventEmitter<boolean>();
  modalToggled = false;
  constructor() { }

  ngOnInit(): void {
  }

  toggleModal() {
    if(this.modalToggled) {
      this.onHide.emit(true);
    }
    this.modalToggled = !this.modalToggled;
    
  }
}
