import { Component, OnInit } from '@angular/core';
import { UserSubscriptionMixin } from 'src/app/mixins/subscriptions.mixin';
import { ClientService } from 'src/app/services/client.service';
import { LoadingService } from 'src/app/services/loading.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-client-colors',
  templateUrl: './client-colors.component.html',
  styleUrls: ['./client-colors.component.scss']
})
export class ClientColorsComponent extends UserSubscriptionMixin implements OnInit {
  colorsData = {
    description: [],
    usage: [],
  }

  constructor(
    userService: UserService,
    navService: NavigationService,
    projectService: ProjectService,
    clientService: ClientService,
    private loadingService: LoadingService,
  ) {
    super(userService, navService, projectService, clientService);
  }

  ngOnInit(): void {
    this.currentProjectLoaded.then(() => {
      this.colorsData.description = this.currentProject.clientPortal?.colors?.description || [];
      this.colorsData.usage = this.currentProject.clientPortal?.colors?.usage || [];
    })
  }

  updateDescriptionModules($event) {
    this.colorsData.description = [...$event]
  }
  updateUsageModules($event) {
    this.colorsData.usage = [...$event]
  }

  updateProject() {
    this.currentProject.clientPortal.colors = this.colorsData

    this.loadingService.startLoading();
    this.projectService.updateProject(this.currentProject.id, this.currentProject)
    .then(() => {
      this.loadingService.stopLoading()
    });
  }
  toKebabCase(str) {
    return str && str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.toLowerCase())
      .join('-');
  }
}
