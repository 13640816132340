import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { from, BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import Project from '../interfaces/Project';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class GuideService {
  subscriptions: Subscription = new Subscription();
  currentProject = new BehaviorSubject(null)
  currentSubmenuLinks = new BehaviorSubject([])

  constructor(
    private afs: AngularFirestore,
  ) { }

  setCurrentProjectById(id) {
    const projectRef: AngularFirestoreCollection<any> = this.afs.collection('projects', ref => ref.where('id', '==', id))
    from(projectRef.valueChanges()).subscribe(res => {
      if (res && res[0]) {
        this.currentProject.next(res[0])
      }
    })
  }
  setCurrentProjectByUrl(url) {
    return new Promise<void>((resolve: any) => {
      const projectRef: AngularFirestoreCollection<any> = this.afs.collection('projects', ref => ref.where('settings.url', '==', url))
      from(projectRef.valueChanges()).subscribe(res => {
        if (res) {
          if (res.length) {
            this.currentProject.next(res[0])
            resolve(res[0].id)
          } else {
            resolve('no project found')
          }
        }
      })
    })
  }

  setCurrentSubmenu(guideSections) {
    if (guideSections && guideSections.length) {
      const subnavs = []
      guideSections.forEach((section, index) => {
        console.log(section);
        const li = document.createElement('li');
        li.innerHTML = `<a data-fragment="${section.id}" class="sub-nav-item cursor-pointer">${section.querySelector('h2').innerText}</a>`
        subnavs.push(li)
      })
      this.currentSubmenuLinks.next(subnavs)
      console.log(this.currentSubmenuLinks)
    } else {
      this.currentSubmenuLinks.next([])
    }
  }

}
