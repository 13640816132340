<div class="typography" *ngIf="currentProject && currentProject.variables">
    <h1 class="mbs">Typography</h1>

    <section class="fonts mbxl">
        <div class="col-12 col-md-6">
            <h3 class="mbs">Fonts</h3>
            <div class="form-control justify-start"
                *ngFor="let font of currentProject.variables.typography.fonts;let index = index; trackBy: preventBlur;">
                <input type="text" [(ngModel)]="currentProject.variables.typography.fonts[index]"
                    (blur)="updateProject()">
                <styles-icon name="close" class="show-hover cursor-pointer" (click)="deleteFont(index)"></styles-icon>
            </div>
            <a class="txt-underline " (click)="addFont()">+ Add Font</a>
        </div>
    </section>

    <section class="weights mbxl row">
        <div class="col-12 col-md-6 col-lg-4">
            <h3 class="mbs">Weights</h3>
            <ng-container *ngFor="let weight of currentProject.variables.typography.weights;let index = index">
                <div class="form-control" *ngIf="!weight.hidden">
                    <label>{{weight.label}}</label>
                    <input type="text" [(ngModel)]="currentProject.variables.typography.weights[index].value"
                        (blur)="updateProject()">
                    <styles-icon name="close" class="show-hover cursor-pointer" (click)="toggleWeight(index)">
                    </styles-icon>
                </div>
            </ng-container>
            <styles-dropdown>
                <ng-container class="dropdown-link">+ Add Weight</ng-container>
                <li *ngFor="let weight of currentProject.variables.typography.weights;let index = index"
                    class="d-flex align-center mbxxs cursor-pointer" (click)="toggleWeight(index)">
                    <div class="checkbox border-a ptxs prxs mrxxs" [ngClass]="{'bg-neutral': !weight.hidden}"></div>
                    <div>{{weight.label}}</div>
                </li>
            </styles-dropdown>
        </div>
    </section>

    <section class="tags mbxl row">
        <div class="col-12">
            <h3 class="mbs">Elements</h3>


            <div class="element-boxes mbm" dragula="ELEMENTS" [(dragulaModel)]="currentProject.variables.typography.elements">
                <div class="element-box-wrap"  *ngFor="let element of currentProject.variables.typography.elements; let index = index">
                    <div class="element-box-header d-flex justify-between" style="cursor: grab">
                        <h5 class="tag-label" *ngIf="element.tagOrClass === 'htmlTag'">{{element.tag}}</h5>
                        <h5 class="class-label" *ngIf="element.tagOrClass === 'htmlClass'">{{element.classLabel}}</h5>
                        <div class="element-actions d-flex align-center">
                            <a (click)="editElement(element, index)" class="d-inline-block mrxxxs"><styles-icon style="height: 18px; width: 18px" name="edit" color="neutralDarker"></styles-icon></a>
                            <a (click)="deleteElement(index)"><styles-icon style="height: 18px; width: 18px" name="close" color="neutralDarker"></styles-icon></a>
                        </div>
                    </div>
                    <div 
                        class="element-box" 
                        contenteditable 
                        (input)="elementBoxTextUpdate($event.target.innerHTML, index)"
                        [ngStyle]="{
                            'font-size': element.fontSize.desktop + 'px',
                            'line-height': element.lineHeight.desktop + 'px',
                            'color': colorSlugs[element.color.desktop],
                            'letter-spacing': element.letterSpacing.desktop + 'px',
                            'font-weight': element.fontWeight.desktop,
                            'text-decoration': element.textDecoration.desktop,
                            'text-transform': element.textTransform.desktop,
                            'max-height': element.lineHeight.desktop * 1.5 + 'px'
                        }"
                    >
                        {{element.testText ? element.testText : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit.' }}
                    </div>
                </div>
            </div>

            <div class="add-element-wrap mbxl">
                <styles-modal #editElementModal (onHide)="changeHide($event)">
                    <ng-container class="open-inner">Add Element</ng-container>
                    <h2 class="mbs">{{editingElement !== null ? 'Edit' : 'Add'}} Element</h2>

                    <!-- Select tag or class -->
                    <h5>Entry type</h5>
                    <div class="entry-type-wrap d-flex align-center mbxs">
                        <div class="radio-wrap d-flex align-center mrs cursor-pointer">
                            <input id="entry-type-css" type="radio" class="mrxxxs" value="css" name="entryType"
                                [(ngModel)]="newElement.entryType" required>
                            <label for="entry-type-css">CSS</label>
                        </div>
                        <div class="radio-wrap d-flex align-center mrs cursor-pointer">
                            <input id="entry-type-form" type="radio" class="mrxxxs" value="form" name="entryType"
                                [(ngModel)]="newElement.entryType" required>
                            <label for="entry-type-form">Form</label>
                        </div>
                    </div>

                    <h5>Element details</h5>
                    <table class="mbxs form-entry">
                        <!-- Element type -->
                        <tr class="element-type">
                            <td>Element type</td>
                            <td>
                                <div class="tag-class-wrap d-flex align-center">
                                    <div class="radio-wrap d-flex align-center mrs cursor-pointer">
                                        <input id="htmlTag" type="radio" class="mrxxxs" value="htmlTag" name="tagOrClass"
                                            [(ngModel)]="newElement.tagOrClass" required>
                                        <label for="htmlTag">Tag</label>
                                    </div>
                                    <div class="radio-wrap d-flex align-center mrs cursor-pointer">
                                        <input id="htmlClass" type="radio" class="mrxxxs" value="htmlClass" name="tagOrClass"
                                            [(ngModel)]="newElement.tagOrClass" required>
                                        <label for="htmlClass">Class</label>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <!-- Select tag -->
                        <tr class="tag" *ngIf="newElement.tagOrClass == 'htmlTag'">
                            <td>Tag</td>
                            <td>
                                <select [(ngModel)]="newElement.tag">
                                    <option *ngFor="let tag of tags" [value]="tag">{{tag}}</option>
                                </select>
                            </td>
                        </tr>
                        <!-- Class name -->
                        <tr class="class-label" *ngIf="newElement.tagOrClass == 'htmlClass'">
                            <td>Class Name</td>
                            <td>
                                <input type="text" name="classLabel" [(ngModel)]="newElement.classLabel"
                                    required>
                            </td>
                        </tr>
                    </table>

                    <h5>Element Styles</h5>
                    <table class="mbxs css-entry" *ngIf="newElement.entryType === 'css'">
                        <tr>
                            <td>Desktop</td>
                            <td>
                                <textarea name="css-desktop" id="css-desktop" rows="10" [(ngModel)]="newElement.css.desktop"></textarea>
                            </td>
                        </tr>
                        <tr>
                            <td>Mobile</td>
                            <td>
                                <textarea name="css-mobile" id="css-mobile" rows="10" [(ngModel)]="newElement.css.mobile"></textarea>
                            </td>
                        </tr>
                    </table>

                    <table class="mbxs form-entry" *ngIf="newElement.entryType === 'form'">
                        <thead>
                            <th></th>
                            <th><small>Desktop</small></th>
                            <th><small>Mobile</small></th>
                        </thead>
                        <!-- Font size -->
                        <tr class="font-size">
                            <td>Font size</td>
                            <td>
                                <input type="number" name="font-size-desktop" id="font-size-desktop" [(ngModel)]="newElement.fontSize.desktop">
                            </td>
                            <td>
                                <input type="number" name="font-size-mobile" id="font-size-mobile" [(ngModel)]="newElement.fontSize.mobile">
                            </td>
                        </tr>
                        <!-- Line height -->
                        <tr class="line-height">
                            <td>Line height</td>
                            <td>
                                <input type="number" name="line-height-desktop" id="line-height-desktop" [(ngModel)]="newElement.lineHeight.desktop">
                            </td>
                            <td>
                                <input type="number" name="line-height-mobile" id="line-height-mobile" [(ngModel)]="newElement.lineHeight.mobile">
                            </td>
                        </tr>
                        <!-- Font family -->
                        <tr class="font-family">
                            <td>Font family</td>
                            <td>
                                <select [(ngModel)]="newElement.fontFamily.desktop">
                                    <option *ngFor="let font of currentProject.variables.typography.fonts" [value]="font">
                                        {{font}}</option>
                                </select>
                            </td>
                            <td>
                                <select [(ngModel)]="newElement.fontFamily.mobile">
                                    <option *ngFor="let font of currentProject.variables.typography.fonts" [value]="font">
                                        {{font}}</option>
                                </select>
                            </td>
                        </tr>
                        <!-- Font color -->
                        <tr class="color">
                            <td>Font color</td>
                            <td>
                                <select [(ngModel)]="newElement.color.desktop"
                                    [ngStyle]="{'border-right' :'10px solid '+ colorSlugs[newElement.color.desktop]}">
                                    {{ colorSlugs[newElement.color.desktop] }}
                                    <option *ngFor="let color of currentProject.variables.colors" [ngValue]="color.slug">
                                        {{color.label}}</option>
                                </select>
                            </td>
                            <td>
                                <select [(ngModel)]="newElement.color.mobile"
                                    [ngStyle]="{'border-right' :'10px solid '+ colorSlugs[newElement.color.mobile]}">
                                    {{ colorSlugs[newElement.color.mobile] }}
                                    <option *ngFor="let color of currentProject.variables.colors" [ngValue]="color.slug">
                                        {{color.label}}</option>
                                </select>
                            </td>
                        </tr>
                        <!-- Font weight -->
                        <tr class="font-weight">
                            <td>Font weight</td>
                            <td>
                                <select [(ngModel)]="newElement.fontWeight.desktop">
                                    <option *ngFor="let weight of filteredWeights" [value]="weight.label">{{weight.label}}
                                    </option>
                                </select>
                            </td>
                            <td>
                                <select [(ngModel)]="newElement.fontWeight.mobile">
                                    <option *ngFor="let weight of filteredWeights" [value]="weight.label">{{weight.label}}
                                    </option>
                                </select>
                            </td>
                        </tr>
                        <!-- Letter spacing -->
                        <tr class="letter-spacing">
                            <td>Letter spacing</td>
                            <td>
                                <input type="number" [(ngModel)]="newElement.letterSpacing.desktop">
                            </td>
                            <td>
                                <input type="number" [(ngModel)]="newElement.letterSpacing.mobile">
                            </td>
                        </tr>
                        <!-- Margin top -->
                        <tr class="margin-top">
                            <td>Margin top</td>
                            <td>
                                <select [(ngModel)]="newElement.marginTop.desktop">
                                    <option [value]="0">0 - unset</option>
                                    <option *ngFor="let size of sizes" [value]="size">{{size + ' - ' +
                                        currentProject.variables.spacing[size.toLowerCase()].desktop + 'px'}}</option>
                                </select>
                            </td>
                            <td>
                                <select [(ngModel)]="newElement.marginTop.mobile">
                                    <option [value]="0">0 - unset</option>
                                    <option *ngFor="let size of sizes" [value]="size">{{size + ' - ' +
                                        currentProject.variables.spacing[size.toLowerCase()].desktop + 'px'}}</option>
                                </select>
                            </td>
                        </tr>
                        <!-- Margin bottom -->
                        <tr class="margin-bottom">
                            <td>Margin bottom</td>
                            <td>
                                <select [(ngModel)]="newElement.marginBottom.desktop">
                                    <option [value]="0">0 - unset</option>
                                    <option *ngFor="let size of sizes" [value]="size">{{size + ' - ' +
                                        currentProject.variables.spacing[size.toLowerCase()].desktop + 'px'}}</option>
                                </select>
                            </td>
                            <td>
                                <select [(ngModel)]="newElement.marginBottom.mobile">
                                    <option [value]="0">0 - unset</option>
                                    <option *ngFor="let size of sizes" [value]="size">{{size + ' - ' +
                                        currentProject.variables.spacing[size.toLowerCase()].desktop + 'px'}}</option>
                                </select>
                            </td>
                        </tr>
                        <!-- Text decoration -->
                        <tr class="text-decoration">
                            <td>Text decoration</td>
                            <td>
                                <select [(ngModel)]="newElement.textDecoration.desktop">
                                    <option value="none">None</option>
                                    <option value="underline">Underline</option>
                                </select>
                            </td>
                            <td>
                                <select [(ngModel)]="newElement.textDecoration.mobile">
                                    <option value="none">None</option>
                                    <option value="underline">Underline</option>
                                </select>
                            </td>
                        </tr>
                        <!-- Text transform -->
                        <tr class="text-transform">
                            <td>Text transform</td>
                            <td>
                                <select [(ngModel)]="newElement.textTransform.desktop">
                                    <option value="none">None</option>
                                    <option value="uppercase">Uppercase</option>
                                    <option value="lowercase">Lowercase</option>
                                    <option value="capitalize">Capitalize</option>
                                </select>
                            </td>
                            <td>
                                <select [(ngModel)]="newElement.textTransform.mobile">
                                    <option value="none">None</option>
                                    <option value="uppercase">Uppercase</option>
                                    <option value="lowercase">Lowercase</option>
                                    <option value="capitalize">Capitalize</option>
                                </select>
                            </td>
                        </tr>
                    </table>
                    <div class="txt-r">
                        <button *ngIf="newElement.entryType === 'css'" class="btn" (click)="convertCss()">Convert</button>
                        <button *ngIf="newElement.entryType === 'form'" class="btn" (click)="saveElement()">Save Element</button>
                    </div>

                </styles-modal>
            </div>


        </div>
    </section>
</div>