import { Component, OnInit, ViewChild } from '@angular/core';
import { UserSubscriptionMixin } from 'src/app/mixins/subscriptions.mixin';
import { ClientService } from 'src/app/services/client.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';
import { DragulaService } from 'ng2-dragula';

const emptyElement = {
  tagOrClass: 'htmlTag',
  tag: null,
  classLabel: null,
  classSlug: null,
  fontSize: {desktop: null,mobile:null},
  lineHeight: {desktop: null,mobile:null},
  fontFamily: {desktop: null,mobile:null},
  color: {desktop: null,mobile:null}, 
  fontWeight: {desktop: null,mobile:null},
  letterSpacing: {desktop: null,mobile:null},
  marginTop: {desktop: null,mobile:null},
  marginBottom: {desktop: null,mobile:null},
  textDecoration: {desktop: null,mobile:null},
  textTransform: {desktop: null,mobile:null},
  testText: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
  entryType: 'css',
  css: {desktop: null,mobile: null}
}

@Component({
  selector: 'app-typography',
  templateUrl: './typography.component.html',
  styleUrls: ['./typography.component.scss']
})
export class TypographyComponent extends UserSubscriptionMixin implements OnInit {
  @ViewChild('editElementModal') modal;
  newElement = {...emptyElement}
  newElementError = '';
  editingElement = null;

  elements = [];
  sizes = ['Base','XXXL','XXL','XL','L','M','S','XS','XXS','XXXS'];
  tags = ['body','h1','h2','h3','h4','h5','h6','small'];
  filteredWeights = [];
  colorSlugs = {};

  constructor(
    userService: UserService,
    navService: NavigationService,
    projectService: ProjectService,
    clientService: ClientService,
    dragulaService: DragulaService
  ) {
    super(userService, navService, projectService,clientService);

    this.subscriptions.add(dragulaService.drop("ELEMENTS")
      .subscribe(({ name, el, target, source, sibling }) => {
        this.updateProject();
      })
    );
  }

  ngOnInit(): void {
    this.currentProjectLoaded.then(() => {
      if (this.currentProject.variables?.typography?.weights.length === 0 ) {
        this.currentProject.variables.typography.weights = [
            {
              hidden: true,
              value: 100,
              label: "lighter"
            },
            {
              hidden: true,
              value: 300,
              label: "light"
            },
            {
              hidden: true,
              value: 500,
              label: "normal"
            },
            {
              hidden: true,
              value: 600,
              label: "bold"
            },
            {
              hidden: true,
              value: 700,
              label: "bolder"
            },
            {
              hidden: true,
              value: 900,
              label: "black"
            }
        ]; 
      }
      if (Object.keys(this.currentProject.variables?.typography?.sizes).length === 0 && Object.keys(this.currentProject.variables?.typography?.heights).length === 0) {
        this.sizes.forEach((element) => {
          this.currentProject.variables.typography.sizes[element.toLowerCase()] = {hidden: false, mobile: "", desktop: ""};
          this.currentProject.variables.typography.heights[element.toLowerCase()] = {hidden: false, mobile: "", desktop: ""};
        });
        this.tags.forEach((element) => {
          this.currentProject.variables.typography.tags[element] = {color: "", font: "", hidden: false, line: "base", size: "base", weight: "normal", letterSpacing: 0, marginTop: 0, marginBottom: 0};
        });
      }
      if (!('elements' in this.currentProject.variables.typography)) this.currentProject.variables.typography.elements = [];
      console.log(this.currentProject);

      this.setFilteredWeights();
      this.setColorSlugs();
    })
  }

  updateProject() {
    this.projectService.updateProject(this.currentProject.id, this.currentProject).then(res => {
      console.log(res);
    });
  }

  // Fonts
  addFont() {
    this.currentProject.variables.typography.fonts.push('');
  }

  deleteFont(index) {
    this.currentProject.variables.typography.fonts.splice(index, 1);
    this.updateProject();
  }

  // Weights
  toggleWeight(i) {
    this.currentProject.variables.typography.weights[i].hidden = !this.currentProject.variables.typography.weights[i].hidden
    this.setFilteredWeights();
    this.updateProject();
  }

  setFilteredWeights() {
    this.filteredWeights = this.currentProject.variables.typography.weights.filter((w) => !w.hidden);
  }

  setColorSlugs() {
    for (let color of this.currentProject.variables.colors) {
      this.colorSlugs[color.slug] = color.hex;
    }
  }
  
  preventBlur(index) {
    return index;
  }

  changeHide(val: boolean) {
    //alert();
    console.log(this.editingElement);
    this.editingElement = null;
    this.newElement = { ...emptyElement }
  }

  saveElement() {
    let { currentProject, currentProjectId, projectService, modal, newElement, editingElement } = this;
    if (newElement.classLabel) newElement.classSlug = this.convertToKababCase(newElement.classLabel);

    if (!('elements' in this.currentProject.variables.typography)) this.currentProject.variables.typography.elements = [];

    if (editingElement !== null) {
      currentProject.variables.typography.elements[editingElement] = {...newElement};
    } else {
      currentProject.variables.typography.elements.push(newElement)
    }

    projectService.updateProject(currentProjectId, currentProject).then(() => {
      if (editingElement !== null) editingElement = null;
      newElement = { ...emptyElement }
      if (this.modal.modalToggled) modal.toggleModal();

    })
  }

  elementBoxTextUpdate(text, index) {
    let { currentProject, currentProjectId, projectService } = this;
    currentProject.variables.typography.elements[index].testText = text;
    projectService.updateProject(currentProjectId, currentProject)
  }

  editElement(color, index) {
    this.editingElement = index;
    this.newElement = {...color};
    this.newElement.entryType = 'form';
    this.modal.toggleModal();
  }

  deleteElement(index) {
    this.currentProject.variables.typography.elements.splice(index, 1)
    this.projectService.updateProject(this.currentProjectId, this.currentProject)
  }

  convertCss() {
    [
      this.newElement.css.desktop,
      this.newElement.css.mobile
    ].forEach((styleStr, index) => {
      if (styleStr) {
        const rows = styleStr.split('\n');
        rows.forEach(row => {
          const key = this.convertToCamelCase(row.split(': ')[0]);
          let value = row.split(': ')[1];
          if (key && value) {
            value = value.endsWith(';') ? value.substring(0, value.length - 1) : value;
  
            if (key in this.newElement) {
              switch (key) {
                case 'fontSize':
                case 'lineHeight':
                case 'letterSpacing':
                  value = parseInt(value);
                  break;
                case 'color':
                  let newColor = null;
                  for (const colorSlug in this.colorSlugs) {
                    if (this.colorSlugs[colorSlug] == value) {
                      newColor = colorSlug
                    } 
                  }
                  value = newColor;
                  break;
                case 'marginTop':
                case 'marginBottom':
                  let newMargin = null;
                  const spacing = this.currentProject.variables.spacing
                  for (const size in spacing) {
                    if (spacing[size][index == 0 ? 'desktop' : 'mobile'] === parseInt(value)) {
                      newMargin = size.toUpperCase();
                    }
                  }
                  value = newMargin;
                  break;
                case 'fontWeight':
                  let newWeight = null;
                  if (parseInt(value) === NaN) {
                    newWeight = value;
                  } else {
                    for (const weight in this.filteredWeights) {
                      if (parseInt(this.filteredWeights[weight].value) === parseInt(value)) {
                        newWeight = this.filteredWeights[weight].label
                      }
                    }
                  }
                  value = newWeight;
                  break;
              }

              this.newElement.entryType = 'form';
              this.newElement[key][index == 0 ? 'desktop' : 'mobile'] = value
            }
          }

        })
      }
    })
    // this.newElement.entryType = 'form';
    console.log(this.newElement);
  }

  convertToKababCase(str) {
    return str && str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g).map(x => x.toLowerCase()).join('-');
  }
  convertToCamelCase(str) {
    return str.replace(/-./g, x=>x[1].toUpperCase())
  }
}
/*
font-family: Lato;
font-style: normal;
font-weight: 600;
font-size: 80px;
line-height: 94px;
color: #212121;
margin-top: 24px;
margin-bottom: 16px;
text-decoration: none;
text-transform: none


{
  "tagOrClass":"htmlTag",
  "tag":null,
  "classLabel":null,
  "classSlug":null,
  "fontSize":{ "desktop":80,"mobile":null},
  "lineHeight":{ "desktop":94,"mobile":null},
  "fontFamily":{ "desktop":"Lato","mobile":null},
  "color":{ "desktop":"midnight","mobile":null},
  "fontWeight":{ "desktop":"normal","mobile":null},
  "letterSpacing":{ "desktop":0,"mobile":null},
  "marginTop":{ "desktop":"L","mobile":null},
  "marginBottom":{ "desktop":"S","mobile":null},
  "textDecoration":{ "desktop":"none","mobile":null},
  "textTransform":{ "desktop":"none","mobile":null},
  "testText":"Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
  "entryType":"form",
  "css":{"desktop":"font-family: Lato;\\nfont-weight: 400;\\nfont-size: 80px;\\nline-height: 94px;\\ncolor: #202344;\\nmargin-top: 24px;\\nmargin-bottom: 16px;\\ntext-decoration: none;\\ntext-transform: none\\nletter-spacing: 0px;","mobile":null}

}

*/