import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { PageScrollService } from 'ngx-page-scroll-core';
import ProjectSettings from 'src/app/interfaces/project-settings';
import { UserSubscriptionMixin } from 'src/app/mixins/subscriptions.mixin';
import { ClientModel } from 'src/app/models/client.model';
import { ClientService } from 'src/app/services/client.service';
import { LoadingService } from 'src/app/services/loading.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-harmonica',
  templateUrl: './harmonica.component.html',
  styleUrls: ['./harmonica.component.scss']
})
export class HarmonicaComponent extends UserSubscriptionMixin implements OnInit {
  @ViewChild('currentProjectClientDropdownBtn') currentProjectClientDropdownBtn;
  currentFilter = null;
  filteredClients = [];
  clientSearch = {
    newProject: '',
    currentProject: '',
    editClients: ''
  }
  gradientBackgrounds = {};
  sanitizedSvg;

  colorSlugs = {};
  designModules = ['colors', 'typography', 'spacing', 'buttons', 'links', 'icons', 'logos'];
  devModules = ['overview', 'codeBlocks', 'components', 'stylesheet'];

  constructor(
    userService: UserService,
    navService: NavigationService,
    projectService: ProjectService,
    clientService: ClientService,
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private pageScrollService: PageScrollService,
    private sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document: any
  ) {
    super(userService, navService, projectService, clientService);
  }

  ngOnInit(): void {

    this.currentProjectLoaded.then(() => {

      // init url and isLive
      if (!this.currentProject.settings) {
        this.currentProject.settings = {
          url: '',
          isLive: false
        } as ProjectSettings;
      } else {
        if (!('url' in this.currentProject.settings)) this.currentProject.settings.url = '';
        if (!('isLive' in this.currentProject.settings)) this.currentProject.settings.isLive = false;
      }

      // init thumbnail data
      if (!this.currentProject.settings.thumbnail) {
        this.currentProject.settings.thumbnail = [{}, {}, {}];
        this.projectService.updateProject(this.currentProject.id, this.currentProject);
      }

      // init project colors
      if (!this.currentProject.settings.colors.headerBG || !this.currentProject.settings.colors.headerText) {
        this.currentProject.settings.colors.headerBG = 'neutral-darker';
        this.currentProject.settings.colors.headerText = 'neutral-darker';
        if (this.currentProject.settings.colors.header) {
          delete this.currentProject.settings.colors.header;
          this.projectService.updateProject(this.currentProject.id, this.currentProject);
        }
      }

      // color slugs
      this.setColorSlugs();

      // init thumbnail icon data
      if (!this.currentProject.settings.icon) {
        this.currentProject.settings.icon = false;
        this.updateProject();
      }
      if (!this.currentProject.settings.iconBg) {
        this.currentProject.settings.iconBg = this.currentProject.variables.colors[0];
        this.updateProject();
      }

      // render project thumbnail
      if (this.currentProject.settings.icon && this.currentProject.settings.iconSvg) {
        this.sanitizedSvg = this.sanitizer.bypassSecurityTrustHtml(this.currentProject.settings.iconSvg);
      } else {
        this.gradientBackgrounds[this.currentProject.id] = "grey";

        if (this.currentProject.settings.thumbnail && this.currentProject.settings.thumbnail.some(c => c.slug)) {
          this.gradientBackgrounds[this.currentProject.id] = this.generateGradient(this.currentProject.settings.thumbnail, this.currentProject.name);
        } else if (this.currentProject.variables.colors) {
          this.gradientBackgrounds[this.currentProject.id] = this.generateGradient(this.currentProject.variables.colors, this.currentProject.name);
        }
      }

      return this.clientsLoaded;
    }).then(() => {
      this.initClients();
    })
  }

  updateProject() {
    this.loadingService.startLoading();
    this.projectService.updateProject(this.currentProject.id, this.currentProject).then(res => {
      this.loadingService.stopLoading();
      console.log(res);
    });
  }

  /************************** 
  * Settings
  * ************************/
  toggleModule(m, type) {
    this.currentProject.settings.modules[type][m] = !this.currentProject.settings.modules[type][m];
  }

  setThumbnailColor(slug, i) {
    this.currentProject.settings.thumbnail[i] = this.currentProject.variables.colors.filter(c => c.slug === slug)[0];
    this.gradientBackgrounds[this.currentProject.id] = this.generateGradient(this.currentProject.settings.thumbnail, this.currentProject.name);
  }

  setIconColor(slug) {
    this.currentProject.settings.iconBg = this.currentProject.variables.colors.filter(c => c.slug === slug)[0];
  }

  renderThumbnailSVG() {
    console.log(this.currentProject.settings.iconSvg)
    this.sanitizedSvg = this.sanitizer.bypassSecurityTrustHtml(this.currentProject.settings.iconSvg);
  }

  /************************** 
   * Clients
   * ************************/
  initClients() {
    this.filteredClients = this.clients.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      return 0;
    });
    let currentClient = this.clients.filter(c => {
      return c.id === this.currentProject.client;
    })[0]
    if (currentClient) this.clientSearch.currentProject = currentClient.name;
  }

  onClientSearch(location) {
    if (this.clientSearch[location].length > 0) {
      let a = this[location + 'ClientDropdownBtn'].nativeElement.parentNode;
      if (a.nextElementSibling.classList.contains('hide-fully')) a.click();
    }
    this.filteredClients = this.clients.filter(c => {
      return c.name.toLowerCase().includes(this.clientSearch[location].toLowerCase());
    })
  }

  selectClient(location, data) {
    this.clientSearch[location] = data.name;
    this[location].client = data.id;
  }

  createClient(location) {
    this.loadingService.startLoading();
    let newClient = { ...ClientModel };
    newClient.name = this.clientSearch[location];
    newClient.owner = this.currentUser.id;
    this.clientService.createClient(newClient).then(id => {
      if (location !== 'editClients') this[location].client = id;
      if (location == 'editClients') this.clientSearch[location] = '';
      this.loadingService.stopLoading();
    })
  }

  removeClient(location) {
    this[location].client = null;
    this.clientSearch[location] = '';
  }

  deleteClient(i) {
    this.loadingService.startLoading();
    this.clientService.deleteClient(this.clients[i].id).then(() => {
      this.loadingService.stopLoading();
    })
  }
  updateClient(i) {
    this.loadingService.startLoading();
    this.clientService.updateClient(this.clients[i].id, this.clients[i]).then(() => {
      this.loadingService.stopLoading();
    })
  }

  setFilter(clientID) {
    if (!clientID || this.currentFilter === clientID) {
      if (this.router.url.includes('code-blocks')) this.router.navigateByUrl('/');
      this.currentFilter = null;
      this.projectService.filterByClient(null);
    } else if (clientID === 'codeBlocks') {
      this.currentFilter = 'codeBlocks';
      this.router.navigateByUrl('/code-blocks')
    } else {
      if (this.router.url.includes('code-blocks')) this.router.navigateByUrl('/');
      this.currentFilter = clientID;
      this.projectService.filterByClient(clientID)
    }
  }

  /**************************
  * Utilities
  * ************************/
  convertToNormalCase(str) {
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); })
  }
  toKebabCase(str) {
    return str && str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.toLowerCase())
      .join('-');
  }

  setColorSlugs() {
    for (let color of this.currentProject.variables.colors) {
      this.colorSlugs[color.slug] = color.hex;
    }
  }

  generateGradient(colors, name) {
    let backgroundString = "grey";

    colors = colors.filter(c => !!c.slug && !!c.hex && !!c.label);

    if (colors.length === 1) {
      backgroundString = colors[0].hex;
    } else if (colors.length === 2) {
      let nameint = this.sumChars(name.replace(/\W/g, '').toLowerCase());
      let degree = nameint % 360;
      backgroundString = `linear-gradient(${degree}deg, ${colors[0].hex} calc(50% - 1px), rgba(0, 0, 0, 0) 50%), linear-gradient(${degree}deg, #fff calc(50% - 2px), ${colors[1].hex} calc(50% - 1px))`;
    } else if (colors.length > 2) {
      let nameint = this.sumChars(name.replace(/\W/g, '').toLowerCase());
      let degree = nameint % 360;
      let degree2 = (nameint / 4.1) % 360;
      backgroundString = `linear-gradient(${degree}deg, ${colors[0].hex} calc(33% - 1px), rgba(0, 0, 0, 0) 33%), linear-gradient(${degree2}deg, ${colors[1].hex} calc(66% - 2px), ${colors[2].hex} calc(66% - 1px))`;
    }

    return backgroundString;
  }

  sumChars(s) {
    var i, n = s.length, acc = 0;
    for (i = 0; i < n; i++) {
      acc += parseInt(s[i], 36) - 9;
    }
    return acc;
  }
}
