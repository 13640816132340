<div class="guide-dashboard container" *ngIf="currentProject.clientPortal && currentProject.clientPortal.dashboard">
  <div class="row mbl">
    <div class="col-12 pbxxs">
      <h1>{{currentProject.clientPortal.dashboard.heading}}</h1>
    </div>
  </div>
  <div class="row cards">
    <div class="col-12 col-lg-6 card-wrap mbm" *ngIf="currentProject.clientPortal.dashboard.pages.wordpressTips">
      <a class="card" [routerLink]="[navBase + 'wordpress-tips']">
        <div class="card-info">
          <h4>WordPress Tips</h4>
          <p>Pointers with accompanying images to help you use the page builder.</p>
        </div>
        <div class="link">
          Learn More
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 8.99973H12.17L7.29 13.8797C6.9 14.2697 6.9 14.9097 7.29 15.2997C7.68 15.6897 8.31 15.6897 8.7 15.2997L15.29 8.70973C15.68 8.31973 15.68 7.68973 15.29 7.29973L8.71 0.699727C8.32 0.309727 7.69 0.309727 7.3 0.699727C6.91 1.08973 6.91 1.71973 7.3 2.10973L12.17 6.99973H1C0.45 6.99973 0 7.44973 0 7.99973C0 8.54973 0.45 8.99973 1 8.99973Z" fill="#F98B64"/>
          </svg>            
        </div>
      </a>
    </div>
    <div class="col-12 col-lg-6 card-wrap mbm" *ngIf="currentProject.clientPortal.dashboard.pages.brandResources">
      <a class="card" [routerLink]="[navBase + 'brand-resources']">
        <div class="card-info">
          <h4>Brand Resources</h4>
          <p>Brand guidelines, logo files, colors, icons and graphics.</p>
        </div>
        <div class="link">
          Learn More
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 8.99973H12.17L7.29 13.8797C6.9 14.2697 6.9 14.9097 7.29 15.2997C7.68 15.6897 8.31 15.6897 8.7 15.2997L15.29 8.70973C15.68 8.31973 15.68 7.68973 15.29 7.29973L8.71 0.699727C8.32 0.309727 7.69 0.309727 7.3 0.699727C6.91 1.08973 6.91 1.71973 7.3 2.10973L12.17 6.99973H1C0.45 6.99973 0 7.44973 0 7.99973C0 8.54973 0.45 8.99973 1 8.99973Z" fill="#F98B64"/>
          </svg>            
        </div>
      </a>
    </div>
    <div class="col-12 col-lg-6 card-wrap mbm" *ngIf="currentProject.clientPortal.dashboard.pages.links">
      <a class="card" [routerLink]="[navBase + 'guide-links']">
        <div class="card-info">
          <h4>Links</h4>
          <p>Direct links to shared google drive folders with brand assets, collateral, and more.</p>
        </div>
        <div class="link">
          Learn More
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 8.99973H12.17L7.29 13.8797C6.9 14.2697 6.9 14.9097 7.29 15.2997C7.68 15.6897 8.31 15.6897 8.7 15.2997L15.29 8.70973C15.68 8.31973 15.68 7.68973 15.29 7.29973L8.71 0.699727C8.32 0.309727 7.69 0.309727 7.3 0.699727C6.91 1.08973 6.91 1.71973 7.3 2.10973L12.17 6.99973H1C0.45 6.99973 0 7.44973 0 7.99973C0 8.54973 0.45 8.99973 1 8.99973Z" fill="#F98B64"/>
          </svg>            
        </div>
      </a>
    </div>
  </div>
</div>
