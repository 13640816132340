import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { UserSubscriptionMixin } from 'src/app/mixins/subscriptions.mixin';
import { ClientService } from 'src/app/services/client.service';
import { LoadingService } from 'src/app/services/loading.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-client-guidelines',
  templateUrl: './client-guidelines.component.html',
  styleUrls: ['./client-guidelines.component.scss']
})
export class ClientGuidelinesComponent extends UserSubscriptionMixin implements OnInit {
  sectionDescription = 'Sed aliquet vel odio mattis consectetur morbi neque ullamcorper lorem. Lorem egestas eget augue gravida sed ullamcorper sagittis. Sit diam porta cursus sit nam sit tortor libero nulla. In quis vel felis ornare commodo, quisque suscipit laoreet.'
  links = []
  emptyLink = {
    label: '',
    type: 'link',
    url: '',
    value: null
  }
  
  guidelinesData = {
    description: [],
    links: []
  }

  constructor(
    userService: UserService,
    navService: NavigationService,
    projectService: ProjectService,
    clientService: ClientService,
    private loadingService: LoadingService,
    private storage: AngularFireStorage,
  ) {
    super(userService, navService, projectService, clientService);
  }

  ngOnInit(): void {
    this.currentProjectLoaded.then(() => {
      console.log(this.currentProject);
      this.guidelinesData.description = this.currentProject.clientPortal?.guidelines?.description;
      this.guidelinesData.links = this.currentProject.clientPortal?.guidelines?.links;
    })
    this.guidelinesData.links.push({ ...this.emptyLink });
  }

  addLink() {
    this.guidelinesData.links.push({ ...this.emptyLink });
  }
  updateLinkFile(event, i) {
   this.guidelinesData.links[i].value = event
    this.updateProject();
  }
  removeLinkFile(event, i) {
   this.guidelinesData.links[i].value = ''
    this.updateProject();
  }
  updateModules($event) {
    this.guidelinesData.description = [...$event]
    this.updateProject();
  }

  updateProject() {
    this.currentProject.clientPortal.guidelines = this.guidelinesData

    this.loadingService.startLoading();
    this.projectService.updateProject(this.currentProject.id, this.currentProject)
    .then(() => {
      this.loadingService.stopLoading()
    });
  }
  toKebabCase(str) {
    return str && str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.toLowerCase())
      .join('-');
  }
}
