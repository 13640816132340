import { Component, Input, OnInit, ɵallowSanitizationBypassAndThrow } from '@angular/core';
import { Sanitizer } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'styles-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
  @Input() name: string;
  @Input() color = 'neutralDarker';
  @Input() height = 24;
  @Input() width = 24;
  iconHTML = null;
  viewBox = `0, 0, ${this.height}, ${this.height}`;
  colors = {
    neutralDarker: '#000000',
    neutral: '#c4c4c4',
    neutralBright: '#F3F2F2',
    neutralBrighter: '#ffffff',
    systemError: '#e61000',
    systemInfo: '#4caf50',
  }

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.color = this.color.includes('#') ? this.color : this.colors[this.color];
    if(this.name === "nifticLogo") {
      this.viewBox = '0, 0, 195, 195';
    }
    this.iconHTML = this.getInnerHTMLValue(this[this.name]())
  }
  
  getInnerHTMLValue(value){
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  nifticLogo() {
    return `
      <path fill-rule="evenodd" clip-rule="evenodd" d="M97.5 13C50.8319 13 13 50.8319 13 97.5C13 144.168 50.8319 182 97.5 182C144.168 182 182 144.168 182 97.5C182 50.8319 144.168 13 97.5 13ZM0 97.5C0 43.6522 43.6522 0 97.5 0C151.348 0 195 43.6522 195 97.5C195 151.348 151.348 195 97.5 195C43.6522 195 0 151.348 0 97.5Z" fill="${this.color}" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M129.3 43C132.89 43 135.8 45.9101 135.8 49.5V140.2C135.8 152.624 118.987 156.621 113.548 145.33C113.545 145.324 113.543 145.318 113.54 145.313L72.2998 60.4735V145.5C72.2998 149.09 69.3897 152 65.7998 152C62.21 152 59.2998 149.09 59.2998 145.5V54.9C59.2998 42.789 76.0216 38.2875 81.5517 49.7706C81.5544 49.7762 81.557 49.7817 81.5597 49.7872L122.8 134.627V49.5C122.8 45.9101 125.71 43 129.3 43Z" fill="${this.color}" />
    `;
  }
  home() {
    return `
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" fill="${this.color}"/>`;
  }
  
  search() {
    return `
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" fill="${this.color}"/>`
  }

  close() {
    return `
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" fill="${this.color}"/>
    `
  }

  edit() {
    return `
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"  fill="${this.color}" />
    `
  }

  caretDown() {
    return `
    <path d="M0 0h24v24H0z" fill="none"/><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" fill="${this.color}" />
    `
  }

  menu() {
    return `
    <g><rect fill="none" height="${this.height}" width="${this.width}"/></g><g><g><g><path fill="${this.color}" d="M20,9H4v2h16V9z M4,15h16v-2H4V15z"/></g></g></g>
    `
  }

  add() {
    return `
    <path d="M0 0h24v24H0z" fill="none"/><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" fill="${this.color}" />
    `
  }

  subtract() {
    return `
    <path d="M0 0h24v24H0z" fill="none"/><path d="M19 13H5v-2h14v2z" fill="${this.color}" />
    `
  }

  copy() {
    return `
    <path d="M0 0h24v24H0z" fill="none"/><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" fill="${this.color}" />
    `
  }

  check() {
    return  `
      <path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" fill="${this.color}" />
    `
  }

  file() {
    return `
      <path d="M0 0h24v24H0z" fill="none"/>
      <path fill="${this.color}" d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"/>
    `
  }

  arrowRight() {
    return `
      <path d="M1 8.99973H12.17L7.29 13.8797C6.9 14.2697 6.9 14.9097 7.29 15.2997C7.68 15.6897 8.31 15.6897 8.7 15.2997L15.29 8.70973C15.68 8.31973 15.68 7.68973 15.29 7.29973L8.71 0.699727C8.32 0.309727 7.69 0.309727 7.3 0.699727C6.91 1.08973 6.91 1.71973 7.3 2.10973L12.17 6.99973H1C0.45 6.99973 0 7.44973 0 7.99973C0 8.54973 0.45 8.99973 1 8.99973Z" fill="${this.color}"/>    
    `
  }

  download() {
    return `
    <path d="M1 15L1.621 17.485C1.72915 17.9177 1.97882 18.3018 2.33033 18.5763C2.68184 18.8508 3.11501 18.9999 3.561 19H18.439C18.885 18.9999 19.3182 18.8508 19.6697 18.5763C20.0212 18.3018 20.2708 17.9177 20.379 17.485L21 15M11 13V1V13ZM11 13L7 9L11 13ZM11 13L15 9L11 13Z" stroke="#F98B64" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>    
    `
  }

}
