<div class="col-12" *ngIf="currentProject && currentProject.clientPortal">
  <div class="page-heading d-flex align-center justify-between mbxs">
    <h2 class="mrm">Fonts</h2>
    <a class="btn" (click)="updateProject()">Save changes</a>
  </div>

  <div class="section-description mbxl">
    <h5 class="mbxs">Section description</h5>
    <styles-builder [modules]="fontsData.description" (updateModules)="updateModules($event)" [currentProjectId]="currentProject.id"></styles-builder>
  </div>

  <div class="typefaces mbxl">
    <h4 class="mbs">Typefaces</h4>
    <div class="typeface mbl" *ngFor="let typeface of fontsData.typefaces; let i = index">
      <h5 class="mbxxs">{{typeface.label ? typeface.label : 'Typeface Name'}}</h5>
      <div class="form-control">
        <label [for]="'typeface-label-' + i">Label</label>
        <input type="text" class="full-width" [name]="'typeface-label-' + i" [(ngModel)]="typeface.label">
      </div>
      <div class="form-control">
        <label [for]="'typeface-descriptiion-' + i">Description</label>
        <textarea class="full-width" [name]="'typeface-description-' + i" [(ngModel)]="typeface.description" row="6"></textarea>
      </div>
      <div class="form-control">
        <label [for]="'typeface-file-' + i">File (.zip)</label>
        <div class="full-width">
          <styles-file-upload 
            [url]="typeface.file" 
            fileType=".zip,.rar,.7zip" 
            [currentProjectId]="currentProject.id"
            (updateUrl)="updateTypefaceFile($event, i)"
            (removeUrl)="removeTypefaceFile($event, i)"
            class="full-width d-flex align-center mtxxxs"
          ></styles-file-upload>
        </div>
      </div>
    </div>
    <div class="add-link-wrap mtxs">
      <a class="txt-underline cursor-pointer d-inline-flex align-center" (click)="addTypeface()">
        <styles-icon name="add" class="mrxxxs"></styles-icon>
        Add Typeface
      </a>
    </div>
  </div>

</div>