<div class="colors" *ngIf="currentProject && currentProject?.variables?.colors">
  <h5 class="txt-uppercase">Colors</h5>
  <p class="mbs"><small>Click on the swatch (scss), slug or hex to copy to the clipboard.</small></p>
  <div class="colors-list row" *ngIf="currentProject && currentProject.variables.colors">
    <div class="col-12 col-md-3 col-lg-2 mbs" *ngFor="let color of currentProject?.variables.colors; let i = index">
      <div class="color txt-c">
        <div class="color-swatch cursor-pointer pvm pos-relative display-inline" [ngStyle]="{'background-color': color.hex}" 
        (click)="copyStr('color(\''+color.slug+'\')')">
        </div>
        <div class="color-meta d-flex flex-col">
          <small class="cursor-pointer" (click)="copyStr(color.slug)"><b>{{color.slug}}</b></small>
          <small class="cursor-pointer" (click)="copyStr(color.hex)">{{color.hex}}</small>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="icons" *ngIf="icons && icons.length">
  <h5 class="txt-uppercase">Icons</h5>
  <p class="mbs"><small>Click on an icon to copy the element to the clipboard (e.g. <span class="code">{{"&lt;span class=\'icon-edit\'&gt;&lt;/span&gt;"}}</span>) </small></p>
  <div class="icons-list row">
    <div class="col-3 col-md-2 col-lg-1" *ngFor="let icon of icons">
      <div class="ico-wrap d-flex align-center mbxs">
        <span [class]="'cursor-pointer icon ' + icon" (click)='copyStr("<span class=\"icon " + icon + "\"></span>")'></span>
      </div>
    </div>
  </div>
</div>
<div class="logos" *ngIf="logos && logos.length">
  <div class="logo-styles" [innerHTML]="logoStyles"></div>
  <h5 class="txt-uppercase">Logos</h5>
  <p class="mbs"><small>Click on a logo to copy the element to the clipboard (e.g. <span class="code">
    {{"&lt;span aria-label=\'ClientName ProjectName primary logo dark\' role=\'img\' class=\'logo-primary-dark-svg\'&gt;&lt;/span&gt;"}}
  </span>) </small></p>
  <table class="logos-list full-width">
    <thead>
      <tr>
        <th></th>
        <th colspan="2">Dark</th>
        <th colspan="2">Light</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td></td>
        <td>SVG</td>
        <td>PNG</td>
        <td>SVG</td>
        <td>PNG</td>
      </tr>
      <tr *ngFor="let logo of logos">
        <ng-container *ngIf="logo.slug as logoSlug">
          <td>{{logo.name}}</td>
          <td>
            <div *ngIf="'lightBG' in logo.types && logo.types.lightBG.svg"
              [class]="'cursor-pointer logo-' + logoSlug + '-dark-svg'"
              (click)='copyLogo(logoSlug, "dark", "svg")'
            ></div>
          </td>
          <td>
            <div *ngIf="'lightBG' in logo.types && logo.types.lightBG.png" 
              [class]="'cursor-pointer logo-' + logoSlug + '-dark-png'"
              (click)='copyLogo(logoSlug, "dark", "png")'
            ></div>
          </td>
          <td>
            <div *ngIf="'darkBG' in logo.types && logo.types.darkBG.svg" 
              [class]="'cursor-pointer logo-' + logoSlug + '-light-svg'"
              (click)='copyLogo(logoSlug, "light", "svg")'
            ></div>
          </td>
          <td>
            <div *ngIf="'darkBG' in logo.types && logo.types.darkBG.png" 
              [class]="'cursor-pointer logo-' + logoSlug + '-light-png'"
              (click)='copyLogo(logoSlug, "light", "png")'
            ></div>
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>
</div>
<div class="tables d-flex flex-wrap">
  <h5 class="txt-uppercase mbxs full-width mtxl">Variable Utilities</h5>
  <div class="type-sizes mbl mrxl" *ngIf="currentProject && currentProject?.variables?.typography">
    <table>
      <thead>
        <tr>
          <th class=" txt-l" colspan="3">Spacing</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td></td>
          <td class="font-normal type-xxs">desktop</td>
          <td class="font-normal type-xxs">mobile</td>
        </tr>
        <tr *ngFor="let size of sizes; let index = index">
          <td class="code">{{size.toLowerCase()}}</td>
          <td class="txt-c font-mono">{{currentProject.variables.spacing[size.toLowerCase()].desktop}}</td>
          <td class="txt-c font-mono">{{currentProject.variables.spacing[size.toLowerCase()].mobile}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="weights mbl mrxl" *ngIf="currentProject && weights.length !== 0">
    <table>
      <thead>
        <tr>
          <th class="">Font Weights</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let weight of weights; let index = index">
          <td class="code">.font-{{ weight.label }}</td>
          <td class="txt-c font-mono plm">{{ weight.value }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="tags mbl mrxl" *ngIf="currentProject && currentProject?.variables?.typography">
    <table>
      <thead>
        <tr>
          <th class=" txt-l" colspan="3">Tags</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td></td>
          <td class="font-normal type-xxs line-xxs txt-c">font</td>
          <td class="font-normal type-xxs line-xxs txt-c">size</td>
          <td class="font-normal type-xxs line-xxs txt-c">line</td>
          <td class="font-normal type-xxs line-xxs txt-c">weight</td>
          <td class="font-normal type-xxs line-xxs txt-c">color</td>
          <td class="font-normal type-xxs line-xxs txt-c">letter<br>spacing</td>
          <td class="font-normal type-xxs line-xxs txt-c">margin<br>top</td>
          <td class="font-normal type-xxs line-xxs txt-c">margin<br>bottom</td>
          <td class="font-normal type-xxs line-xxs txt-c">text<br>decoration</td>
          <td class="font-normal type-xxs line-xxs txt-c">text<br>transform</td>
        </tr>
        <tr *ngFor="let element of currentProject.variables.typography.elements; let index = index">
          <td class="code">{{
            element.tagOrClass === 'htmlTag' ?
              element.tag :
              element.classSlug.startsWith('.') ? element.classSlug : '.' + element.classSlug  
          }}</td>
          <td class="txt-c font-mono phs">{{element.fontFamily.desktop || ''}}</td>
          <td class="txt-c font-mono phs">{{element.fontSize.desktop || ''}}</td>
          <td class="txt-c font-mono phs">{{element.lineHeight.desktop || ''}}</td>
          <td class="txt-c font-mono phs">{{element.fontWeight.desktop}}</td>
          <td class="txt-c font-mono phs">{{element.color.desktop || ''}}</td>
          <td class="txt-c font-mono phs">{{element.letterSpacing.desktop || ''}}</td>
          <td class="txt-c font-mono phs">{{
            (element.marginTop.desktop &&
            element.marginTop.desktop !== '0' &&
            currentProject.variables.spacing[
              element.marginTop.desktop.toLowerCase()
            ].desktop) || ''
          }}</td>
          <td class="txt-c font-mono phs">{{
            (element.marginBottom.desktop &&
            element.marginBottom.desktop !== '0' &&
            currentProject.variables.spacing[
              element.marginBottom.desktop.toLowerCase()
            ].desktop) || ''
          }}</td>
          <td class="txt-c font-mono phs">{{element.textDecoration.desktop || ''}}</td>
          <td class="txt-c font-mono phs">{{element.textTransform.desktop || ''}}</td>
        </tr>
        <!-- <tr *ngFor="let tag of tags; let index = index">
          <td class="code">{{tag}}</td>
          <td class="txt-c font-mono phs">{{currentProject.variables.typography.tags[tag].font}}</td>
          <td class="txt-c font-mono phs">{{currentProject.variables.typography.sizes[currentProject.variables.typography.tags[tag].size.toLowerCase()].desktop / 10}}</td>
          <td class="txt-c font-mono phs">{{currentProject.variables.typography.sizes[currentProject.variables.typography.tags[tag].line.toLowerCase()].desktop / 10}}</td>
          <td class="txt-c font-mono phs">{{currentProject.variables.typography.tags[tag].weight}}</td>
          <td class="txt-c font-mono phs">{{currentProject.variables.typography.tags[tag].color || 'default'}}</td>
          <td class="txt-c font-mono phs">{{currentProject.variables.typography.tags[tag].letterSpacing || '0'}}</td>
          <td class="txt-c font-mono phs">{{
            (currentProject.variables.typography.tags[tag].marginTop &&
            currentProject.variables.typography.tags[tag].marginTop !== '0' &&
            currentProject.variables.spacing[
              currentProject.variables.typography.tags[tag].marginTop.toLowerCase()
            ].desktop) || '0'
          }}</td>
          <td class="txt-c font-mono phs">{{
            (currentProject.variables.typography.tags[tag].marginBottom &&
            currentProject.variables.typography.tags[tag].marginBottom !== '0' &&
            currentProject.variables.spacing[
              currentProject.variables.typography.tags[tag].marginBottom.toLowerCase()
            ].desktop) || '0'
          }}</td>
        </tr> -->
      </tbody>
    </table>
  </div>
</div>
<div class="tables d-flex flex-wrap">
  <h5 class="txt-uppercase mbxs full-width mtxl">Standard Utilities</h5>
  <div class="display mbl mrxl" style="max-width: 200px">
    <table>
      <tbody>
        <tr><td colspan="2"><small style="white-space: break-spaces;" class="font-italic d-block mbxxs">All standard utilties have breakpoint prefixes. xl is set to min-width and the rest max-width.</small></td></tr>
        <tr><td class="code">xs</td><td class="plxs font-mono">575px</td></tr>
        <tr><td class="code">sm</td><td class="plxs font-mono">767px</td></tr>
        <tr><td class="code">md</td><td class="plxs font-mono">991px</td></tr>
        <tr><td class="code">lg</td><td class="plxs font-mono">1199px</td></tr>
        <tr><td class="code">xl</td><td class="plxs font-mono">1200px</td></tr>
      </tbody>
    </table>
  </div>
  <div class="display mbl mrxl">
    <table>
      <thead><tr><th class="">Display</th></tr></thead>
      <tbody>
        <tr><td class="code">.d-none</td><td class="plxs font-mono">display: none </td></tr>
        <tr><td class="code">.d-inline</td><td class="plxs font-mono">display: inline </td></tr>
        <tr><td class="code">.d-inline-block</td><td class="plxs font-mono">display: inline-block </td></tr>
        <tr><td class="code">.d-block</td><td class="plxs font-mono">display: block </td></tr>
        <tr><td class="code">.d-table</td><td class="plxs font-mono">display: table </td></tr>
        <tr><td class="code">.d-table-row</td><td class="plxs font-mono">display: table-row </td></tr>
        <tr><td class="code">.d-table-cell</td><td class="plxs font-mono">display: table-cell </td></tr>
        <tr><td class="code">.d-flex</td><td class="plxs font-mono">display: flex</td></tr>
        <tr><td class="code">.d-inline-flex</td><td class="plxs font-mono">display: inline-flex</td></tr>
      </tbody>
    </table>
  </div>
  <div class="flex-section mbl mrxl  d-flex flex-wrap">
    <div class="flex mrxl">
      <table>
        <thead><tr><th class="">Flex</th></tr></thead>
        <tbody>
          <tr><td class="code">.justify-start</td><td class="plxs font-mono">justify-content: flex-start</td></tr>
          <tr><td class="code">.justify-end</td><td class="plxs font-mono">justify-content: flex-end</td></tr>
          <tr><td class="code">.justify-center</td><td class="plxs font-mono">justify-content: center</td></tr>
          <tr><td class="code">.justify-around</td><td class="plxs font-mono">justify-content: space-around</td></tr>
          <tr><td class="code">.justify-between</td><td class="plxs font-mono">justify-content: space-between</td></tr>
          <tr><td class="code">.align-start</td><td class="plxs font-mono">align-items: flex-start</td></tr>
          <tr><td class="code">.align-end</td><td class="plxs font-mono">align-items: flex-end</td></tr>
          <tr><td class="code">.align-center</td><td class="plxs font-mono">align-items: center</td></tr>
        </tbody>
      </table>
    </div>
    <div class="flex-direction">
      <table>
        <thead><tr><th class="">&nbsp;</th></tr></thead>
        <tbody>
          <tr><td class="code">.flex-col</td><td class="plxs font-mono">flex-direction: column</td></tr>
          <tr><td class="code">.flex-row</td><td class="plxs font-mono">flex-direction: row</td></tr>
          <tr><td class="code">.flex-grow</td><td class="plxs font-mono">flex-grow: 1</td></tr>
          <tr><td class="code">.flex-shrink</td><td class="plxs font-mono">flex-shrink: 1</td></tr>
          <tr><td class="code">.flex-wrap</td><td class="plxs font-mono">flex-wrap: wrap</td></tr>
          <tr><td class="code">.flex-no-wrap</td><td class="plxs font-mono">flex-wrap: nowrap</td></tr>
          <tr><td class="code">.row-reverse</td><td class="plxs font-mono">flex-direction: row-reverse</td></tr>
          <tr><td class="code">.col-reverse</td><td class="plxs font-mono">flex-direction: column-reverse</td></tr>
          <tr><td class="code">.order-[0-10]</td><td class="plxs font-mono">order: [0-10]</td></tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="position mbl mrxl">
    <table>
      <thead><tr><th class="">Position</th></tr></thead>
      <tbody>
        <tr><td class="code">.pos-relative</td><td class="plxs font-mono">position: relative</td></tr>
        <tr><td class="code">.pos-absolute</td><td class="plxs font-mono">position: absolute</td></tr>
        <tr><td class="code">.pos-fixed</td><td class="plxs font-mono">position: fixed</td></tr>
        <tr><td class="code">.pos-top</td><td class="plxs font-mono">top: 0</td></tr>
        <tr><td class="code">.pos-right</td><td class="plxs font-mono">right: 0</td></tr>
        <tr><td class="code">.pos-bottom</td><td class="plxs font-mono">bottom: 0</td></tr>
        <tr><td class="code">.pos-left</td><td class="plxs font-mono">left: 0</td></tr>
        <tr><td class="code">.pos-v-center</td><td class="plxs font-mono">top: 50%; transform: translateY(-50%)</td></tr>
        <tr><td class="code">.pos-h-center</td><td class="plxs font-mono">left: 50%; transform: translateX(-50%)</td></tr>
        <tr><td class="code">.pos-center</td><td class="plxs font-mono">top: 50%; left: 50%; transform: translate(-50%,-50%)</td></tr>
      </tbody>
    </table>
  </div>
  <div class="text mbl mrxl">
    <table>
      <thead><tr><th class="">Text</th></tr></thead>
      <tbody>
        <tr><td class="code">.txt-lowercase</td><td class="plxs font-mono">text-transform: lowercase</td></tr>
        <tr><td class="code">.txt-uppercase</td><td class="plxs font-mono">text-transform: uppercase</td></tr>
        <tr><td class="code">.txt-capitalize</td><td class="plxs font-mono">text-transform: capitalize</td></tr>
        <tr><td class="code">.txt-sentence</td><td class="plxs font-mono">text-transform: sentence</td></tr>
        <tr><td class="code">.font-italic</td><td class="plxs font-mono">font-style: italic </td></tr>
        <tr><td class="code">.font-monospace</td><td class="plxs font-mono">font-family: monospace</td></tr>
        <tr><td class="code">.ntd</td><td class="plxs font-mono">text-decoration: none </td></tr>
        <tr><td class="code">.txt-underline</td><td class="plxs font-mono">text-decoration: underline</td></tr>
        <tr><td class="code">.txt-c</td><td class="plxs font-mono">text-align: center</td></tr>
        <tr><td class="code">.txt-r</td><td class="plxs font-mono">text-align: right</td></tr>
        <tr><td class="code">.txt-l</td><td class="plxs font-mono">text-align: left</td></tr>
      </tbody>
    </table>
  </div>
  <div class="misc mbl mrxl">
    <table>
      <thead><tr><th class="">Misc</th></tr></thead>
      <tbody>
        <tr><td class="code">.cursor-pointer</td><td class="plxs font-mono">cursor: pointer</td></tr>
        <tr><td class="code">.full-height</td><td class="plxs font-mono">height: 100%</td></tr>
        <tr><td class="code">.full-width</td><td class="plxs font-mono">width: 100%</td></tr>
        <tr><td class="code">.hide-fully</td><td class="plxs font-mono">display: none</td></tr>
        <tr><td class="code">.hide-visibility</td><td class="plxs font-mono">visibility: hidden</td></tr>
        <tr><td class="code">.whitespace-nowrap</td><td class="plxs font-mono">white-space: nowrap</td></tr>
      </tbody>
    </table>
  </div>
</div>