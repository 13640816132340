<div class="links" *ngIf="currentProject">
    <div class="page-heading d-flex align-center justify-between mbs col-12">
        <h1 class="mrm">Links</h1>
        <a class="btn" (click)="updateProject()">Save changes</a>
    </div>
    <section class="links">
        <div class="col-12" *ngIf="currentProject?.components?.links"  dragula="links" [(dragulaModel)]="currentProject.components.links">
            <div class="accordion-control" [ngClass]="{'toggled': link.toggled}"
                *ngFor="let link of currentProject.components.links; let i = index" [attr.id]="toKebabCase(link.name)">
                <div class="accordion-heading cursor-pointer d-flex align-center justify-between" 
                    [ngClass]="{'mbs': link.toggled}" 
                    (click)="toggleLink(i)" 
                    (mouseover)="heading.hovered = true"
                    (mouseleave)="heading.hovered = false" 
                    #heading
                >
                    <div class="left d-flex align-center justify-start">
                        <h3>{{link.name}}{{!link.toggled ? ':' : ''}}</h3>
                        <a class="def-link mlxs" [ngStyle]="linkStyles[i].base" *ngIf="!link.toggled">{{link.text}}</a>
                    </div>
                    <div class="right d-flex align-center justify-end">
                        <b class="color-system-error mrxs" *ngIf="heading.hovered" (click)="deleteLink(i)">Delete</b>
                        <styles-icon name="copy" color="neutral-darker" *ngIf="heading.hovered" (click)="duplicateLink($event,link,i)"></styles-icon>
                        <styles-icon name="caretDown" color="neutral-darker" [ngStyle]="{'transform': !link.toggled ? 'none' : 'rotate(180deg)'}"></styles-icon>
                    </div>
                </div>

                <div class="accordion-body" *ngIf="link.toggled">
                    <div class="d-flex">
                        <div class="link-wrap mrl mtxs">
                            <a class="def-link" 
                                (mouseover)="link.hovered = true"
                                (mouseleave)="link.hovered = false"
                                [ngStyle]="(link.hovered  || linkStateForm.value.linkState === 'hover' ) ? linkStyles[i].hover : linkStyles[i].base"
                            >{{link.text}}</a>
                        </div>
                        <div class="link-state">
                            <form [formGroup]="linkStateForm" class="d-flex flex-col mtxs">
                              <label class="d-flex align-center">
                                <input type="radio" style="height: 20px" class="mrxxs" value="base" formControlName="linkState" selected>
                                <span>Base</span>
                              </label>
                              <label class="d-flex align-center">
                                <input type="radio" style="height: 20px" class="mrxxs" value="hover" formControlName="linkState">
                                <span>Hover</span>
                              </label>
                              <!-- <label class="d-flex align-center">
                                <input type="radio" style="height: 20px" class="mrxxs" value="disabled" formControlName="buttonState">
                                <span>Disabled</span>
                              </label> -->
                           </form>
                           <!-- <pre>{{buttonStateForm.value | json}}</pre> -->
                          </div>
                        
                    </div>
                    <div class="d-flex flex-wrap align-start">
                        <table class="mtm mrl">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th><span style="visibility: hidden;">Spacer</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="tdlabel">
                                        Link Name
                                    </td>
                                    <td><input type="text" [(ngModel)]="link.name"></td>
                                </tr>
                                <tr>
                                    <td class="tdlabel">
                                        Link Text
                                    </td>
                                    <td><input type="text" [(ngModel)]="link.text"></td>
                                </tr>
                                <tr>
                                    <td class="tdlabel">
                                        Transition
                                    </td>
                                    <td>
                                        <div class="sizeholder">
                                            <input type="number" [(ngModel)]="link.transition">
                                            s
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="mtm mrl">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Base</th>
                                    <th>Hover</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        Font Color
                                    </td>
                                    <td>
                                        <select [(ngModel)]="link.styles.color.base" class="full-width"
                                            [ngStyle]="{'border-right' :'10px solid '+ colorSlugs[link.styles.color.base]}">
                                            {{ colorSlugs[link.styles.color.base] }}
                                            <option *ngFor="let color of currentProject.variables.colors"
                                                [ngValue]="color.slug">{{color.label}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select [(ngModel)]="link.styles.color.hover" class="full-width"
                                            [ngStyle]="{'border-right' :'10px solid '+ colorSlugs[link.styles.color.hover]}">
                                            {{ colorSlugs[link.styles.color.hover] }}
                                            <option *ngFor="let color of currentProject.variables.colors"
                                                [ngValue]="color.slug">{{color.label}}</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Font Weight
                                    </td>
                                    <td>
                                        <select [(ngModel)]="link.styles.fontWeight.base" class="full-width">
                                            <option *ngFor="let weight of filteredWeights" [ngValue]="weight.value">{{
                                                weight.label }}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select [(ngModel)]="link.styles.fontWeight.hover" class="full-width">
                                            <option *ngFor="let weight of filteredWeights" [ngValue]="weight.value">{{
                                                weight.label }}</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="tdlabel">
                                        Background
                                    </td>
                                    <td>
                                        <select [(ngModel)]="link.styles.background.base" class="full-width"
                                            [ngStyle]="{'border-right' :'10px solid '+ colorSlugs[link.styles.background.base]}">
                                            {{ colorSlugs[link.styles.background.base] }}
                                            <option *ngFor="let color of currentProject.variables.colors"
                                                [ngValue]="color.slug">{{color.label}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select [(ngModel)]="link.styles.background.hover" class="full-width"
                                            [ngStyle]="{'border-right' :'10px solid '+ colorSlugs[link.styles.background.hover]}">
                                            {{ colorSlugs[link.styles.background.hover] }}
                                            <option *ngFor="let color of currentProject.variables.colors"
                                                [ngValue]="color.slug">{{color.label}}</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="section-heading font-bold ptm pbxs">
                                        Text Decoration
                                    </td>        
                                </tr>
                                <tr>
                                    <td class="tdlabel">
                                        Line
                                    </td>
                                    <td>
                                        <select [(ngModel)]="link.styles.textDecorationLine.base" class="full-width">
                                            {{ link.styles.textDecorationLine.base}}
                                            <option *ngFor="let line of decoration.line"
                                                [ngValue]="line">{{line}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select [(ngModel)]="link.styles.textDecorationLine.hover" class="full-width">
                                            {{ link.styles.textDecorationLine.hover}}
                                            <option *ngFor="let line of decoration.line"
                                                [ngValue]="line">{{line}}</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="tdlabel">
                                        Color
                                    </td>
                                    <td>
                                        <select [(ngModel)]="link.styles.textDecorationColor.base" class="full-width"
                                            [ngStyle]="{'border-right' :'10px solid '+ colorSlugs[link.styles.textDecorationColor.base]}">
                                            {{ colorSlugs[link.styles.textDecorationColor.base] }}
                                            <option *ngFor="let color of currentProject.variables.colors"
                                                [ngValue]="color.slug">{{color.label}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select [(ngModel)]="link.styles.textDecorationColor.hover" class="full-width"
                                            [ngStyle]="{'border-right' :'10px solid '+ colorSlugs[link.styles.textDecorationColor.hover]}">
                                            {{ colorSlugs[link.styles.textDecorationColor.hover] }}
                                            <option *ngFor="let color of currentProject.variables.colors"
                                                [ngValue]="color.slug">{{color.label}}</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="tdlabel">
                                        Line
                                    </td>
                                    <td>
                                        <select [(ngModel)]="link.styles.textDecorationStyle.base" class="full-width">
                                            {{ link.styles.textDecorationStyle.base}}
                                            <option *ngFor="let style of decoration.style"
                                                [ngValue]="style">{{style}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select [(ngModel)]="link.styles.textDecorationStyle.hover" class="full-width">
                                            {{ link.styles.textDecorationStyle.hover}}
                                            <option *ngFor="let style of decoration.style"
                                                [ngValue]="style">{{style}}</option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="col-12 mtl">
        <a class="btn" (click)="addLink()">+ Add Link</a>
    </div>
</div>


<!-- text-decoration-line (required)
text-decoration-color
text-decoration-style -->


<!-- Dark Blue : #0F2A3B 
Blue : #0D456D 
Light Blue : #C4D0D9
Dark Orange : #F3780B
Orange : #F4901D
Dark Green : #0C270C
Green : #0C392A
Light Green : #B9C9C3
Yellow : #FED464
Light Yellow : #FAE9B8
Grey : #F3F3F3
White : #FFFFFF
CTA Default : #F4901D
CTA Hover : #F3780B
Type Dark : #08131C
Type Soft : #42494F
Type Light : #FFFFFF -->