export class LogoModel {
  toggled = true;
  hovered = false;
  clicked = false;
  name = 'Logo Name';
  types = {
    lightBG: {
      active: true,
      svg: '',
      png: ''
    },
    darkBG: {
      active: true,
      svg: '',
      png: ''
    }
  }
}
