import { Component, OnInit } from '@angular/core';
import { UserSubscriptionMixin } from 'src/app/mixins/subscriptions.mixin';
import { ClientService } from 'src/app/services/client.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent extends UserSubscriptionMixin implements OnInit {

  constructor(
    userService: UserService,
    navService: NavigationService,
    projectService: ProjectService,
    clientService: ClientService,
  ) {
    super(userService, navService, projectService, clientService);
  }

  ngOnInit(): void {}

  saveUser() {
    this.userService.saveUser(this.currentUser.id, {position: this.currentUser.position});
  }

  logout() {
    this.userService.asyncLogout();
  }

}
