<ng-container *ngIf="admin">
  <ng-container *ngFor="let module of modules; let moduleIndex = index">
    <div class="form-control full-width">
      <ng-container *ngIf="module.type == 'text'">
        <label [for]="module.type + moduleIndex">{{module.label}}</label>
        <textarea [name]="module.type + moduleIndex" rows="5" [(ngModel)]="module.value" class="full-width" (blur)="onUpdateModules()"></textarea>
      </ng-container>
      <ng-container *ngIf="module.type == 'heading'">
        <label [for]="module.type + moduleIndex">{{module.label}}</label>
        <input type="text" [name]="module.type + moduleIndex" [(ngModel)]="module.value" class="full-width" (blur)="onUpdateModules()">
      </ng-container>
      <ng-container *ngIf="module.type == 'image'">
        <label [for]="module.type + moduleIndex">Image</label>
        <styles-file-upload
          [url]="module.value"
          fileType="image/*"
          [currentProjectId]="currentProjectId"
          (updateUrl)="updateFile($event, moduleIndex)"
          (removeUrl)="removeFile($event, moduleIndex)"
          class="full-width d-flex align-center mtxxxs"
        ></styles-file-upload>
      </ng-container>
      <styles-icon name="close" (click)="removeModule(moduleIndex)" class="cursor-pointer"></styles-icon>
    </div>
  </ng-container>
  <styles-dropdown>
    <ng-container class="dropdown-link">+ Add module</ng-container>
    <li *ngFor="let module of moduleTypes;let index = index" class="d-flex align-center mbxxs cursor-pointer" (click)="addModule(index)">
        <div>{{module.label}}</div>
    </li>
  </styles-dropdown>
</ng-container>
<ng-container *ngIf="!admin">
  <ng-container *ngFor="let module of modules">
    <h4 *ngIf="module.type === 'heading'" class="mbxs">{{module.value}}</h4>
    <p *ngIf="module.type === 'text'" class="mbxs">{{module.value}}</p>
    <img *ngIf="module.type === 'image'" [src]="module.value" [alt]="module.label" class="mbxs">
  </ng-container>
</ng-container>
