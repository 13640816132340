import { Component, OnDestroy, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { Subscription } from 'rxjs';
import { UserSubscriptionMixin } from 'src/app/mixins/subscriptions.mixin';
import { NavigationService } from 'src/app/services/navigation.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';
import { ClientService } from 'src/app/services/client.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent extends UserSubscriptionMixin implements OnInit {
  projects;
  filteredProjects;
  currentSort;
  rightNow;
  gradientBackgrounds;
  sortOptions = ['Last Modified','Alphabetical','Date Created'];
  searchValue = null;

  constructor(
    userService: UserService,
    projectService: ProjectService,
    navService: NavigationService,
    clientService: ClientService,
    private sanitizer: DomSanitizer
  ) { 
    super(userService, navService, projectService, clientService);
  }

  ngOnInit(): void {
    this.gradientBackgrounds = {};
    this.currentSort = "Last Modified";
    this.clientsLoaded
    .then(() => this.projectsLoaded)
    .then(() => {
      this.rightNow = new Date().getTime() / 1000;
      this.projects.forEach((proj, key) => {
        if (proj.settings.icon && proj.settings.iconSvg) {
          proj.sanitizedSvg = this.sanitizer.bypassSecurityTrustHtml(proj.settings.iconSvg);
        } else {
          this.gradientBackgrounds[proj.id] = "grey";
  
          if (proj.settings.thumbnail && proj.settings.thumbnail.some(c => c.slug)) {
            this.gradientBackgrounds[proj.id] = this.generateGradient(proj.settings.thumbnail, proj.name);
          } else if (proj.variables.colors) {
            this.gradientBackgrounds[proj.id] = this.generateGradient(proj.variables.colors, proj.name);
          }
        }
        
        // clients
        let projClient = this.clients.filter(c => {
          return c.id === proj.client;
        })[0]
        if (projClient) proj.clientName = projClient.name

        // add elements arr if it doesn't exist
        this.projectService.convertTagsToElements(proj)

      });
    });
  }

  formatProjectModifiedDate(date) {
    date = date.seconds;
    let formattedDate = "";
    let timeDifference = this.rightNow - date;
    let dateObject = new Date(date);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct',	'Nov', 'Dec'];

    if( timeDifference <= 3600) {
      formattedDate = Math.floor(timeDifference / 60) + ' min ago';
    } else if (timeDifference > 3600 && timeDifference <= 86400 ) {
      formattedDate = Math.floor(timeDifference / 60 / 60) + ' hr ago';
    } else {
      formattedDate = months[dateObject.getMonth()] +'. ' + dateObject.getDate();
    }

    return formattedDate;
  }

  formatDateString(date) {
    return '';
    let dateObject = new Date(date.seconds * 1000);

    return formatDate(dateObject, 'M/d/yy, h:mm a', 'en-US');
  }

  sumChars(s) {
    var i, n = s.length, acc = 0;
    for (i = 0; i < n; i++) {
      acc += parseInt(s[i], 36) - 9;
    }
    return acc;
  }

  
  generateGradient(colors, name) {
    let backgroundString = "grey";

    colors = colors.filter(c => !!c.slug && !!c.hex && !!c.label);

    if (colors.length === 1) {
      backgroundString = colors[0].hex;
    } else if (colors.length === 2) {
      let nameint = this.sumChars(name.replace(/\W/g, '').toLowerCase());
      let degree = nameint % 360;
      backgroundString = `linear-gradient(${degree}deg, ${colors[0].hex} calc(50% - 1px), rgba(0, 0, 0, 0) 50%), linear-gradient(${degree}deg, #fff calc(50% - 2px), ${colors[1].hex} calc(50% - 1px))`;
    } else if (colors.length > 2) {
      let nameint = this.sumChars(name.replace(/\W/g, '').toLowerCase());
      let degree = nameint % 360;
      let degree2 = (nameint / 4.1) % 360;
      backgroundString = `linear-gradient(${degree}deg, ${colors[0].hex} calc(33% - 1px), rgba(0, 0, 0, 0) 33%), linear-gradient(${degree2}deg, ${colors[1].hex} calc(66% - 2px), ${colors[2].hex} calc(66% - 1px))`;
    }

    return backgroundString;
  }

  selectSortOption(sortOption) {
    this.currentSort = sortOption;
    this.projectService.sortProjects(sortOption);
  }

  searchProjects() {
    this.projectService.searchProjects(this.searchValue.toLowerCase());
  }

  /*filterByClient(clientID) {
    this.filteredProjects = this.projects.filter(e => {
      return e.client === clientID;
    });
  }*/
}
