import { Component, OnInit } from '@angular/core';
import { UserSubscriptionMixin } from 'src/app/mixins/subscriptions.mixin';
import { ClientService } from 'src/app/services/client.service';
import { LoadingService } from 'src/app/services/loading.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-client-links',
  templateUrl: './client-links.component.html',
  styleUrls: ['./client-links.component.scss']
})
export class ClientLinksComponent extends UserSubscriptionMixin implements OnInit {
  linkGroups = []
  emptyGroup = {
    label: '',
    description: [],
    links: []
  }
  emptyLink = {
    label: '',
    type: 'link',
    url: '',
    value: null
  }

  constructor(
    userService: UserService,
    navService: NavigationService,
    projectService: ProjectService,
    clientService: ClientService,
    private loadingService: LoadingService,
  ) {
    super(userService, navService, projectService, clientService);
  }

  ngOnInit(): void {
    this.currentProjectLoaded.then(() => {
      this.linkGroups = this.currentProject.clientPortal?.links || [];
      this.linkGroups.forEach(group => {
        if (typeof group.description === 'string') {
          group.description = []
        }
      })
      console.log(this.currentProject)
    })
  }

  addGroup() {
    this.linkGroups.push({ ...this.emptyGroup });
  }
  removeGroup(i) {
    this.linkGroups.splice(i, 1)
    this.updateProject();
  }
  updateModules($event, groupIndex) {
    this.linkGroups[groupIndex].description = [...$event]
    this.updateProject();
  }
  addLink(i) {
    this.linkGroups[i].links.push({ ...this.emptyLink });
  }
  updateLinkFile(event, groupIndex, linkIndex) {
    this.linkGroups[groupIndex].links[linkIndex].value = event
    this.updateProject();
  }
  removeLinkFile(event, groupIndex, linkIndex) {
    this.linkGroups[groupIndex].links[linkIndex].value = ''
    this.updateProject();
  }

  updateProject() {
    this.currentProject.clientPortal.links = this.linkGroups

    this.loadingService.startLoading();
    this.projectService.updateProject(this.currentProject.id, this.currentProject)
    .then(() => {
      this.loadingService.stopLoading()
    });
  }

  toKebabCase(str) {
    return str && str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.toLowerCase())
      .join('-');
  }

}
