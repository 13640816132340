import { Component, OnInit, ViewChild } from '@angular/core';
import { UserSubscriptionMixin } from 'src/app/mixins/subscriptions.mixin';
import { ClientService } from 'src/app/services/client.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';

const ColorModel = {
  label: '',
  hex: '',
  group: '',
  slug: ''
}

@Component({
  selector: 'app-color',
  templateUrl: './color.component.html',
  styleUrls: ['./color.component.scss']
})
export class ColorComponent extends UserSubscriptionMixin implements OnInit {
  @ViewChild('addColorModal') modal;

  newColor = { ...ColorModel }
  newColorError = '';
  bulkColorsInput = '';
  bulkColors = [];

  editingColor = null;

  constructor(
    userService: UserService,
    navService: NavigationService,
    projectService: ProjectService,
    clientService: ClientService,
  ) {
    super(userService, navService, projectService,clientService);
  }

  ngOnInit(): void {
    this.currentProjectLoaded.then(() => {
      if (!this.currentProject.variables?.colors) {
        this.currentProject.variables = {colors: []};
      }
    })
  }

  colorIsValid() {
    let { newColor, currentProject } = this;
    let uniqueLabel = true;

    if (
      currentProject.variables.colors &&
      currentProject.variables.colors.length &&
      currentProject.variables.colors.some(c => c.label === newColor.label)
    ) {
      uniqueLabel = false;
    }
    return newColor.hex && newColor.hex && uniqueLabel

  }

  saveColor(bulkColors = null, bulkInd = null) {
    this.newColorError = '';
    if (bulkColors) {
      this.newColor = {...bulkColors[bulkInd]}
    }

    let { currentProject, currentProjectId, projectService, modal, newColor, editingColor } = this;
    
    if (editingColor !== null || this.colorIsValid()) {
      if (newColor.slug !== "") {
        newColor.slug = this.convertToKababCase(newColor.slug)
      } else {
        newColor.slug = this.convertToKababCase(newColor.label);
      }
      newColor.hex = (newColor.hex.startsWith('#') ? '' : '#') + newColor.hex
      newColor.label = this.capitalize(newColor.label)
      
      if (editingColor !== null) {
        currentProject.variables.colors[editingColor] = {...newColor};
      } else {
        currentProject.variables.colors.push(newColor)
      }

      projectService.updateProject(currentProjectId, currentProject).then(() => {
        if (editingColor !== null) editingColor = null;
        newColor = { ...ColorModel }
        if (this.modal.modalToggled) modal.toggleModal();

        if (bulkColors && bulkInd < bulkColors.length - 1) {
          this.saveColor(bulkColors, bulkInd + 1);
        } else if (bulkColors && bulkInd >= bulkColors.length - 1) {
          this.bulkColorsInput = '';
        }
      })

      //editingColor = null;
    } else {
      this.newColorError = `'${newColor.label}' is already in use. Please choose a new label.`;
    }

  }

  bulkAdd() {
    let lines = this.bulkColorsInput.split('\n');
    let colorsArr = lines.map(line => {
      let colorParts = line.split(" : ");
      return {
        label: colorParts[0],
        hex: colorParts[1],
        slug: colorParts[2] ? colorParts[2] : '',
      }
    })

    this.saveColor(colorsArr, 0);
  }

  editColor(color, index) {
    this.editingColor = index;
    this.newColor = {...color};
    this.modal.toggleModal();
  }

  deleteColor(index) {
    this.currentProject.variables.colors.splice(index, 1)
    this.projectService.updateProject(this.currentProjectId, this.currentProject)
  }

  convertToKababCase(str) {
    return str && str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g).map(x => x.toLowerCase()).join('-');
  }

  capitalize(str) {
    return str.replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
  }

  changeHide(val: boolean) {
    //alert();
    console.log(this.editingColor);
    this.editingColor = null;
    this.newColor = { ...ColorModel }
  }
}

