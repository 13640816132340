<div class="col-12" *ngIf="currentProject && currentProject.clientPortal">
  <div class="page-heading d-flex align-center justify-between mbxs">
    <h2 class="mrm">Brand Guidelines</h2>
    <a class="btn" (click)="updateProject()">Save changes</a>
  </div>

  <div class="section-description mbxl">
    <h5 class="mbxs">Section description</h5>
    <styles-builder [modules]="guidelinesData.description" (updateModules)="updateModules($event)" [currentProjectId]="currentProject.id"></styles-builder>
  </div>

  <div class="guideline-links">
    <h5 class="mbxs">Guideline Links</h5>
    <table class="links">
      <thead>
        <th>Label</th>
        <th>Type</th>
        <th>Input</th>
      </thead>
      <tr *ngFor="let link of guidelinesData.links; let i = index">
        <td class="label">
          <input type="text" [(ngModel)]="link.label" name="label-{{i}}" id="label-{{i}}">
        </td>
        <td class="type">
          <select [(ngModel)]="link.type">
            {{ link.type }}
            <option value="link" selected>Link</option>
            <option value="file">File</option>
          </select>
        </td>
        <td class="input flex-grow">
          <div class="link" *ngIf="link.type == 'link'">
            <input type="url" name="url-{{i}}" id="url-{{i}}" [(ngModel)]="link.url">
          </div>
          <div class="file" *ngIf="link.type == 'file'">
            <!-- <input type="file" name="file-{{i}}" id="file-{{i}}"> -->
            <styles-file-upload 
              [url]="link.value" 
              fileName="toKebabCase(link.label) + i"
              (updateUrl)="updateLinkFile($event, i)"
              (removeUrl)="removeLinkFile($event, i)"
              class="full-width d-flex align-center mtxxxs"
            ></styles-file-upload>
          </div>
        </td>
      </tr>
    </table>
    <div class="add-link-wrap txt-r mtxs">
      <a class="txt-underline cursor-pointer d-inline-flex align-center" (click)="addLink()">
        <styles-icon name="add" class="mrxxxs"></styles-icon>
        Add Link
      </a>
    </div>
  </div>

</div>