import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  currentView = new BehaviorSubject('');
  currentSlug = new BehaviorSubject('');
  currentProjectId = new BehaviorSubject('');
  currentUser;

  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  updateNavData(pathname, user) {
    return new Promise<void>((res) => {
      if (pathname.includes('/p/')) {
        let selectedView = user.selectedView;
        const slug = pathname.substring(pathname.lastIndexOf('/') + 1)
        const id = pathname.substring(3, pathname.lastIndexOf('/'))
        this.currentView.next(selectedView);
        this.currentSlug.next(slug);
        this.currentProjectId.next(id);
        res(id);
      } else {
        this.currentProjectId.next(null);
        this.currentSlug.next(null);
        if (pathname.includes('/settings')) {
          this.currentView.next('settings');
        } else {
          this.currentView.next('dashboard');
        }
        res()
      }
    })
  }

}
