<!-- <div class="logos" *ngIf="currentProject"> -->
<div class="logos" >
  <div class="page-heading d-flex align-center justify-between mbxs">
    <h1 class="mrm">Logos</h1>
    <a class="btn" (click)="updateProject()">Save changes</a>
  </div>
  <section class="logo-sets mbs">

    <div class="col-12" *ngIf="currentProject?.components?.logos" dragula="logos" [(dragulaModel)]="currentProject.components.logos">
      <div class="accordion-control" [ngClass]="{'toggled': logo.toggled}" *ngFor="let logo of currentProject.components.logos; let i = index" [attr.id]="toKebabCase(logo.name)">
        <div class="accordion-heading cursor-pointer d-flex align-center justify-between" 
          [ngClass]="{'mbs': logo.toggled}" 
          (click)="toggleLogo(i)" 
          (mouseover)="heading.hovered = true"
          (mouseleave)="heading.hovered = false" 
          #heading
        >
          <div class="left d-flex align-center justify-start">
              <h3>{{logo.name}}</h3>
          </div>
          <div class="right d-flex align-center justify-end">
              <b class="color-system-error mrxs" *ngIf="heading.hovered" (click)="deleteLogo(i)">Delete</b>
              <styles-icon name="copy" color="neutral-darker" *ngIf="heading.hovered" (click)="duplicateLogo($event,logo,i)"></styles-icon>
              <styles-icon name="caretDown" color="neutral-darker" [ngStyle]="{'transform': !logo.toggled ? 'none' : 'rotate(180deg)'}"></styles-icon>
          </div>
        </div>
        <div class="accordion-body" *ngIf="logo.toggled">
          <div class="col-12 col-lg-6 col-md-10">
            <!-- <h3 class="mbxs">Logo Set</h3> -->
            <div class="form-control mbs">
              <label>Label</label>
              <input type="text" class="full-width" [(ngModel)]="logo.name">
            </div>
            <div class="form-control mbs">
              <label>BG Types</label>
              <div class="checkboxes d-flex align-center full-width">
                <div class="checkbox mrs">
                  <label for="" class="d-flex align-center cursor-pointer">
                    <input type="checkbox" [(ngModel)]="logo.types.lightBG.active"
                    [ngModelOptions]="{standalone: true}" class="mrxs"/>
                    Light
                  </label>
                </div>
                <div class="checkbox">
                  <label for="" class="d-flex align-center cursor-pointer">
                    <input type="checkbox" [(ngModel)]="logo.types.darkBG.active"
                    [ngModelOptions]="{standalone: true}" class="mrxs"/>
                    Dark
                  </label>
                </div>
              </div>
            </div>
            <div class="row">

              <div class="col-12" *ngIf="logo.types.lightBG.active">
                <h5 class="mbxxs">Light BG</h5>
                <div class="logo-holder light" *ngIf="logo.types.lightBG.svg || logo.types.lightBG.png">
                  <div class="logo-inner">
                    <div class="logo-svg" *ngIf="logo.types.lightBG.svg && logo.types.lightBG.sanitized" [innerHTML]="logo.types.lightBG.sanitized"></div>
                    <img *ngIf="!logo.types.lightBG.svg && logo.types.lightBG.png" [src]="logo.types.lightBG.png">
                  </div>
                </div>
                <div class="form-control align-start mbs">
                  <label>SVG</label>
                  <textarea [ngModel]="logo.types.lightBG.svg" (ngModelChange)="onSvgChange($event, logo, 'light')" name="" id="" rows="4" class="mbxs full-width"></textarea>
                </div>
                <div class="form-control align-start mbs">
                  <label>PNG</label>
                  <div class="png-file full-width">
                    <input *ngIf="!logo.types.lightBG.png" accept=".png" type="file" [id]="'logo-png-' + i" (change)="uploadFile($event, logo, 'light')" [name]="'logo-png-' + i" autocomplete="off">
                    <div *ngIf="logo.types.lightBG.png" class="d-flex justify-between align-center">
                      <a [href]="logo.types.lightBG.png" class="link txt-underline d-inline-flex align-center">
                        <styles-icon name="file" class="mrxxxs"></styles-icon>
                        PNG File
                      </a>
                      <styles-icon name="close" (click)="resetFile(logo, 'light')"></styles-icon>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12" *ngIf="logo.types.darkBG.active">
                <h5 class="mbxxs">Dark BG</h5>
                <div class="logo-holder dark" *ngIf="(logo.types.darkBG.svg && logo.types.darkBG.sanitized) || logo.types.darkBG.png">
                  <div class="logo-inner">
                    <div class="logo-svg" *ngIf="logo.types.darkBG.svg && logo.types.darkBG.sanitized" [innerHTML]="logo.types.darkBG.sanitized"></div>
                    <img *ngIf="!logo.types.darkBG.svg && logo.types.darkBG.png" [src]="logo.types.darkBG.png">
                  </div>
                </div>
                <div class="form-control align-start mbs">
                  <label>SVG</label>
                  <textarea [ngModel]="logo.types.darkBG.svg" (ngModelChange)="onSvgChange($event, logo, 'dark')" name="" id="" rows="4" class="mbxs full-width"></textarea>
                </div>
                <div class="form-control align-start mbs">
                  <label>PNG</label>
                  <div class="png-file full-width">
                    <input *ngIf="!logo.types.darkBG.png" accept=".png" type="file" [id]="'logo-png-' + i" (change)="uploadFile($event, logo, 'dark')" [name]="'logo-png-' + i" autocomplete="off">
                    <div *ngIf="logo.types.darkBG.png" class="d-flex justify-between align-center">
                      <a [href]="logo.types.darkBG.png" class="link txt-underline d-inline-flex align-center">
                        <styles-icon name="file" class="mrxxxs"></styles-icon>
                        PNG File
                      </a>
                      <styles-icon name="close" (click)="resetFile(logo, 'dark')"></styles-icon>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
  <div class="col-12 mtl">
    <a class="btn" (click)="addLogo()">+ Add Logo</a>
  </div>
</div>