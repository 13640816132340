import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'styles-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {
  dropdownHidden = true;
  constructor() { }

  
  ngOnInit(): void {
  }

  toggleDropdown() {
    this.dropdownHidden = !this.dropdownHidden;
  }

}
