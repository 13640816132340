<div class="buttons" *ngIf="currentProject">
  <div class="page-heading d-flex align-center justify-between mbs col-12">
    <h1 class="mrm">Buttons</h1>
    <a class="btn" (click)="updateProject()">Save changes</a>
  </div>
  <section class="buttons">
    <div class="col-12" *ngIf="currentProject?.components?.buttons" dragula="buttons" [(dragulaModel)]="currentProject.components.buttons">
      <div class="accordion-control" [ngClass]="{'toggled': button.toggled}" *ngFor="let button of currentProject.components.buttons; let i = index" [attr.id]="toKebabCase(button.name)">
        <!-- <h3 class="mbs cursor-pointer d-flex align-center justify-between" (click)="toggleButton(button)">
          {{button.name}} <bn name="caretDown" color="neutral-darker"
            [ngStyle]="{'transform': button.toggled ? 'rotate(180deg)' : 'rotate(0deg)'}"></bn>
        </h3> -->
        <div class="accordion-heading cursor-pointer d-flex align-center justify-between" 
          [ngClass]="{'mbs': button.toggled}" 
          (click)="toggleButton(i)" 
          (mouseover)="heading.hovered = true"
          (mouseleave)="heading.hovered = false" 
          #heading
        >
          <div class="left d-flex align-center justify-start">
              <h3>{{button.name}}</h3>
          </div>
          <div class="right d-flex align-center justify-end">
              <b class="color-system-error mrxs" *ngIf="heading.hovered" (click)="deleteButton(i)">Delete</b>
              <styles-icon name="copy" color="neutral-darker" *ngIf="heading.hovered" (click)="duplicateButton($event,button,i)"></styles-icon>
              <styles-icon name="caretDown" color="neutral-darker" [ngStyle]="{'transform': !button.toggled ? 'none' : 'rotate(180deg)'}"></styles-icon>
          </div>
        </div>
        <div class="accordion-body" *ngIf="button.toggled">
          <div class="d-flex flex-col">
            <div class="d-flex">
              <div class="btn-wrap mrl mtxs">
                <button class="def-btn" (mouseover)="buttonStateForm.value.buttonState !== 'disabled' && button.hovered = true" (mouseleave)="buttonStateForm.value.buttonState !== 'disabled' && button.hovered = false"
                  [ngStyle]="
                    button.hovered || buttonStateForm.value.buttonState === 'hover' ?
                      buttonStyles[i].hover :
                      buttonStateForm.value.buttonState === 'disabled' ?
                        buttonStyles[i].disabled :
                        buttonStyles[i].base"
                >
                    <div class="btn-icon mlxxs d-inline-flex align-center" *ngIf="button.icon.active && button.icon.position === 'left'" [innerHTML]="button.icon.sanitized"
                      [ngStyle]="
                      button.hovered || buttonStateForm.value.buttonState === 'hover' ?
                        iconStyles[i].hover :
                        buttonStateForm.value.buttonState === 'disabled' ?
                          iconStyles[i].disabled :
                          iconStyles[i].base"
                    ></div>
                    {{button.text}}
                    <div class="btn-icon mlxxs d-inline-flex align-center" *ngIf="button.icon.active && button.icon.position === 'right'" [innerHTML]="button.icon.sanitized"
                      [ngStyle]="
                      button.hovered || buttonStateForm.value.buttonState === 'hover' ?
                        iconStyles[i].hover :
                        buttonStateForm.value.buttonState === 'disabled' ?
                          iconStyles[i].disabled :
                          iconStyles[i].base"
                    ></div>
                </button>
              </div>
              <!-- <small class="mtxxs color-neutral-dark">click to {{button.clicked ? 'un' : ''}}toggle hover state</small> -->
              <div class="button-state">
                <form [formGroup]="buttonStateForm" class="d-flex flex-col mtxs">
                  <label class="d-flex align-center">
                    <input type="radio" style="height: 20px" class="mrxxs" value="base" formControlName="buttonState" selected>
                    <span>Base</span>
                  </label>
                  <label class="d-flex align-center">
                    <input type="radio" style="height: 20px" class="mrxxs" value="hover" formControlName="buttonState">
                    <span>Hover</span>
                  </label>
                  <label class="d-flex align-center">
                    <input type="radio" style="height: 20px" class="mrxxs" value="disabled" formControlName="buttonState">
                    <span>Disabled</span>
                  </label>
               </form>
               <!-- <pre>{{buttonStateForm.value | json}}</pre> -->
              </div>
            </div>
          </div>
          <div class="d-flex flex-wrap align-start">
            <table class="mtm mrl">
              <thead>
                <tr>
                  <th></th>
                  <th><span style="visibility: hidden;">Sizer</span></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="tdlabel">
                    Button Name
                  </td>
                  <td><input type="text" [(ngModel)]="button.name"></td>
                </tr>
                <tr>
                  <td class="tdlabel">
                    Button Text
                  </td>
                  <td><input type="text" [(ngModel)]="button.text"></td>
                </tr>
                <tr>
                  <td class="tdlabel">
                    Transition
                  </td>
                  <td>
                    <div class="sizeholder">
                      <input type="number" [(ngModel)]="button.transition">
                      s
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="mtm">
              <thead>
                <tr>
                  <th></th>
                  <th>Base</th>
                  <th>Hover</th>
                  <th>Disabled</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="tdlabel">
                    Background
                  </td>
                  <td>
                    <select [(ngModel)]="button.styles.background.base"
                      [ngStyle]="{'border-right' :'10px solid '+ colorSlugs[button.styles.background.base]}">
                      {{ colorSlugs[button.styles.background.base] }}
                      <option value="transparent">Transparent</option>
                      <option *ngFor="let color of currentProject.variables.colors" [ngValue]="color.slug">{{color.label}}
                      </option>
                    </select>
                  </td>
                  <td>
                    <select [(ngModel)]="button.styles.background.hover"
                      [ngStyle]="{'border-right' :'10px solid '+ colorSlugs[button.styles.background.hover]}">
                      {{ colorSlugs[button.styles.background.hover] }}
                      <option value="transparent">Transparent</option>
                      <option *ngFor="let color of currentProject.variables.colors" [ngValue]="color.slug">{{color.label}}
                      </option>
                    </select>
                  </td>
                  <td>
                    <select [(ngModel)]="button.styles.background.disabled"
                      [ngStyle]="{'border-right' :'10px solid '+ colorSlugs[button.styles.background.disabled]}">
                      {{ colorSlugs[button.styles.background.disabled] }}
                      <option value="transparent">Transparent</option>
                      <option *ngFor="let color of currentProject.variables.colors" [ngValue]="color.slug">{{color.label}}
                      </option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>
                    Font Color
                  </td>
                  <td>
                    <select [(ngModel)]="button.styles.color.base"
                      [ngStyle]="{'border-right' :'10px solid '+ colorSlugs[button.styles.color.base]}">
                      {{ colorSlugs[button.styles.color.base] }}
                      <option *ngFor="let color of currentProject.variables.colors" [ngValue]="color.slug">{{color.label}}
                      </option>
                    </select>
                  </td>
                  <td>
                    <select [(ngModel)]="button.styles.color.hover"
                      [ngStyle]="{'border-right' :'10px solid '+ colorSlugs[button.styles.color.hover]}">
                      {{ colorSlugs[button.styles.color.hover] }}
                      <option *ngFor="let color of currentProject.variables.colors" [ngValue]="color.slug">{{color.label}}
                      </option>
                    </select>
                  </td>
                  <td>
                    <select [(ngModel)]="button.styles.color.disabled"
                      [ngStyle]="{'border-right' :'10px solid '+ colorSlugs[button.styles.color.disabled]}">
                      {{ colorSlugs[button.styles.color.disabled] }}
                      <option *ngFor="let color of currentProject.variables.colors" [ngValue]="color.slug">{{color.label}}
                      </option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>
                    Border Color
                  </td>
                  <td>
                    <select [(ngModel)]="button.styles.borderColor.base"
                      [ngStyle]="{'border-right' :'10px solid '+ colorSlugs[button.styles.borderColor.base]}">
                      {{ colorSlugs[button.styles.borderColor.base] }}
                      <option *ngFor="let color of currentProject.variables.colors" [ngValue]="color.slug">{{color.label}}
                      </option>
                    </select>
                  </td>
                  <td>
                    <select [(ngModel)]="button.styles.borderColor.hover"
                      [ngStyle]="{'border-right' :'10px solid '+ colorSlugs[button.styles.borderColor.hover]}">
                      {{ colorSlugs[button.styles.borderColor.hover] }}
                      <option *ngFor="let color of currentProject.variables.colors" [ngValue]="color.slug">{{color.label}}
                      </option>
                    </select>
                  </td>
                  <td>
                    <select [(ngModel)]="button.styles.borderColor.disabled"
                      [ngStyle]="{'border-right' :'10px solid '+ colorSlugs[button.styles.borderColor.disabled]}">
                      {{ colorSlugs[button.styles.borderColor.disabled] }}
                      <option *ngFor="let color of currentProject.variables.colors" [ngValue]="color.slug">{{color.label}}
                      </option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>
                    Border Width
                  </td>
                  <td>
                    <div class="sizeholder">
                      <input type="number" [(ngModel)]="button.styles.borderWidth.base">
                      px
                    </div>
                  </td>
                  <td>
                    <div class="sizeholder">
                      <input type="number" [(ngModel)]="button.styles.borderWidth.hover"
                        placeholder="{{button.styles.borderWidth.base}}">
                      px
                    </div>
                  </td>
                  <td>
                    <div class="sizeholder">
                      <input type="number" [(ngModel)]="button.styles.borderWidth.disabled"
                        placeholder="{{button.styles.borderWidth.base}}">
                      px
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    Font Weight
                  </td>
                  <td>
                    <select [(ngModel)]="button.styles.fontWeight.base">
                      <option *ngFor="let weight of filteredWeights" [ngValue]="weight.value">{{ weight.label }}</option>
                    </select>
                  </td>
                  <td>
                    <select [(ngModel)]="button.styles.fontWeight.hover">
                      <option *ngFor="let weight of filteredWeights" [ngValue]="weight.value">{{ weight.label }}</option>
                    </select>
                  </td>
                  <td>
                    <select [(ngModel)]="button.styles.fontWeight.disabled">
                      <option *ngFor="let weight of filteredWeights" [ngValue]="weight.value">{{ weight.label }}</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>
                    Height
                  </td>
                  <td>
                    <div class="sizeholder">
                      <input type="number" [(ngModel)]="button.styles.height.base">
                      px
                    </div>
                  </td>
                  <td>
                    <div class="sizeholder">
                      <input type="number" [(ngModel)]="button.styles.height.hover"
                        placeholder="{{button.styles.height.base}}">
                      px
                    </div>
                  </td>
                  <td>
                    <div class="sizeholder">
                      <input type="number" [(ngModel)]="button.styles.height.disabled"
                        placeholder="{{button.styles.height.base}}">
                      px
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    Horizontal Padding
                  </td>
                  <td>
                    <div class="sizeholder">
                      <input type="number" [(ngModel)]="button.styles.paddingRight.base">
                      px
                    </div>
                  </td>
                  <td>
                    <div class="sizeholder">
                      <input type="number" [(ngModel)]="button.styles.paddingRight.hover"
                        placeholder="{{button.styles.paddingRight.base}}">
                      px
                    </div>
                  </td>
                  <td>
                    <div class="sizeholder">
                      <input type="number" [(ngModel)]="button.styles.paddingRight.disabled"
                        placeholder="{{button.styles.paddingRight.base}}">
                      px
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    Border Radius
                  </td>
                  <td>
                    <div class="sizeholder">
                      <input type="number" [(ngModel)]="button.styles.borderRadius.base">
                      {{button.styles.borderRadius.metric}}
                    </div>
                  </td>
                  <td>
                    <div class="sizeholder">
                      <input type="number" [(ngModel)]="button.styles.borderRadius.hover"
                        placeholder="{{button.styles.borderRadius.base}}">
                      {{button.styles.borderRadius.metric}}
                    </div>
                  </td>
                  <td>
                    <div class="sizeholder">
                      <input type="number" [(ngModel)]="button.styles.borderRadius.disabled"
                        placeholder="{{button.styles.borderRadius.base}}">
                      {{button.styles.borderRadius.metric}}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    Border Radius Unit
                  </td>
                  <td>
                    <select [(ngModel)]="button.styles.borderRadius.metric">
                      <option value="px">Pixels</option>
                      <option value="%">Percent</option>
                    </select>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="button-icon-wrap mtl">
            <div class="form-control">
              <div class="checkbox">
                  <label for=""
                      (click)="button.icon.active = !button.icon.active"
                      class="d-flex align-center cursor-pointer">
                      <input type="checkbox" class="mrxs" [(ngModel)]="button.icon.active" [ngModelOptions]="{standalone: true}" />
                      Button Icon
                  </label>
              </div>
            </div>
            <div class="button-icon-wrap d-flex align-start flex-wrap" *ngIf="button.icon.active">
              <table class="mtm mrl">
                <tbody>
                  <tr>
                    <td class="tdlabel">
                      Position
                    </td>
                    <td>
                      <select [(ngModel)]="button.icon.position" class="mbxs">
                        <option value="left">Left</option>
                        <option value="right">Right</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td class="tdlabel">
                      SVG
                    </td>
                    <td>
                      <textarea name="" id="" style="width: 100%" rows="7" cols="30" [(ngModel)]="button.icon.svg"></textarea>
                      <div [innerHTML]="button.icon.sanitized" #iconSizer></div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="mtm mrl">
                <thead>
                  <tr>
                    <th></th>
                    <th>Base</th>
                    <th>Hover</th>
                    <th>Disabled</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="tdlabel">
                      Padding
                    </td>
                    <td>
                      <div class="sizeholder">
                        <input type="number" [(ngModel)]="button.icon.styles?.padding.base">
                        px
                      </div>
                    </td>
                    <td>
                      <div class="sizeholder">
                        <input type="number" [(ngModel)]="button.icon.styles?.padding.hover"
                          placeholder="{{button.icon.styles?.padding.base}}">
                        px
                      </div>
                    </td>
                    <td>
                      <div class="sizeholder">
                        <input type="number" [(ngModel)]="button.icon.styles?.padding.disabled"
                          placeholder="{{button.icon.styles?.padding.base}}">
                        px
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="tdlabel">
                      Visibility
                    </td>
                    <td>
                      <select [(ngModel)]="button.icon.styles?.visibility.base">
                        <option value="visible" selected>Visible</option>
                        <option value="hidden">Hidden</option>
                      </select>
                    </td>
                    <td>
                      <select [(ngModel)]="button.icon.styles?.visibility.hover">
                        <option value="visible" selected>Visible</option>
                        <option value="hidden">Hidden</option>
                      </select>
                    </td>
                    <td>
                      <select [(ngModel)]="button.icon.styles?.visibility.disabled">
                        <option value="visible" selected>Visible</option>
                        <option value="hidden">Hidden</option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="col-12 mtl">
    <a class="btn" (click)="addButton()">+ Add Button</a>
  </div>
</div>