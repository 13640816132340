import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  inputs = {
    email: '',
    password: '',
  };
  loginError = '';
  passwordMessage = '';
  passwordError = '';

  constructor(    
    private userService: UserService,
    private router: Router,
    private zone: NgZone,
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore
  ) { }

  ngOnInit(): void {
    let node = document.createElement('script');
        node.src = 'https://accounts.google.com/gsi/client';
        node.type = 'text/javascript';
        node.async = true;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async googleLogin() {
    await this.userService.googleLogin();
  }

  login() {
    let {email, password} = this.inputs;
    this.loginError = '';
    this.userService.loginWithEmailAndPassword(email, password).subscribe(
      (credential) => {
        this.zone.run(async () => {
          if (credential) {
            this.completeLogin(credential);
          }
        });
      },
      (err) => {
        console.error(err);
        this.loginError = err;
      }
    );
  }


  completeLogin(credential) {
    this.subscriptions.add(
      this.afs
        .doc(`users/${credential.user.uid}`)
        .valueChanges()
        .subscribe((user: any) => {
          if (user) {
            this.router.navigateByUrl('/');
          } else {
            this.loginError =
              'There was an error retreiving your account.  Please try again or contact an administrator.';
          }
        })
    );
  }

}
