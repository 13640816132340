import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UserSubscriptionMixin } from 'src/app/mixins/subscriptions.mixin';
import { ClientService } from 'src/app/services/client.service';
import { IconsService } from 'src/app/services/icons.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent extends UserSubscriptionMixin implements OnInit {
  sizes = ['Base','XXXL','XXL','XL','L','M','S','XS','XXS','XXXS'];
  tags = ['body','h1','h2','h3','h4','h5','h6','small'];
  weights = []; 
  icons = [];
  logos = [];
  logoStyles: any = ''
  
  constructor(
    userService: UserService,
    navService: NavigationService,
    projectService: ProjectService,
    clientService: ClientService,
    private iconsService: IconsService,
    private sanitizer: DomSanitizer,
  ) {
    super(userService, navService, projectService,clientService);
  }
  colorFormat(color: String) {
    return color.toLowerCase().replace(/\W+/g, '-');
  }
  ngOnInit(): void {
    this.currentProjectLoaded.then(() => {
      console.log(this.currentProject)
      if(this.currentProject?.variables?.typography?.weights.length > 0)
        this.weights = this.currentProject.variables.typography.weights.slice().reverse();

      if (!this.currentProject.variables && !this.currentProject.variables.length) {
        this.currentProject.variables = {colors: [], typography: []};
      }

      if (
        'components' in this.currentProject && 
        'icons' in this.currentProject.components &&  
        this.iconsService.sanitizedStyleTag.getValue()
      ) {
        this.icons = this.iconsService.convertCssToIcons(this.currentProject.components.icons.css);
      }
      if (
        'components' in this.currentProject && 
        'logos' in this.currentProject.components
      ) {
        this.logos = this.currentProject.components.logos.map(logo => {
          logo.slug = this.toKebabCase(logo.name)
          return logo;
        })
        const styles = `
          <style>
            ${this.logos.map(logo => {
              let encodedDark, encodedLight, pngDark, pngLight;
              if ('darkBG' in logo.types) {
                if ('svg' in logo.types.darkBG) encodedLight = window.btoa(logo.types.darkBG.svg)
                if ('png' in logo.types.darkBG) pngLight = logo.types.darkBG.png
              }
              if ('lightBG' in logo.types) {
                if ('svg' in logo.types.lightBG) encodedDark = window.btoa(logo.types.lightBG.svg)
                if ('png' in logo.types.lightBG) pngDark = logo.types.lightBG.png
              }
              return `
              ${encodedDark ? `
              .logo-${logo.slug}-dark-svg:before {\n
                content: url('data:image/svg+xml;base64,${encodedDark}');\n
              }\n` : ''}
              ${pngDark ? `
              .logo-${logo.slug}-dark-png:before {\n
                content: url('${pngDark}');\n
              }\n` : ''}
              ${encodedLight ? `
              .logo-${logo.slug}-light-svg:before {\n
                content: url('data:image/svg+xml;base64,${encodedLight}');\n
              }\n` : ''}
              ${pngLight ? `
              .logo-${logo.slug}-light-png:before {\n
                content: url('${pngLight}')\n;
              }\n` : ''}
              `
            }).join('')}
          </style>
        `
        this.logoStyles = this.sanitizer.bypassSecurityTrustHtml(styles)
        // let encodedSVG = Buffer.from(button.icon.svg).toString('base64')
        // url('data:image/svg+xml;base64,${encodedSVG}'),
      }
    })
  }
  copyLogo(logoSlug, type, format) {
    const {name, clientName} = this.currentProject;
    const logoStr = `<span aria-label="${clientName} ${name} ${logoSlug} logo ${type} ${format}" role="img" class="logo-${logoSlug}-${type}-${format}"></span>`
    this.copyStr(logoStr)
  }
  copyStr(str) {
    const selBox = document.createElement('textarea');
    selBox.className = 'pos-fixed pos-top pos-left opacity-0'
    selBox.value = str;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.showToast(str);
  }

  showToast(str) {
    const toast = document.createElement('div');
    toast.className = "toast pos-fixed pos-bottom pos-right pvxxs phxs bg-accent-taupe txt-c mbs mrs"
    toast.innerText = `Successfully copied '${str}'`
    document.querySelector('body').appendChild(toast);
    setTimeout(() => toast.remove(), 2000)
  }

  toKebabCase(str) {
    return str && str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.toLowerCase())
      .join('-');
  }

}
 