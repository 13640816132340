<div class="modal-btn-wrap" *ngIf="showButton">
    <button class="btn" (click)="toggleModal()">
        <!-- in parent container use <ng-container class="open-inner">Button Text</ng-container> -->
        <ng-content select=".open-inner"></ng-content>
    </button>
</div>
<div class="modal-bg pos-fixed full-height full-width pos-center bg-neutral-darker cursor-pointer opacity-3" [ngClass]="{'hide-fully': !modalToggled}" (click)="toggleModal()"></div>
<div class="modal-wrap" [ngClass]="{'hide-fully': !modalToggled}">
    <div class="modal-inner pos-relative pvs phm bg-neutral-brighter">
        <div class="close-icon pos-absolute pos-top pos-right mtxs mrxs">
            <styles-icon name="close" color="neutralDarker" class="cursor-pointer" (click)="toggleModal()"></styles-icon>
        </div>
        <ng-content></ng-content>
    </div>
</div>
