import { Component, OnInit, ViewChild } from '@angular/core';
import { UserSubscriptionMixin } from 'src/app/mixins/subscriptions.mixin';
import { ClientService } from 'src/app/services/client.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';
import { observable, computed } from 'mobx-angular';
import { makeObservable } from 'mobx';
import { LoadingService } from 'src/app/services/loading.service';
import { ButtonModel } from 'src/app/models/button.model'
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-button',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss']
})
export class ButtonsComponent extends UserSubscriptionMixin implements OnInit {
  @ViewChild('iconSizer') iconSizer;
  buttonStateForm: FormGroup;
  colorSlugs = {};
  filteredWeights = [];
  // button = {clicked: false};
  get buttonStyles() {
    if (this.currentProject && this.currentProject.components && this.currentProject.components.buttons) {
      let btns = this.currentProject.components.buttons.map(button => {
        let b = {base: {}, hover: {}, disabled: {}}
        for (const key of Object.keys(b)) {
          b[key]['transition'] = `all ${button.transition}s ease-in-out`;
          b[key]['height'] = `${button.styles.height[key]}px`;
          b[key]['border'] = `${button.styles.borderWidth[key]}px solid ${this.colorSlugs[button.styles.borderColor[key]]}`;
          b[key]['background-color'] = this.colorSlugs[button.styles.background[key]];
          b[key]['color'] = this.colorSlugs[button.styles.color[key]];
          b[key]['padding'] = `0px ${button.styles.paddingRight[key]}px`;
          b[key]['border-radius'] = button.styles.borderRadius[key] + button.styles.borderRadius.metric;
          b[key]['font-weight'] = button.styles.fontWeight[key];
        }
        return b
      })
      return btns;
    } 
    return ''
  }
  get iconSize() {
    let size;
    if (this.iconSizer && this.iconSizer.nativeElement.querySelector('svg')) {
      size = this.iconSizer.nativeElement.querySelector('svg').clientWidth
    }
    return size
  }
  get iconStyles() {
    if (this.currentProject && this.currentProject.components && this.currentProject.components.buttons) {
      let btns = this.currentProject.components.buttons.map(button => {
        if (button.icon) {
          let b = {base: {}, hover: {}, disabled: {}}
          for (const key of Object.keys(b)) {
            if (button.icon.position === 'right') {
              b[key]['padding-left'] = `${button.icon.styles?.padding[key]}px`;
            } else {
              b[key]['padding-right'] = `${button.icon.styles?.padding[key]}px`;
            }
            
            b[key]['transition'] = `all ${button.transition}s ease-in-out`;

            if (button.icon.styles?.visibility[key] === 'visible') {
              let padding = button.icon.styles?.padding[key] ? parseInt(button.icon.styles?.padding[key]) : 0
              b[key]['width'] = `${padding + this.iconSize }px`;
              b[key]['opacity'] = `1`;
            } else {
              b[key]['width'] = `0px`
              b[key]['opacity'] = `0`;
            }

          }
          return b
        }
      })
      return btns;
    } 
    return ''
  }

  constructor(
    userService: UserService,
    navService: NavigationService,
    projectService: ProjectService,
    clientService: ClientService,
    private loadingService: LoadingService,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder
  ) {
    super(userService, navService, projectService,clientService);
    
    this.buttonStateForm = fb.group({
      buttonState: ['base']
    });
    
  }

  ngOnInit(): void {
    this.currentProjectLoaded.then(() => {
      //alert();
      console.log(this.currentProject);
      
      this.setColorSlugs();
      this.filteredWeights = this.currentProject.variables.typography.weights.filter((w) => !w.hidden);
      if(!this.currentProject.components) {
        this.currentProject.components = { buttons: [Object.assign({}, new ButtonModel)] };
        this.currentProject.components.buttons[0].name = "Default";
        this.updateProject();
      }

      this.currentProject.components.buttons.forEach(button => {
        if (!button.icon) {
          button.icon = {
            active: false,
            position: 'right',
            svg: '',
            styles: {
              padding: {base: null, hover: null, disabled: null},
              visibility: {base: null, hover: null, disabled: null}
            }
          }
        } else {
          button.icon.sanitized = this.sanitizer.bypassSecurityTrustHtml(button.icon.svg);
        }
        
        if (!button.icon.styles) {
          button.icon.styles = {
            padding: {base: null, hover: null, disabled: null},
            visibility: {base: 'visible', hover: 'visible', disabled: 'visible'}
          }
        }
        Object.keys(button.styles).forEach((style:any) => {
          if (!button.styles[style].disabled) button.styles[style].disabled = '';
        })
      })

    });
  }

  updateProject() {
    this.loadingService.startLoading();
    this.currentProject.components.buttons.forEach(b => {
      b.styles.paddingLeft = b.styles.paddingRight;
      if (b.icon.sanitized) delete b.icon.sanitized;
    })
    this.projectService.updateProject(this.currentProject.id, this.currentProject)
    .then(() => {
      this.loadingService.stopLoading()
      this.currentProject.components.buttons.forEach(b => {
        b.icon.sanitized = this.sanitizer.bypassSecurityTrustHtml(b.icon.svg);
      })
    });
  }
  setColorSlugs() {
    for (let color of this.currentProject.variables.colors) {
      this.colorSlugs[color.slug] = color.hex;
    }
    this.colorSlugs['transparent'] = 'transparent';
  }
  addButton() {
    if(!this.currentProject.components.buttons) 
      this.currentProject.components.buttons = [];
    this.currentProject.components.buttons.push(Object.assign({}, new ButtonModel));
  }
  duplicateButton(e,button,i) {
    e.stopPropagation();
    let newButton = {...button}
    newButton.name = newButton.name + ' (copy)';
    newButton.toggled = true;
    button.toggled = false;

    // deep copy nested objects
    let styles = {};
    Object.keys(button.styles).forEach(k => {
      styles[k] = {...button.styles[k]};
    })
    newButton.styles = {...styles};

    this.currentProject.components.buttons.splice(i + 1, 0, newButton);
  }
  toggleButton(index) {
    this.currentProject.components.buttons.forEach((b,i) => {
      if (i === index) b.toggled = !b.toggled
    });
    this.projectService.updateProject(this.currentProject.id, this.currentProject)
  }
  deleteButton(i) {
    this.currentProject.components.buttons.splice(i,1);
  }
  toKebabCase(str) {
    return str && str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.toLowerCase())
      .join('-');
  }
}
