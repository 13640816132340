<div class="wordpress-tips container pbxxxl">
  <div class="row mbm">
    <div class="col-12">
      <h1 class="mbxl">Wordpress Tips</h1>
    </div>
  </div>

  <!-- basics -->
  <div id="basics" class="row guide-section" #guideSection>
    <div class="col-12">
      <h2>Page Builder Basics</h2>
      <p>The niftic page builder is based on Rows, Columns, and Modules. The row & column grid system is based on Bootstrap 4.</p>
      <ul>
        <li><strong>Rows</strong> house discrete sections of content. A row can contain multiple columns & modules.</li>
        <li><strong>Columns</strong> are variable width containers for content. A row can house 12 columns – so a full-width column is 12/12 or 100% width. Columns can be adjusted to be different widths on different screen sizes – for example, a column may be 100% width on mobile, but only 50% width on desktop.</li>
        <li><strong>Modules</strong> are reusable blocks of content or plugins. Modules are different for every site, but common examples include ‘Text’, ‘Image’, ‘Team List’, ‘Image Gallery’, etc.</li>
      </ul>
    </div>
  </div>

  
  <!-- new-pages -->
  <div id="new-pages" class="row guide-section" #guideSection>
    <div class="col-12">
      <h2>Creating New Pages</h2>
      <p>Follow these steps to create a new page builder page:</p>
      <ol>
        <li>In the WordPress sidebar, click Pages > Add New</li>
        <li>Add the title, this will also generate the page slug & URL.</li>
        <li>Under Page Attributes change the Template from ‘Default template’ to ‘Page Builder’. (If you can’t see the Page Attributes box on the page, click the Screen Options button at the top of the page & make sure that the Page Attributes checkbox is selected)</li>
      </ol>
    </div>
  </div>

  <!-- row-settings -->
  <div id="row-settings" class="row guide-section" #guideSection>
    <div class="col-12">
      <h2>Row Settings</h2>
      <p>You can open the row settings by pressing the “Row Settings” button on the row you would like to modify, this will expand an accordion filled with a collection of different options</p>
      <p>The first tab has two settings: the first is the Full Width toggle that will set the row to ignore the default container padding on the page and attempt to fit the entire width to 100% of the page, the second is a content alignment option that allows you to choose between different advanced alignment options for the columns inside the row</p>
      <p>The second tab will allow you to adjust Margin and Paddings for the entire row, please check the Column Settings section for a useful diagram on the differences between these two options.</p>
      <p>The fourth tab allows you to add in a background image, color, as well as a text color.</p>
    </div>
  </div>

  <!-- new-columns -->
  <div id="new-columns" class="row guide-section" #guideSection>
    <div class="col-12">
      <h2>Creating New Columns</h2>
      <p>To add a new column to a row click the ‘+’ button located inside the row and then select your column type from the dropdown</p>
      <p>Once your column is added, a popup should appear where you can edit the content- this editor will change based on each column type.</p>
      <p>After entering or modifying the content of a column you can press the “X” button on the top of the module to return to the page editor. Once you’ve returned to the page editor you can either hit the ‘Update/Publish’ button in the sidebar to save the page or continue to add more content.</p>
    </div>
  </div>

  <!-- column-settings -->
  <div id="column-settings" class="row guide-section" #guideSection>
    <div class="col-12">
      <h2>Column Settings</h2>
      <p>Inside each column editing modal there is a settings area hidden inside an accordion, you can expand it by clicking the section that reads ‘Column Settings’</p>
      <p>The first tab inside the settings menu will allow you to set the column’s width and offset (space to the left of the column) on different viewport sizes</p>
      <p>For reference, the viewports are defined based on how wide the current visitors screen is;</p>
      <p>The second tab will allow you to set the margin and padding for the column (though please keep in mind that left margins may interfere with your offset settings from the first tab) </p>
      <p>For reference on the differences between Margin and Padding please refer to this illustration; </p>
      <p>The third tab has options to set additional CSS classes as well as set Background and Text colors for the column, please note that some of the column modules are specifically built out with these set already, so changing them inside this section may not change anything visually</p>
    </div>
  </div>


</div>