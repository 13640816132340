import { Component, OnInit } from '@angular/core';
import { UserSubscriptionMixin } from 'src/app/mixins/subscriptions.mixin';
import { ClientService } from 'src/app/services/client.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-borders',
  templateUrl: './borders.component.html',
  styleUrls: ['./borders.component.scss']
})
export class BordersComponent extends UserSubscriptionMixin implements OnInit {
  sizes = ['Base','XXXL','XXL','XL','L','M','S','XS','XXS','XXXS'];
  tags = ['body','h1','h2','h3','h4','h5','h6','small'];

  constructor(
    userService: UserService,
    navService: NavigationService,
    projectService: ProjectService,
    clientService: ClientService,
  ) {
    super(userService, navService, projectService,clientService);
  }

  ngOnInit(): void {
    // this.projectService.updateProject(this.currentProjectId, this.currentProject);
    this.currentProjectLoaded.then(() => {
      this.currentProject.variables.borders.widths = [
        {value: 0, hidden: true, label: 'xxxl'},
        {value: 0, hidden: true, label: 'xxl'},
        {value: 0, hidden: true, label: 'xl'},
        {value: 0, hidden: true, label: 'l'},
        {value: 0, hidden: true, label: 'm'},
        {value: 0, hidden: false, label: 'base'},
        {value: 0, hidden: true, label: 's'},
        {value: 0, hidden: true, label: 'xs'},
        {value: 0, hidden: true, label: 'xxs'},
        {value: 0, hidden: true, label: 'xxxs'},
      ]
      this.currentProject.variables.borders.radius = [
        {value: 0, hidden: true, label: 'xxxl'},
        {value: 0, hidden: true, label: 'xxl'},
        {value: 0, hidden: true, label: 'xl'},
        {value: 0, hidden: true, label: 'l'},
        {value: 0, hidden: true, label: 'm'},
        {value: 0, hidden: false, label: 'base'},
        {value: 0, hidden: true, label: 's'},
        {value: 0, hidden: true, label: 'xs'},
        {value: 0, hidden: true, label: 'xxs'},
        {value: 0, hidden: true, label: 'xxxs'},
      ]
      this.updateProject();
    })
  }

  updateProject() {
    this.projectService.updateProject(this.currentProject.id, this.currentProject);
  }


  toggleWidth(i) {
    this.currentProject.variables.borders.widths[i].hidden = !this.currentProject.variables.borders.widths[i].hidden
    this.updateProject();
  }

  toggleRadius(i) {
    this.currentProject.variables.borders.radius[i].hidden = !this.currentProject.variables.borders.radius[i].hidden
    this.updateProject();
  }

}

