<div class="row">
  <div class="col-12">
    
    <div class="dropzone"
         dropzone
         (hovered)="toggleHover($event)"
         (dropped)="onDrop($event)"
         [class.hovering]="isHovering">
    
         <p>Drop file(s) here</p>
    </div>
  </div>
  <div *ngFor="let file of files; let i = index" style="height: 0; visibility: hidden;">
    <upload-task [file]="file" [index]="i" (completed)="handleCompletedFile($event)" [projectId]="projectId"></upload-task>
  </div>
  <!-- <div class="col-12 col-lg-6">
    <div class="uploads" *ngIf="files && files.length">
      <div class="font-bold">Uploaded files</div>
    </div>
  </div> -->
</div>