import { Component, OnInit } from '@angular/core';
import { UserSubscriptionMixin } from 'src/app/mixins/subscriptions.mixin';
import { ClientService } from 'src/app/services/client.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-spacing',
  templateUrl: './spacing.component.html',
  styleUrls: ['./spacing.component.scss']
})
export class SpacingComponent extends UserSubscriptionMixin implements OnInit {
  sizes = ['Base','XXXL','XXL','XL','L','M','S','XS','XXS','XXXS'];
  tags = ['body','h1','h2','h3','h4','h5','h6','small'];

  constructor(
    userService: UserService,
    navService: NavigationService,
    projectService: ProjectService,
    clientService: ClientService,
  ) {
    super(userService, navService, projectService,clientService);
  }

  ngOnInit(): void {
    // this.projectService.updateProject(this.currentProjectId, this.currentProject);

    this.currentProjectLoaded.then(() => {
      console.log(this.currentProject);
      if (!this.currentProject.variables.spacing || this.currentProject.variables.spacing.length === 0) {
        this.currentProject.variables.spacing = {
          xxxs: {mobile:10, hidden: false, desktop: 10},
          xxs: {desktop:12, hidden: false, mobile: 12},
          xs: {desktop:14, mobile: 14, hidden: false},
          s: {hidden:false, desktop: 16, mobile: 16},
          m: {desktop:18, mobile: 18, hidden: false},
          l: {hidden:false, desktop: 24, mobile: 24},
          xl: {mobile:36, hidden: false, desktop: 36},
          xxl: {desktop:48, hidden: false, mobile: 48},
          xxxl: {hidden:false, desktop: 64, mobile: 54},
          base: {mobile:16, hidden: false, desktop: 16}
        } 
        console.log(this.currentProject);
        this.updateProject();
      }
    })
  }

  updateProject() {
    this.projectService.updateProject(this.currentProject.id, this.currentProject);
  }

}
