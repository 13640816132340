import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { DomSanitizer } from '@angular/platform-browser';
import { UserSubscriptionMixin } from 'src/app/mixins/subscriptions.mixin';
import { LogoModel } from 'src/app/models/logo.model';
import { ClientService } from 'src/app/services/client.service';
import { IconsService } from 'src/app/services/icons.service';
import { LoadingService } from 'src/app/services/loading.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-logos',
  templateUrl: './logos.component.html',
  styleUrls: ['./logos.component.scss']
})
export class LogosComponent extends UserSubscriptionMixin implements OnInit {
  emptyLogo = {
    toggled: false,
    label: '',
    types: {
      lightBG: {
        active: true,
        svg: '',
        png: ''
      },
      darkBG: {
        active: true,
        svg: '',
        png: ''
      }
    },
  }
  constructor(
    userService: UserService,
    navService: NavigationService,
    projectService: ProjectService,
    clientService: ClientService,
    private loadingService: LoadingService,
    private storage: AngularFireStorage,
    private sanitizer: DomSanitizer,
    private iconsService: IconsService
  ) {
    super(userService, navService, projectService,clientService);
    
  }

  ngOnInit(): void {
    this.currentProjectLoaded.then(() => {
      //alert();
      console.log(this.currentProject);

      if (!this.currentProject.components?.logos?.length) {
        this.addLogo();
      } else {
        this.currentProject.components.logos.forEach(logo => {
          logo.types.lightBG.sanitized = this.sanitizer.bypassSecurityTrustHtml(logo.types.lightBG.svg);
          logo.types.darkBG.sanitized = this.sanitizer.bypassSecurityTrustHtml(logo.types.darkBG.svg);
        })  
      }

    });
  }

  updateProject() {
    this.loadingService.startLoading();
    this.currentProject.components.logos.forEach(logo => {
      if (logo.types.lightBG.sanitized) delete logo.types.lightBG.sanitized;
      if (logo.types.darkBG.sanitized) delete logo.types.darkBG.sanitized;
    })
    this.projectService.updateProject(this.currentProject.id, this.currentProject)
    .then(() => {
      this.loadingService.stopLoading()
      this.currentProject.components.logos.forEach(logo => {
        logo.types.lightBG.sanitized = this.sanitizer.bypassSecurityTrustHtml(logo.types.lightBG.svg);
        logo.types.darkBG.sanitized = this.sanitizer.bypassSecurityTrustHtml(logo.types.darkBG.svg);
      })
    });
  }
  addLogo() {
    if(!this.currentProject.components.logos) 
      this.currentProject.components.logos = [];
    this.currentProject.components.logos.push(Object.assign({}, new LogoModel));
  }
  duplicateLogo(e,logo,i) {
    e.stopPropagation();
    let newLogo = {...logo}
    newLogo.name = newLogo.name + ' (copy)';
    newLogo.toggled = true;
    logo.toggled = false;

    // deep copy nested objects
    let types = {};
    Object.keys(logo.types).forEach(k => {
      types[k] = {...logo.types[k]};
    })
    newLogo.types = {...types};

    this.currentProject.components.logos.splice(i + 1, 0, newLogo);
  }
  toggleLogo(index) {
    this.currentProject.components.logos.forEach((b,i) => {
      if (i === index) b.toggled = !b.toggled
    });
    this.projectService.updateProject(this.currentProject.id, this.currentProject)
  }
  deleteLogo(i) {
    this.currentProject.components.logos.splice(i,1);
  }
  uploadFile(event, logo, type) {
    const file = event.target.files[0];
    const filePath = `${this.toKebabCase(this.currentProject.name)}-${this.toKebabCase(logo.name)}-logo-${type}.png`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    task.snapshotChanges().pipe(
      finalize(() => {
        // this[type] = fileRef.getDownloadURL().toPromise().then(res => res)
        fileRef.getDownloadURL().toPromise().then(res => {
          if (res) {
            logo.types[type + 'BG'].png = res;
            // this.currentProject.components.icons.files[type] = this[type];
            this.updateProject();
          }
        })
      })
    ).subscribe()
  }
  resetFile(logo, type) {
    logo.types[type + 'BG'].png = ''
    // this.currentProject.components.icons.files[type] = this[type];
    // this.updateProject();
  }
  onSvgChange(event, logo, type) {
    logo.types[type + 'BG'].svg = event;
    this.currentProject.components.logos.forEach(logo => {
      logo.types.lightBG.sanitized = this.sanitizer.bypassSecurityTrustHtml(logo.types.lightBG.svg);
      logo.types.darkBG.sanitized = this.sanitizer.bypassSecurityTrustHtml(logo.types.darkBG.svg);
    })  
  }
  toKebabCase(str) {
    return str && str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.toLowerCase())
      .join('-');
  }

}
