import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserSubscriptionMixin } from 'src/app/mixins/subscriptions.mixin';
import { NavigationService } from 'src/app/services/navigation.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import firebase from 'firebase/app'
import 'firebase/functions'
import { LoadingService } from 'src/app/services/loading.service';
import { ClientService } from 'src/app/services/client.service';

const fb = firebase;

@Component({
  selector: 'app-stylesheet',
  templateUrl: './stylesheet.component.html',
  styleUrls: ['./stylesheet.component.scss']
})
export class StylesheetComponent extends UserSubscriptionMixin implements OnInit {
  @ViewChild('cssTextarea') cssTextarea: ElementRef;
  @ViewChild('colorsJsonEl') colorsJsonEl: ElementRef;
  @ViewChild('variablesEl') variablesEl: ElementRef;
  css = 'Compiling CSS...';
  variables = 'Fetching variables';
  colorsJSON = '';
  loading = true;
  copying = false;
  copyingJSON = false;
  copyingVariables = false;

  constructor(
    userService: UserService,
    navService: NavigationService,
    projectService: ProjectService,
    clientService: ClientService,
    private functions: AngularFireFunctions,
  ) {
    super(userService, navService, projectService, clientService);
  }

  ngOnInit(): void {
    this.currentProjectLoaded.then(() => {
      this.generateStylesheet();
      this.generateColorsJSON();
    })
  }

  generateStylesheet() {
    delete this.currentProject.css;
    const compileScss = fb.app().functions('us-central1').httpsCallable('compileScssOnCall');
    console.log({ project: this.currentProject })
    compileScss({ project: this.currentProject })
      .then((result: any) => {
        console.log(result);
        this.css = result.data.css;
        this.variables = result.data.varString;
        this.currentProject.css = this.css;
        this.projectService.updateProject(this.currentProject.id, this.currentProject);
        this.loading = false;
      }, (error) => {
        console.error(error);
      });
  }

  generateColorsJSON() {
    this.colorsJSON = `{
\t"colors": [
${this.currentProject.variables.colors.map((c, i, arr) => {
      let comma = i == arr.length - 1 ? '' : ',';
      return `\t\t{
      \t\t\t"name": "${c.label}",
      \t\t\t"color": "${c.hex}",
      \t\t\t"slug": "${c.slug}"
    \t\t}${comma}`;
    }).join('\n')
      }
\t]
}`
  }

  copyCss() {
    this.copying = true;
    this.cssTextarea.nativeElement.select();
    document.execCommand('copy');
    setTimeout(() => {
      this.copying = false;
    }, 2000)
  }
  copyColorsJSON() {
    this.copyingJSON = true;
    this.colorsJsonEl.nativeElement.select();
    document.execCommand('copy');
    setTimeout(() => {
      this.copyingJSON = false;
    }, 2000)
  }
  copyVariables() {
    this.copyingVariables = true;
    this.variablesEl.nativeElement.select();
    document.execCommand('copy');
    setTimeout(() => {
      this.copyingVariables = false;
    }, 2000)
  }

}
