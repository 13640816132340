<div id="icon-css" *ngIf="iconsStyleTag" [innerHTML]="iconsStyleTag"></div>
<div id="subnav-css" [innerHTML]="subnavStyleTag"></div>
<div class="guide" *ngIf="currentProject">
  <header class="sidebar bg-neutral-bright pam">
    <div class="logo" *ngIf="sanitizedLogo" [innerHTML]="sanitizedLogo"></div>
    <ul class="menu">
      <li class="mbs" [ngClass]="{'guide-active': currentRoute == ''}" id="dashboard-nav">
        <a [routerLink]="[ navBase ]" class="cursor-pointer">
          Dashboard
        </a>
      </li>
      <li class="mbs" [ngClass]="{'guide-active': currentRoute == 'wordpress-tips'}" id="wordpress-tips-nav" *ngIf="currentProject.clientPortal.dashboard.pages.wordpressTips">
        <a [routerLink]="[ navBase + 'wordpress-tips' ]" class="cursor-pointer">
          Wordpress Tips
        </a>
        <ul class="submenu plxs"></ul>
      </li>
      <li class="mbs" [ngClass]="{'guide-active': currentRoute == 'brand-resources'}" id="brand-resources-nav" *ngIf="currentProject.clientPortal.dashboard.pages.brandResources">
        <a [routerLink]="[ navBase + 'brand-resources' ]" class="cursor-pointer">
          Brand Resources
        </a>
        <ul class="submenu plxs"></ul>
      </li>
      <li class="mbs" [ngClass]="{'guide-active': currentRoute == 'guide-links'}" id="guide-links-nav" *ngIf="currentProject.clientPortal.dashboard.pages.links">
        <a [routerLink]="[ navBase + 'guide-links' ]" class="cursor-pointer">
          Links
        </a>
        <ul class="submenu plxs"></ul>
      </li>
    </ul>
  </header>
  <main id="page" class="d-flex flex-col align-stretch mtxl pam">
    <router-outlet></router-outlet>
  </main>
</div>

<div class="guide no-project pvxl" *ngIf="!currentProject" style="height: 100vh">
  <div class="container">
    <div class="row">
      <div class="col-12 txt-c">

        <h2 class="mbm">Find your Niftic guide</h2>
        <h5 class="mbxs">Please enter your site's url below</h5>
        <div class="form-wrap d-flex flex-col">
          <input type="text" [(ngModel)]="siteInput" class="mrxxs">
          <a (click)="searchForProject()">Search</a>
          <div class="error-message" *ngIf="showErrorMessage" style="color: #f44336">No site found. Please make sure your URL was entered correctly.</div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="loading-overlay" *ngIf="showLoadingOverlay"></div>