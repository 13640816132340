<!-- <div>
    <ngx-monaco-editor [options]="editorOptions" [(ngModel)]="code"></ngx-monaco-editor>
</div> -->

<div class="code-blocks" *ngIf="currentProject">

    <div class="page-heading d-flex align-center justify-between mbs col-12">
        <h1 class="mrm">Code Blocks</h1>
        <a class="btn mlm" (click)="updateProject()">Save changes</a>
    </div>
    <div class="sections">
        <div class="col-12" *ngIf="currentProject.codeBlocks"  dragula="codeBlocks" [(dragulaModel)]="currentProject.codeBlocks">
            <div class="accordion-control" [ngClass]="{'toggled': section.toggled}"
                *ngFor="let section of currentProject.codeBlocks; let sectionInd = index" [attr.id]="toKebabCase(section.name)">
                <div class="accordion-heading cursor-pointer d-flex align-center justify-between"
                    [ngClass]="{'mbs': section.toggled}" 
                    (click)="toggleSection(sectionInd)" 
                    (mouseover)="heading.hovered = true"
                    (mouseleave)="heading.hovered = false" #heading>
                    <div class="left d-flex align-center justify-start">
                        <h3>{{section.name}}</h3>
                    </div>
                    <div class="right d-flex align-center justify-end">
                        <styles-icon class="cursor-pointer" *ngIf="heading.hovered" name="close" color="systemError" (click)="deleteSection(sectionInd)"></styles-icon>
                        <styles-icon class="cursor-pointer" *ngIf="heading.hovered" name="edit" color="neutralDarker" (click)="toggleSectionModal('edit', sectionInd)"></styles-icon>
                        <styles-icon name="caretDown" color="neutral-darker" [ngStyle]="{'transform': !section.toggled ? 'none' : 'rotate(180deg)'}"></styles-icon>
                    </div>
                </div>
                <div class="accordion-body" *ngIf="section.toggled">
                    <div class="section-description mbm" [innerHTML]="section.description"></div>
                    <div class="block mbl" *ngFor="let block of section.blocks; let blockInd = index">
                        <h4 class="block-name mbxxs d-flex justify-between">
                            <span>{{block.name}}</span>
                            <div class="options">
                                <span [ngClass]="{'hide-fully': !block.copied}" class="color-system-info d-inline-flex align-center mrxxs">
                                    <styles-icon name="check" color="systemInfo" class="mrxxxs"></styles-icon>
                                    <span class="type-xxs">copied!</span>
                                </span>
                                <styles-icon class="cursor-pointer" name="copy" color="neutralDarker" (click)="copyBlock(block)" [ngClass]="{'hide-fully': block.copied}"></styles-icon>
                                <styles-icon class="cursor-pointer" name="edit" color="neutralDarker" (click)="toggleBlockModal('edit', sectionInd, blockInd)"></styles-icon>
                                <styles-icon class="cursor-pointer" name="close" color="systemError" (click)="deleteBlock(sectionInd, blockInd)"></styles-icon>
                            </div>
                        </h4>
                        <div class="block-content mbxxs">
                            <ngx-monaco-editor 
                                [options]="{theme: 'vs-dark', language: currentProject.codeBlocks[sectionInd].blocks[blockInd].language, readOnly: true}"
                                [(ngModel)]="block.content"
                            ></ngx-monaco-editor>
                            
                        </div>
                        <div  class="block-description" [innerHTML]="block.description"></div>
                    </div>
                    <a (click)="toggleBlockModal('new', sectionInd)" class="mtl">+ Add code block</a>
                    <div>
                        <button class="btn mtl" (click)="toggleMinifyModal(section)">Bulk Copy</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 mtl">
        <a class="btn" (click)="toggleSectionModal('new')">+ Add Section</a>
    </div>

    <!-- modals -->

    <!-- edit section -->
    <styles-modal #editSectionModal [showButton]="false">
        <ng-container *ngIf="editSection !== null">
            <h2 class="mbs">{{editSection.newSection ? 'Add' : 'Edit'}} Section</h2>
            <div class="form-control">
                <label>Section Name</label>
                <input class="form-control" type="text" name="section-name" [(ngModel)]="editSection.name" required>
            </div>
            <div class="form-control">
                <label>Section Description</label>
            </div>
            <div class="mbs prs">
                <quill-editor
                    [bounds]="'self'"
                    [modules]="quillModules"
                    [placeholder]="'Short Description'"
                    [customToolbarPosition]="'top'"
                    [(ngModel)]="editSection.description"
                >
                </quill-editor>
            </div>
            <div class="txt-r">
                <button class="btn" (click)="saveSection()">Save Section</button>
            </div>
        </ng-container>
    </styles-modal>

    <!-- edit block -->
    <styles-modal #editBlockModal [showButton]="false">
        <ng-container *ngIf="editBlock !== null">
            <h2 class="mbs">{{editBlock.newBlock ? 'Add' : 'Edit'}} Block</h2>
            <div class="form-control">
                <label>Block Name</label>
                <input class="form-control" type="text" name="section-name" [(ngModel)]="editBlock.name" required>
            </div>
            <div class="form-control">
                <label>Block Language</label>
                <select name="language" id="language" [(ngModel)]="editBlock.language">
                    <option value="javascript">JavaScript</option>
                    <option value="html">HTML</option>
                    <option value="css">CSS</option>
                </select>
            </div>
            <div class="form-control">
                <label>Code Block</label>
            </div>
            <div class="mbs">
                <ngx-monaco-editor
                    [options]="{theme: 'vs-dark', language: editBlock.language}"
                    [(ngModel)]="editBlock.content"
                ></ngx-monaco-editor>
            </div>
            <div class="form-control">
                <label>Block Description</label>
            </div>
            <div class="mbs prs">
                <quill-editor
                    [bounds]="'self'"
                    [modules]="quillModules"
                    [placeholder]="'Short Description'"
                    [customToolbarPosition]="'top'"
                    [(ngModel)]="editBlock.description"
                >
                </quill-editor>
            </div>
            <div class="txt-r">
                <button class="btn" (click)="saveBlock()">Save Block</button>
            </div>
        </ng-container>
    </styles-modal>

    <!-- edit minify -->
    <styles-modal #editBulkCopyModal [showButton]="false">
        <ng-container *ngIf="editBulkCopy !== null">
            <h3 class="mbxs">Select blocks to copy</h3>
            <div class="checkbox" *ngFor="let block of editBulkCopy.blocks">
                <label for="" (click)="block.selected = !block.selected" class="d-flex align-center cursor-pointer">
                    <input type="checkbox" class="mrxs"
                        [(ngModel)]="block.selected"
                        [ngModelOptions]="{standalone: true}"
                    /> 
                    {{block.name}}
                </label>
            </div>
            <div class="d-flex justify-between align-center mtxs">
                <div class="d-flex align-center">
                    <button class="btn" (click)="copyBlocks(minifyCheckbox.checked)">{{editBulkCopy.copied ? 'Copied!' : 'Copy'}}</button>
                    <div class="checkbox d-flex align-center mls">
                        <label for="" (click)="minifyCheckbox.click()" class="d-flex align-center cursor-pointer">
                            <input type="checkbox" class="mrxxs" #minifyCheckbox /> 
                            Minified
                        </label>
                    </div>
                </div>
                <a class="link txt-underline" (click)="toggleMinifyModal()">Done</a>
            </div>
        </ng-container>
    </styles-modal>


</div>