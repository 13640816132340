import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import Project from 'src/app/interfaces/Project';
import { UserSubscriptionMixin } from 'src/app/mixins/subscriptions.mixin';
import { ProjectModel } from 'src/app/models/project.model';
import { SidebarModel } from 'src/app/models/sidebar.model';
import { ClientModel } from 'src/app/models/client.model';
import { ClientService } from 'src/app/services/client.service';
import { LoadingService } from 'src/app/services/loading.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { EasingLogic, PageScrollService } from 'ngx-page-scroll-core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IconsService } from 'src/app/services/icons.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent extends UserSubscriptionMixin implements OnInit {
  @ViewChild('createProjectModal') createModal;
  @ViewChild('editProjectModal') editModal;
  @ViewChild('deleteProjectModal') deleteModal;
  @ViewChild('editClientsModal') editClients;
  @ViewChild('newProjectClientDropdownBtn') newProjectClientDropdownBtn;
  @ViewChild('currentProjectClientDropdownBtn') currentProjectClientDropdownBtn;

  currentView;
  currentSlug;

  navLinks = { ...SidebarModel };
  newProject = Object.assign({}, new ProjectModel);
  menuToggled = false;

  currentFilter = null;
  filteredClients = [];
  clientSearch = {
    newProject: '',
    currentProject: '',
    editClients: ''
  }

  colorSlugs = {};
  designModules = ['colors', 'typography', 'spacing', 'buttons', 'links'];
  devModules = ['overview', 'codeBlocks', 'components', 'stylesheet'];

  iconsStyleTag = null;

  constructor(
    userService: UserService,
    navService: NavigationService,
    projectService: ProjectService,
    clientService: ClientService,
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private pageScrollService: PageScrollService,
    private iconsService: IconsService,
    @Inject(DOCUMENT) private document: any
  ) {
    super(userService, navService, projectService, clientService);
  }

  ngOnInit(): void {
    this.checkIfCodeBlocksPage();

    this.currentProjectLoaded.then(() => {
      if (!this.currentProject.settings.thumbnail) {
        this.currentProject.settings.thumbnail = [{}, {}, {}];
        this.projectService.updateProject(this.currentProject.id, this.currentProject);
      }
      if (!this.currentProject.settings.colors.headerBG || !this.currentProject.settings.colors.headerText) {
        this.currentProject.settings.colors.headerBG = 'neutral-darker';
        this.currentProject.settings.colors.headerText = 'neutral-darker';
        if (this.currentProject.settings.colors.header) {
          delete this.currentProject.settings.colors.header;
          this.projectService.updateProject(this.currentProject.id, this.currentProject);
        }
      }
      this.setColorSlugs();

      if (!this.currentProject.settings.icon) {
        this.currentProject.settings.icon = false;
        this.saveProject();
      }
      if (!this.currentProject.settings.iconBg) {
        this.currentProject.settings.iconBg = this.currentProject.variables.colors[0];
        this.saveProject();
      }

      this.subscriptions.add(
        this.iconsService.sanitizedStyleTag.subscribe(tag => this.iconsStyleTag = tag ? tag : null)
      )
      // if ('components' in this.currentProject && 'icons' in this.currentProject.components) {
      //   const {eot, ttf, woff, svg} = this.currentProject.components.icons.files
      //   const css = this.currentProject.components.icons.css 
      //   this.generateIconCss = eot && ttf && woff && svg && css

      //   if (this.generateIconCss) {
      //     // this.convertCssToIcons();
      //     this.sanitizedStyleTag = this.getStyleTag(eot, ttf, woff, svg, css);
      //   }
      // }



      return this.clientsLoaded;
    }).then(() => {
      this.initClients();
    })
  }

  toggleModal(modal) {
    this[modal].toggleModal();
    this.initClients();
  }

  /************************** 
 * Navigation
 * ************************/
  navigate(slug) {
    if (this.currentView === 'dashboard' || this.currentView === 'settings') {
      this.router.navigateByUrl('/' + slug);
    } else {
      this.currentUser.selectedLinks[this.currentView] = slug;
      this.userService.saveUser(this.currentUser.id, this.currentUser);
      this.router.navigateByUrl('/p/' + this.currentProjectId + '/' + slug)
    }
  }

  navigateToHash(hash) {
    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: hash,
      scrollOffset: 150,
      duration: 0,
    });
  }

  changeView(view) {
    this.currentUser.selectedView = view;
    this.userService.saveUser(this.currentUser.id, this.currentUser);
    this.navService.updateNavData(window.location.pathname, this.currentUser)
    this.router.navigateByUrl('/p/' + this.currentProjectId + '/' + this.currentUser.selectedLinks[view])
  }

  toggleView() {
    if (this.currentUser.selectedView === 'develop') {
      this.changeView('design');
    } else if (this.currentUser.selectedView === 'design') {
      this.changeView('develop');
    }
  }

  toggleMenu() {
    this.menuToggled = !this.menuToggled;
  }

  checkIfCodeBlocksPage() {
    const codeBlocksCheck = this.router.events.subscribe((val) => {
      let i = 0;
      if (val instanceof NavigationEnd) {
        i++;
        if (this.router.url.includes('code-blocks')) {
          this.setFilter('codeBlocks');
          codeBlocksCheck.unsubscribe();
        }
        if (i >= 2) {
          codeBlocksCheck.unsubscribe();
        }
      }
    });
  }

  /************************** 
   * Project CRUD
   * ************************/
  createProject() {
    this.loadingService.startLoading();
    this.newProject.owner = this.currentUser.id
    this.projectService.createProject(this.newProject).then(() => {
      this.loadingService.stopLoading();
      this.createModal.toggleModal();
    })
  }
  saveProject() {
    this.loadingService.startLoading();
    this.projectService.updateProject(this.currentProject.id, this.currentProject).then(() => {
      this.loadingService.stopLoading();
      if (this.editModal.modalToggled) this.editModal.toggleModal();
    })
  }
  deleteProject() {
    this.loadingService.startLoading();
    this.projectService.deleteProject(this.currentProject.id).then(() => {
      this.loadingService.stopLoading();
      this.deleteModal.toggleModal();
      this.router.navigateByUrl('/');
    })
  }




  /************************** 
   * Clients
   * ************************/
  initClients() {
    this.filteredClients = this.clients.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      return 0;
    });
    let currentClient = this.clients.filter(c => {
      return c.id === this.currentProject.client;
    })[0]
    if (currentClient) this.clientSearch.currentProject = currentClient.name;
  }

  onClientSearch(location) {
    if (this.clientSearch[location].length > 0) {
      let a = this[location + 'ClientDropdownBtn'].nativeElement.parentNode;
      if (a.nextElementSibling.classList.contains('hide-fully')) a.click();
    }
    this.filteredClients = this.clients.filter(c => {
      return c.name.toLowerCase().includes(this.clientSearch[location].toLowerCase());
    })
  }

  selectClient(location, data) {
    this.clientSearch[location] = data.name;
    this[location].client = data.id;
  }

  createClient(location) {
    this.loadingService.startLoading();
    let newClient = { ...ClientModel };
    newClient.name = this.clientSearch[location];
    newClient.owner = this.currentUser.id;
    this.clientService.createClient(newClient).then(id => {
      if (location !== 'editClients') this[location].client = id;
      if (location == 'editClients') this.clientSearch[location] = '';
      this.loadingService.stopLoading();
    })
  }

  removeClient(location) {
    this[location].client = null;
    this.clientSearch[location] = '';
  }

  deleteClient(i) {
    this.loadingService.startLoading();
    this.clientService.deleteClient(this.clients[i].id).then(() => {
      this.loadingService.stopLoading();
    })
  }
  updateClient(i) {
    this.loadingService.startLoading();
    this.clientService.updateClient(this.clients[i].id, this.clients[i]).then(() => {
      this.loadingService.stopLoading();
    })
  }

  setFilter(clientID) {
    if (!clientID || this.currentFilter === clientID) {
      if (this.router.url.includes('code-blocks')) this.router.navigateByUrl('/');
      this.currentFilter = null;
      this.projectService.filterByClient(null);
    } else if (clientID === 'codeBlocks') {
      this.currentFilter = 'codeBlocks';
      this.router.navigateByUrl('/code-blocks')
    } else {
      if (this.router.url.includes('code-blocks')) this.router.navigateByUrl('/');
      this.currentFilter = clientID;
      this.projectService.filterByClient(clientID)
    }
  }

  convertToNormalCase(str) {
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); })
  }
  toKebabCase(str) {
    return str && str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.toLowerCase())
      .join('-');
  }

  setColorSlugs() {
    for (let color of this.currentProject.variables.colors) {
      this.colorSlugs[color.slug] = color.hex;
    }
  }
  
}

