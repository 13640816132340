<div class="brand-resources container pbxxxl">
  <div class="row mbm">
    <div class="col-12">
      <h1 class="mbxl">Brand Resources</h1>
    </div>
  </div>

  <!-- brand-guidelines -->
  <div id="brand-guidelines" class="row guide-section" #guideSection *ngIf="currentProject?.clientPortal?.guidelines">
    <div class="col-12">
      <h2>Brand Guidelines</h2>
      <div class="guidelines-description mbs" *ngIf="currentProject?.clientPortal?.guidelines?.description">
        <styles-builder [modules]="currentProject.clientPortal.guidelines.description" [admin]="false"></styles-builder>
      </div>
      <div class="links" *ngIf="currentProject?.clientPortal?.guidelines?.links">
        <a 
          class="link d-flex align-center" 
          [ngClass]="{'external': link.type === 'link', 'download': link.type === 'file'}" 
          [href]="link.type === 'link' ? 'http://' + link.url : link.value" 
          target="_blank" 
          *ngFor="let link of currentProject.clientPortal.guidelines.links"
        >
          {{link.label}}
          <styles-icon [name]="link.type === 'link' ? 'arrowRight' : 'download'" color="#F98B64" class="mlxxs"></styles-icon>
        </a>
      </div>
    </div>
  </div>

  <!-- logos -->
  <div id="logos" class="row guide-section" #guideSection *ngIf="currentProject?.clientPortal?.logos && currentProject?.components?.logos && currentProject?.components?.logos.length">
    <div class="col-12">
      <h2>Logos</h2>
      <div class="logos-description mbs" *ngIf="currentProject?.clientPortal?.logos?.description">
        <styles-builder [modules]="currentProject.clientPortal.logos.description" [admin]="false"></styles-builder>
      </div>
      <!-- TODO: zip download functionality -->
      <!-- <div class="links">
        <a class="link download" target="_blank">Download Full Logo Set</a>
      </div> -->
      <div class="logo-cards row mtm" *ngFor="let logoGroup of currentProject.components.logos">
        <div class="col-12 row-title">
          <h4 class="mbxs">{{logoGroup.name}}</h4>
        </div>
        <div class="col-12 col-lg-6 logo-card-wrap" *ngIf="logoGroup.types.lightBG && logoGroup.types.lightBG.active">
          <div class="logo-title mbxs">On Light Background</div>
          <div class="logo-card light">
            <div class="logo-holder light" *ngIf="logoGroup.types.lightBG.svg || logoGroup.types.lightBG.png">
              <div class="logo-inner">
                <div class="logo-svg" *ngIf="logoGroup.types.lightBG.svg && logoGroup.types.lightBG.sanitized" [innerHTML]="logoGroup.types.lightBG.sanitized"></div>
                <img *ngIf="!logoGroup.types.lightBG.svg && logoGroup.types.lightBG.png" [src]="logoGroup.types.lightBG.png">
              </div>
            </div>
            <div class="logo-footer">
              <a class="link download d-flex align-center" *ngIf="logoGroup.types.lightBG.svg" (click)="downloadImage({svg: logoGroup.types.lightBG.svg, name: logoGroup.name + '-dark.svg'}, true)">
                Download SVG
                <styles-icon name="download" color="#F98B64" class="mlxxs"></styles-icon>
              </a>
              <a class="link download d-flex align-center" *ngIf="logoGroup.types.lightBG.png" (click)="downloadImage({downloadURL: logoGroup.types.lightBG.png, name: logoGroup.name + '-dark.png'})">
                Download PNG
                <styles-icon name="download" color="#F98B64" class="mlxxs"></styles-icon>
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 logo-card-wrap">
          <div class="logo-title mbxs">On Dark Background</div>
          <div class="logo-card dark">
            <div class="logo-holder dark" *ngIf="logoGroup.types.darkBG.svg || logoGroup.types.darkBG.png">
              <div class="logo-inner">
                <div class="logo-svg" *ngIf="logoGroup.types.darkBG.svg && logoGroup.types.darkBG.sanitized" [innerHTML]="logoGroup.types.darkBG.sanitized"></div>
                <img *ngIf="!logoGroup.types.darkBG.svg && logoGroup.types.darkBG.png" [src]="logoGroup.types.darkBG.png">
              </div>
            </div>
            <div class="logo-footer">
              <a class="link download d-flex align-center" (click)="downloadImage({svg: logoGroup.types.darkBG.svg, name: logoGroup.name + '-light.svg'}, true)">
                Download SVG
                <styles-icon name="download" color="#F98B64" class="mlxxs"></styles-icon>
              </a>
              <a class="link download d-flex align-center" (click)="downloadImage({downloadURL: logoGroup.types.darkBG.png, name: logoGroup.name + '-light.png'})">
                Download PNG
                <styles-icon name="download" color="#F98B64" class="mlxxs"></styles-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- colors -->
  <div id="colors" class="row guide-section" #guideSection *ngIf="currentProject?.variables?.colors">
    <div class="col-12">
      <h2>Colors</h2>
      <div class="colors-description mbs" *ngIf="currentProject?.clientPortal?.colors?.description">
        <styles-builder [modules]="currentProject.clientPortal.colors.description" [admin]="false"></styles-builder>
      </div>
      <div class="color-codes-wrap mtm mbxl">
        <h4 class="mbm">Color Codes</h4>
        <div class="color-swatches">
          <div class="color-swatch" *ngFor="let color of currentProject.variables.colors">
            <div class="color" [ngStyle]="{'background': color.hex}"></div>
            <div class="swatch-footer">
              <div class="label">{{color.label}}</div>
              <div class="hex">{{color.hex}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="color-usage">
        <h4>Color Usage</h4>
        <div class="colors-usage mbs" *ngIf="currentProject?.clientPortal?.colors?.usage">
          <styles-builder [modules]="currentProject.clientPortal.colors.usage" [admin]="false"></styles-builder>
        </div>
      </div>
    </div>
  </div>

  <!-- fonts -->
  <div id="fonts" class="row guide-section" *ngIf="currentProject?.clientPortal?.fonts" #guideSection>
    <div class="col-12">
      <h2>Fonts</h2>
      <div class="fonts-description mbs" *ngIf="currentProject?.clientPortal?.fonts?.description">
        <styles-builder [modules]="currentProject.clientPortal.fonts.description" [admin]="false"></styles-builder>
      </div>
      <div class="links mbm">
        <a class="link download d-flex align-center" (click)="downloadZip('fonts')">Download All Font Files <styles-icon name="download" color="#F98B64" class="mlxxs"></styles-icon></a>
      </div>
      
      <div class="fonts-wrap" *ngFor="let font of currentProject?.clientPortal?.fonts?.typefaces">
        <div class="font">
          <h4 class="mbxxs">{{font.label}}</h4>
          <p>{{font.description}}</p>
          <div class="links mbm">
            <a class="link download d-flex align-center" (click)="downloadFile(font.file, font.label + '.zip')">Download Display Fonts <styles-icon name="download" color="#F98B64" class="mlxxs"></styles-icon></a>
          </div>
        </div>
      </div>

    </div>
  </div>

  <!-- icons-graphics -->
  <div id="icons-graphics" class="row guide-section" *ngIf="currentProject?.clientPortal?.iconsGraphics" #guideSection>
    <div class="col-12">
      <h2>Icons &amp; Graphics</h2>
      <div class="icons-graphics-description mbs" *ngIf="currentProject?.clientPortal?.iconsGraphics?.description">
        <styles-builder [modules]="currentProject.clientPortal.iconsGraphics.description" [admin]="false"></styles-builder>
      </div>
      <div class="links mbxl">
        <a class="link file-download d-flex align-center" (click)="downloadZip('icons')">Download All Icons <styles-icon name="download" color="#F98B64" class="mlxxs"></styles-icon></a>
        <a class="link file-download d-flex align-center" (click)="downloadZip('graphics')">Download All Graphics <styles-icon name="download" color="#F98B64" class="mlxxs"></styles-icon></a>
      </div>

      <div class="accordion" *ngFor="let asset of assets; let i = index;" [ngClass]="{'toggled': asset.toggled}" #accordion>
        <div class="accordion-header" (click)="toggleAccordion(asset, accordion)">
          <h4>{{asset.label}}</h4>
          <div class="accordion-arrow">
            <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 1.40432L8.40876 8.00093L1.81779 1.40432" stroke="#F98B64" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>              
          </div>
        </div>
        <div class="accordion-content" style="height: 0">
          <!-- ICONS -->
          <div class="content-inner icons" *ngIf="i == 0">
            <ul>
              <li *ngFor="let icon of asset.content">
                <a (click)="downloadImage(icon)">
                  <img [src]="icon.downloadURL" [alt]="icon.name">
                </a>
              </li>
            </ul>
          </div>
          <!-- GRAPHICS -->
          <div class="content-inner graphics" *ngIf="i == 1">
            <ul>
              <li *ngFor="let graphic of asset.content">
                <a (click)="downloadImage(graphic)" class="d-flex align-center">
                  <img [src]="graphic.downloadURL" [alt]="graphic.name" class="mrm">
                  <strong class="type-xs">{{graphic.name}}</strong>
                  <styles-icon name="download" color="#212121" class="mlxxs"></styles-icon>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>


</div>