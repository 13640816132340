import { Component, EventEmitter, Output, Input } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent {
  @Input() projectId: string;
  @Output() filesData =  new EventEmitter<any>();

  isHovering: boolean;

  files: File[] = [];
  uploadedFiles = [];

  constructor(
    private loadingService: LoadingService
  ) {}

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(files: FileList) {
    this.loadingService.startLoading();
    for (let i = 0; i < files.length; i++) {
      this.files.push(files[i]);
      this.uploadedFiles.push({name: files[i].name})
    }

    this.checkCompleted().then((files: any) => {
      if (files && files.length) {
        this.filesData.emit(files)
        this.files = []
        this.uploadedFiles = []
      }
    })
  }
  handleCompletedFile(e) {
    this.uploadedFiles[e.index].downloadURL = e.downloadURL
    this.uploadedFiles[e.index].path = e.path
  }
  checkCompleted() {
    let checkInt = null
    return new Promise((resolve) => {
      checkInt = setInterval(() => {
        if (this.uploadedFiles.every(file => 'downloadURL' in file && 'path' in file)) {
          resolve(this.uploadedFiles)
        }
      }, 100)
    })
  }
}