import { Injectable } from '@angular/core';
import { Observable, of, from, BehaviorSubject } from 'rxjs';
import { switchMap, take, map } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import User from '../interfaces/User';
import { Router } from '@angular/router';
import { UserModel } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: BehaviorSubject<User> = new BehaviorSubject({ ...UserModel });

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
  ) {
    this.setUser().subscribe((u) => {
      if (u && u.id) {
        this.user.next({ ...u });
      }
    });
  }

  setUser() {
    return this.afAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          return this.afs.doc(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    )
  }

  logout() {
    this.afAuth.signOut().then(() => {
      this.setUser();
      this.router.navigate(['/']);
    });
  }

  async asyncLogout() {
    await this.afAuth.signOut();
    this.user.next(null);
    setTimeout(async () => {
      await this.router.navigate(['/login']);
    }, 250);
  }

  loginWithEmailAndPassword(e, p) {
    return from(this.afAuth.signInWithEmailAndPassword(e, p));
  }

  signup(displayName: string, email: string, password: string, position: string): Promise<void> {
    return this.afAuth.createUserWithEmailAndPassword(email, password).then((credential) => {
      return this.createUser(credential.user, displayName, position);
    });
  }

  async googleLogin(): Promise<void> {
    const provider = new firebase.default.auth.GoogleAuthProvider();
    const credential = await this.afAuth.signInWithPopup(provider);
    return this.completeLogin(credential);
  }

  async completeLogin(credential): Promise<void> {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${credential.user.uid}`);
    const docSnapshot = await userRef.ref.get();
    if (!docSnapshot.exists) {
      const position = 'designer';
      const userData = {
        id: credential.user.uid,
        email: credential.user.email,
        displayName: credential.user.displayName,
        photoURL: credential.user.photoURL,
        teams: [],
        projects: [],
        position: position,
        selectedView: position.substring(0, position.length - 2),
        selectedLinks: {
          design: 'colors',
          develop: 'overview',
          projectSettings: 'harmonica'
        }
      };
      userRef.set(userData, { merge: true });
      this.user.next({ ...userData });
      await this.router.navigate(['/dashboard']);
    } else {
      const ud: User | any = docSnapshot.data();
      if (!('projectSettings' in ud.selectedLinks)) {
        ud.selectedLinks.projectSettings = 'harmonica';
      }
      this.user.next({ ...ud });
      console.log(this.user);
      this.saveUser(ud.id, ud);
      await this.router.navigate(['/dashboard']);
    }
  }

  createUser(user, dn, position): Promise<void> {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      id: user.uid,
      email: user.email,
      displayName: dn,
      teams: [],
      photoURL: '',
      projects: [],
      position: position,
      selectedView: position.substring(0, position.length - 2),
      selectedLinks: {
        design: 'colors',
        develop: 'overview',
        projectSettings: 'harmonica'
      }
    };
    return userRef.set(userData, { merge: true });
  }

  updateEmail(e): Promise<void> {
    const user = firebase.default.auth().currentUser;
    return user.updateEmail(e);
  }
  updatePassword(p): Promise<void> {
    const user = firebase.default.auth().currentUser;
    return user.updatePassword(p);
  }

  getUser(uid) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`/users/${uid}`);
    return new Promise((resolve, reject) => {
      userRef.get().subscribe(
        (ud) => {
          resolve(ud.data());
        },
        (e) => reject(e)
      );
    });
  }

  saveUser(uid, d) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${uid}`);
    return userRef.update(d);
  }

  getUsers() {
    return this.afs
      .collection('users')
      .get()
      .pipe(
        map((snapshot) => {
          const items = [];
          snapshot.docs.map((a) => {
            const data = a.data();
            items.push(data);
          });
          return items;
        })
      );
  }

  deleteUser(uid) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${uid}`);
    return userRef.delete();
  }
}
